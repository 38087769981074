const fieldConstans = {
  nameMaxLength: 100,
  emailMaxLength: 150,
  passwordMinLength: 8,
  passwordMaxLength: 100,
  phoneNumberMaxLength: 30,
  phoneNumberMinLength: 6,
  addressMaxLength: 100,
  descriptionMaxLength: 2000,
}

export default fieldConstans;
