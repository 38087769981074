import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams, useNavigate } from "react-router-dom";
import { LocationDetail } from '../../interfaces/interfaces';
import { getLocationRequest } from '../../api/requests';
import { useApiData } from '../../hooks/useApiData';
import { MdOutlineEmail } from 'react-icons/md';
import { MdPhone } from 'react-icons/md';
import { MdSmartphone } from 'react-icons/md';
import { LocationQuotes } from './LocationQuotes';
import { MdConstruction, MdWork, MdOutlineAttachMoney, MdInsertDriveFile, MdBusiness, MdSettings } from 'react-icons/md';
import { FaBell, FaTv } from 'react-icons/fa';
import { DetailSkeleton } from '../../components/common/DetailSkeleton';
import { LocationAssets } from './LocationAssets';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/common/BackButton';
import { LocationJobs } from './LocationJobs';
import { Documents } from '../../components/Documents';
import { Services } from '../../components/service/Services';
import { SubsectionTabs } from '../../components/tabs/SubsectionTabs';
import { SectionContainer } from '../../components/container/SectionContainer';
import { CardContainer } from '../../components/container/CardContainer';

export const Location = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { status, onSuccess, onFailure } = useApiData<LocationDetail>();

  const locationData = status.type === 'SUCCESS' ? status.data : undefined;

  useEffect(() => {
    if (!id) return;
    getLocationRequest(onSuccess, onFailure, id);
  }, [id]);

  const handleNewDocumentUploaded = () => {
    if (!id) return;
    getLocationRequest(onSuccess, onFailure, id);
  }

  const onAlarmClick = () => {
    const alarm = status.type === 'SUCCESS' ? status.data?.location?.link?.alerts : '';
    if (!alarm) return;
    navigate(`/location/${id}/alarm?link=${encodeURIComponent(alarm)}`);
  }

  const onMonitorClick = () => {
    const base = status.type === 'SUCCESS' ? status.data?.location?.link?.base : '';
    if (!base) return;
    navigate(`/location/${id}/monitor?link=${encodeURIComponent(base)}`);
  }

  if (status.type === 'LOADING') {
    return (
      <SectionContainer>
        <DetailSkeleton />
      </SectionContainer>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={status.error} type="Location" link={{ title: 'Go to Locations', ref: '/locations' }} />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <CardContainer>
        <Card.Title className={"m-3 m-md-0"}>
          <div className="d-flex flex-row  align-items-start">
            <h3 className="flex-fill m-0">
              <BackButton className="p-0 me-3 float-left" />
              {status.data.location.name}
            </h3>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>Alarms</Tooltip>}
            >
              <button disabled={!locationData?.location.link?.alerts} onClick={onAlarmClick} style={{ border: 'none', background: 'transparent' }}>
                <FaBell />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>Monitoring</Tooltip>}
            >
              <button disabled={!locationData?.location.link?.base} onClick={onMonitorClick} style={{ border: 'none', background: 'transparent' }}>
                <FaTv />
              </button>
            </OverlayTrigger>
          </div>
        </Card.Title>
        <Card.Body className="pb-0 pe-0 ps-0">
          <Row className="p-0 m-3 m-md-0" xs={1} sm={1} md={3}>
            <Col className="ps-0">
              <h5>Location</h5>
              <p className="m-0">{status.data.location.address.street}</p>
              <p className="text-muted m-0">
                {`${status.data.location.address.city}, ${status.data.location.address.state} ${status.data.location.address.postalCode}`}
              </p>
              {(status.data.location.email || status.data.location.phoneNumber) && (
                <>
                  <p className="mb-1"></p>
                  {status.data.location.email && (
                    <p className="text-muted m-0">
                      <MdOutlineEmail className="me-1" />
                      {status.data.location.email}
                    </p>
                  )}
                  {status.data.location.phoneNumber && (
                    <p className="text-muted m-0">
                      <MdPhone className="me-1" />
                      {status.data.location.phoneNumber}
                    </p>
                  )}
                </>
              )}
              <p/>
            </Col>
            <Col className="ps-0">
              <h5>Location contact</h5>
              {status.data.location.primaryContact ? (
                <>
                  <p className="mb-1">
                    {`${status.data.location.primaryContact.firstName} ${status.data.location.primaryContact.lastName} - ${status.data.location.primaryContact.type}`}
                  </p>
                  {status.data.location.primaryContact.email && (
                    <p className="text-muted m-0">
                      <MdOutlineEmail className="me-1" />
                      {status.data.location.primaryContact.email}
                    </p>
                  )}
                  {status.data.location.primaryContact.phone && (
                    <p className="text-muted m-0">
                      <MdPhone className="me-1" />
                      {status.data.location.primaryContact.phone}
                    </p>
                  )}
                  {status.data.location.primaryContact.alternatePhone && (
                    <p className="text-muted m-0">
                      <MdPhone className="me-1" />
                      {status.data.location.primaryContact.alternatePhone}
                    </p>
                  )}
                  {status.data.location.primaryContact.mobile && (
                    <p className="text-muted m-0">
                      <MdSmartphone className="me-1" />
                      {status.data.location.primaryContact.mobile}
                    </p>
                  )}
                  <p/>
                </>
              ) : (
                <p>-</p>
              )}
            </Col>
            <Col className="p-0">
              <h5>Company</h5>
              <p className="mt-0 mb-1">{status.data.location.company?.name}</p>
              <p className="text-muted m-0">{status.data.location.company?.address.street}</p>
              <p className="text-muted m-0">
                {`${status.data.location.company?.address.city}, ${status.data.location.company?.address.state} ${status.data.location.company?.address.postalCode}`}
              </p>
              <p />
            </Col>
          </Row>
          <Row className="mt-4" />
          <SubsectionTabs
            sections={[
              { label: <><MdConstruction size={22} /> Services</>, content: <Services type="location" /> },
              { label: <><MdWork size={22} /> Projects</>, content: <LocationJobs /> },
              { label: <><MdSettings size={22} /> Assets</>, content: <LocationAssets /> },
              // { label: <><MdOutlineAttachMoney size={22} /> Quotes</>, content: <LocationQuotes /> },
              // { label: <><MdBusiness size={22} /> Contracts</>, content: <LocationQuotes /> },
              { label: <><MdInsertDriveFile size={22} /> Documents</>, content: <Documents onDocumentsChanged={handleNewDocumentUploaded} entityId={status.data.location.id} entityType={"location"} documents={status.data.location.attachments} /> },
            ]}
            maxContentHeight={55}
          />
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  );
}
