import Modal from 'react-bootstrap/Modal';
import {Form, Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useForm} from 'react-hook-form';
import {createAttachment} from '../api/requests';
import {Category, DocumentEntityTypes} from '../interfaces/interfaces';
import {useState} from 'react';

export const categories = [
  {id: 1, name: 'Job Paperwork'},
  {id: 2, name: 'Job Vendor Bill'},
  {id: 3, name: 'Job Quality Control Picture'},
  {id: 5, name: 'Deficiency Repair Proposal'},
  {id: 7, name: 'Generic Attachment (attachments that do not fall into a more specific category)'},
  {id: 8, name: 'Avatar Image'},
  {id: 9, name: 'Import'},
  {id: 10, name: 'Blank Paperwork'},
  {id: 11, name: 'Work Acknowledgement'},
  {id: 12, name: 'Logo'},
  {id: 14, name: 'Job Invoice'},
];

type FormData = {
  description: string;
  file: FileList | null;
  category?: string;
  newCategory?: string;
}

const INVALID_VAL = '-1';
const NEW_CATEGORY = '-2';

const defaultValues: FormData = {
  description: '',
  file: null,
  category: INVALID_VAL,
  newCategory: '',
}

type Props = {
  show: boolean;
  onHide: () => void;
  onUpload: () => void;
  entityId: number;
  entityType: DocumentEntityTypes;
  categories: Category[];
}

export function AddDocument(props: Props) {

  const {register, handleSubmit, reset, formState: {errors}, watch} = useForm({
    defaultValues,
  })

  const newCategory = watch('category') === NEW_CATEGORY;

  const [loading, setLoading] = useState(false)

  console.log(errors)

  const uploadDocument = (data: FormData) => {

    console.log(data)

    if (!data.file?.[0] || !data.category) {
      return;
    }

    setLoading(true);
    createAttachment(
      () => {
        props.onHide()
        props.onUpload()
        reset(defaultValues)
      },
      () => {
        alert('Error uploading document');
      },
      {
        uploadedFile: data.file[0],
        description: data.description,
        category: data.category !== NEW_CATEGORY ? data.category : data.newCategory || "General",
        entityId: props.entityId.toString(),
        entityType: props.entityType,
      }
    ).finally(() => {
      setLoading(false)
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          {...register("description", {required: "Please enter a description", validate: (value) => value.trim().length > 0,})}
          placeholder={'Description'}
          as={'textarea'}
        />
        {errors.description && <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
        <Form.Control
          className={"mt-3"}
          {...register("file", {required: "Please select a file", validate: (value) => value?.[0] != null})}
          type="file"
        />
        {errors.file && <Form.Text className="text-danger">{errors.file.message}</Form.Text>}
        <Form.Select
          className={"mt-3"}
          {...register("category", {required: "Please select a category", validate: (val) => {
            console.log(val)
            console.log("checking", val != null && val !== INVALID_VAL)
            return val != null && val !== INVALID_VAL;
          }})}>
          <option value={INVALID_VAL}>Select category</option>
          {props.categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
        </Form.Select>
        {errors.category && <Form.Text className="text-danger">{errors.category.message}</Form.Text>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button disabled={loading} variant="primary" onClick={handleSubmit(uploadDocument)}>
          {loading && <Spinner animation="border" size="sm" className={"me-2"} />}
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
