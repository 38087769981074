import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { Job, Jobs, JobStatus } from '../../interfaces/interfaces';
import { useApiData } from '../../hooks/useApiData';
import { getLocationJobsRequest } from '../../api/requests';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TableSkeleton } from '../../components/common/TableSkeleton';
import { JobTable } from '../../components/project/JobTable';
import { SmallError } from '../../components/SmallError';
import {useFiltered} from '../../hooks/useFiltered';

export const LocationJobs = () => {
  const { id } = useParams<{ id: string }>();
  const { status, onSuccess, onFailure } = useApiData<Job[]>();
  const [ showCompleted, setShowCompleted ] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [completedSearchTerm, setCompletedSearchTerm] = useState('');

  const [filtered, filteredCompleted] = useFiltered(status.type === 'SUCCESS' ? status.data : [], [
    {
      filter: s => s.status !== JobStatus.Completed,
      searchTerm: searchTerm,
      searchFields: ['location.name', 'description', 'status', 'type'],
      sortBy: 'windowEnd',
    },
    {
      filter: s => s.status === JobStatus.Completed,
      searchTerm: completedSearchTerm,
      searchFields: ['location.name', 'description', 'status', 'type'],
      sortBy: 'windowEnd',
    }
  ])

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const handleCompletedSearch = (search: string) => {
    setCompletedSearchTerm(search);
  }

  const onRequestSuccess = (data: Jobs) => {
    onSuccess(data.jobs);
  }

  useEffect(() => {
    if (!id) return;
    getLocationJobsRequest(onRequestSuccess, onFailure, id, 'scheduled,completed');
  }, [id]);

  if (status.type === 'LOADING') {
    return (
      <Container fluid className="p-1 m-0 justify-content-center">
        <TableSkeleton />
      </Container>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <Container fluid className="p-1 m-0 justify-content-center">
        <SmallError error={status.error} type="Projects" />
      </Container>
    );
  }

  return (
    <Container fluid className="p-1 m-0 justify-content-center">
      <JobTable searchKey={"current"} jobs={filtered} handleSearch={handleSearch} />
      <Container fluid className="mt-4 d-flex flex-row p-0">
        <Button
          variant="light"
          className="mb-3 flex-grow-1 border"
          onClick={() => setShowCompleted(!showCompleted)}
        >
          <div className="flex-grow-1 d-flex flex-row align-items-center">
            <h5 className="m-0 flex-grow-1">Completed projects</h5>
            <h6 className="m-0">
              {showCompleted ? <MdKeyboardArrowUp size={26} /> : <MdKeyboardArrowDown size={26} />}
            </h6>
          </div>
        </Button>
      </Container>
      {showCompleted && (
        <JobTable searchKey={"historic"} jobs={filteredCompleted} handleSearch={handleCompletedSearch} />
      )}
    </Container>
  );
}
