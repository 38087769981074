import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../hooks/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Constants from '../../constants/fieldConstants';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { useApiData } from '../../hooks/useApiData';
import { CompanyData } from '../../interfaces/interfaces';
import { getCompanyRequest, updateCompanyRequest } from '../../api/requests';
import Skeleton from 'react-loading-skeleton';
import { EditButton } from './EditButton';

interface CompanyForm {
  name: string;
  phoneNumber: string;
  addressStreet: string,
  addressCity: string,
  addressState: string,
  addressPostalCode: string
}

type Status =
  | { type: 'INIT' }
  | { type: 'LOADING' }
  | { type: 'ERROR', message: string };

export const UpdateCompany = () => {
  const { user } = useAppSelector(state => state.profile);
  const [status, setStatus] = useState<Status>({ type: 'INIT' });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { onSuccess, onFailure, status: dataStatus, setData } = useApiData<CompanyData>();
  const companyFormSchema = Yup.object().shape({
    name: Yup.string()
      .max(Constants.nameMaxLength, 'First name too long'),
    phoneNumber: Yup.string()
      .max(Constants.phoneNumberMaxLength, 'Phone number too long'),
    addressStreet: Yup.string()
      .max(Constants.addressMaxLength, 'Address street too long'),
    addressCity: Yup.string()
      .max(Constants.addressMaxLength, 'Address city too long'),
    addressState: Yup.string()
      .max(Constants.addressMaxLength, 'Address state too long'),
    addressPostalCode: Yup.string()
      .max(Constants.addressMaxLength, 'Address zip code too long')
  });
  const { register, handleSubmit, formState, watch, reset } = useForm<CompanyForm>({
    resolver: yupResolver(companyFormSchema),
    mode: 'onTouched',
  });

  const companyChanged = () => {
    if (dataStatus.type !== 'SUCCESS') return false;
    const { name, phoneNumber, addressStreet, addressCity, addressPostalCode, addressState } = watch();
    return name !== dataStatus.data.name
      || phoneNumber !== dataStatus.data.phoneNumber
      || addressStreet !== dataStatus.data.address.street
      || addressCity !== dataStatus.data.address.city
      || addressPostalCode !== dataStatus.data.address.postalCode
      || addressState !== dataStatus.data.address.state;
  }

  const onSubmit: SubmitHandler<CompanyForm> = (data: CompanyForm) => {
    if (dataStatus.type !== 'SUCCESS') return;
    setStatus({ type: 'LOADING' });
    updateCompanyRequest(() => {
      setData({ ...dataStatus.data, name: data.name, phoneNumber: data.phoneNumber, address: {
        street: data.addressStreet, city: data.addressCity, state: data.addressState, postalCode: data.addressPostalCode } });
      setStatus({ type: 'INIT' });
      setIsEditing(false);
      toast.success('Company updated successfully');
    }, () => {
      setStatus({ type: 'ERROR', message: 'Error updating company. Please try again later.' });
    }, data);
  }

  useEffect(() => {
    if (!user || !user.companyId) return;
    getCompanyRequest((data: CompanyData) => {
      reset({
        name: data.name,
        phoneNumber: data.phoneNumber,
        addressStreet: data.address.street,
        addressCity: data.address.city,
        addressState: data.address.state,
        addressPostalCode: data.address.postalCode
      });
      onSuccess(data);
    }, onFailure);
  }, []);

  const onEdit = () => {
    setIsEditing(true);
  }

  const onCancel = () => {
    if (dataStatus.type !== 'SUCCESS') return;
    reset({
      name: dataStatus.data.name,
      phoneNumber: dataStatus.data.phoneNumber,
      addressStreet: dataStatus.data.address.street,
      addressCity: dataStatus.data.address.city,
      addressState: dataStatus.data.address.state,
      addressPostalCode: dataStatus.data.address.postalCode
    })
    setIsEditing(false);
  }

  if (!user || !user.companyId) return null;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="pt-2">
        Company
        {(dataStatus.type === 'SUCCESS' && !isEditing) && (
          <EditButton onClick={onEdit} />
        )}
      </h5>
      {dataStatus.type === 'ERROR' && (
        <p className="text-danger">An error occurred while finding your company. Please try again later.</p>
      )}
      {dataStatus.type === 'LOADING' && (
        <>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Name</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Phone</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
          </Row>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>City</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>State</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
          </Row>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Street</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Zip Code</Form.Label>
              <Skeleton style={{ height: '38px', flexGrow: 1 }} />
            </Form.Group>
          </Row>
        </>
      )}
      {dataStatus.type === 'SUCCESS' && (
        <>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Company name"
                type="text"
                maxLength={Constants.nameMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                required
                {...register('name')}
              />
              {formState.errors.name && <p className="text-danger">{formState.errors.name.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                placeholder="Company phone number"
                type="tel"
                maxLength={Constants.phoneNumberMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                {...register('phoneNumber')}
              />
              {formState.errors.phoneNumber && <p className="text-danger">{formState.errors.phoneNumber.message}</p>}
            </Form.Group>
          </Row>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>City</Form.Label>
              <Form.Control
                placeholder="Company city"
                type="text"
                maxLength={Constants.addressMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                required
                {...register('addressCity')}
              />
              {formState.errors.addressCity && <p className="text-danger">{formState.errors.addressCity.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Company state"
                type="text"
                maxLength={Constants.addressMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                required
                {...register('addressState')}
              />
              {formState.errors.addressState && <p className="text-danger">{formState.errors.addressState.message}</p>}
            </Form.Group>
          </Row>
          <Row xs={2} sm={2} md={2}>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Street</Form.Label>
              <Form.Control
                placeholder="Company street"
                type="text"
                maxLength={Constants.addressMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                required
                {...register('addressStreet')}
              />
              {formState.errors.addressStreet && <p className="text-danger">{formState.errors.addressStreet.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                placeholder="Company zip code"
                type="text"
                maxLength={Constants.addressMaxLength}
                disabled={status.type === 'LOADING' || !isEditing}
                required
                {...register('addressPostalCode')}
              />
              {formState.errors.addressPostalCode && <p className="text-danger">{formState.errors.addressPostalCode.message}</p>}
            </Form.Group>
          </Row>
          {(isEditing && status.type === 'ERROR') && (
            <div className="alert alert-danger mt-3 p-2">
              <p className="m-0">{status.message}</p>
            </div>
          )}
          {isEditing && (
            <div className="flex-grow-1 d-flex justify-content-end">
              <Button
                variant="danger"
                className="mt-3 ps-2 pe-2 me-2"
                disabled={status.type === 'LOADING'}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="mt-3 ps-2 pe-2"
                disabled={status.type === 'LOADING' || !companyChanged() || !formState.isValid}
              >
                Save Changes
                {status.type === 'LOADING' && (
                  <span className="spinner-border spinner-border-sm ms-2" />
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </Form>
  );
}