import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useParams, useNavigate, Link } from "react-router-dom";
import { AssetDetail } from '../../interfaces/interfaces';
import { getAssetRequest } from '../../api/requests';
import { useApiData } from '../../hooks/useApiData';
import { MdOutlineEmail } from 'react-icons/md';
import { MdPhone } from 'react-icons/md';
import { MdConstruction, MdWork, MdInsertDriveFile } from 'react-icons/md';
import { FaBell, FaTv } from 'react-icons/fa';
import { FiTrendingUp } from 'react-icons/fi';
import { DetailSkeleton } from '../../components/common/DetailSkeleton';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/common/BackButton';
import { AssetJobs } from './AssetJobs';
import { Documents } from '../../components/Documents';
import { Services } from '../../components/service/Services';
import { CardContainer } from '../../components/container/CardContainer';
import { SubsectionTabs } from '../../components/tabs/SubsectionTabs';
import { SectionContainer } from '../../components/container/SectionContainer';

export const Asset = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { status, onSuccess, onFailure } = useApiData<AssetDetail>();

  const links = status.type === 'SUCCESS' ? {
    base: status.data.link?.base,
    trending: status.data.link?.trending,
    alerts: status.data.link?.alerts,
  } : undefined

  useEffect(() => {
    if (!id) return;
    getAssetRequest(onSuccess, onFailure, id);
  }, [id]);

  const handleNewDocumentUploaded = () => {
    if (!id) return;
    getAssetRequest(onSuccess, onFailure, id);
  }

  const onAlarmClick = () => {
    if (!links?.alerts) return;
    navigate(`/asset/${id}/alarm?link=${encodeURIComponent(links.alerts)}`);
  }

  const onMonitorClick = () => {
    if (!links?.base) return;
    navigate(`/asset/${id}/monitor?link=${encodeURIComponent(links.base)}`);
  }

  const onTrendClick = () => {
    if (!links?.trending) return;
    navigate(`/asset/${id}/trend?link=${encodeURIComponent(links.trending)}`);
  }

  if (status.type === 'LOADING') {
    return (
      <SectionContainer>
        <DetailSkeleton />
      </SectionContainer>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={status.error} type="Asset" link={{ title: 'Go to Locations', ref: '/locations' }} />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <CardContainer>
        <Card.Title>
          <div className="d-flex m-3 m-md-0 flex-row align-items-start">
            <h3 className="flex-fill m-0">
              <BackButton className="p-0 me-3 float-left" />
              {status.data.name}
            </h3>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>Trends</Tooltip>}
            >
              <button disabled={!links?.trending} onClick={onTrendClick} style={{ border: 'none', background: 'transparent' }}>
                <FiTrendingUp />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>Alarms</Tooltip>}
            >
              <button disabled={!links?.alerts} onClick={onAlarmClick} style={{ border: 'none', background: 'transparent' }}>
                <FaBell />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={(props) => <Tooltip {...props}>Monitoring</Tooltip>}
            >
              <button disabled={!links?.base} onClick={onMonitorClick} style={{ border: 'none', background: 'transparent' }}>
                <FaTv />
              </button>
            </OverlayTrigger>
          </div>
        </Card.Title>
        <Card.Body className="pb-0 pe-0 ps-0">
          <div className={"mx-3 mx-md-0"}>
            <div className="d-flex flex-row align-items-center">
              {status.data.serviceLine && (
                <img
                  src={status.data.serviceLine.icon}
                  alt={status.data.display}
                  style={{ width: '18px', height: '18px' }}
                  className="me-1"
                />
                )}
              <p className="m-0 p-0 fw-bold">
                {status.data.display}
              </p>
            </div>
            {status.data.properties.notes && (
              <p className="m-0 p-0">
                <span className="fw-bold">Notes: </span>
                <span>{status.data.properties.notes}</span>
              </p>
            )}
            <p/>
            <Row className="p-0 m-0" xs={1} sm={1} md={3}>
              <Col className="ps-0">
                <h5>Location</h5>
                {status.data.location ? (
                  <>
                    <p className="mb-1">
                      <Link to={`/location/${status.data.location.id}`}>
                        {status.data.location.name}
                      </Link>
                    </p>
                    <p className="m-0">{status.data.location.address.street}</p>
                    <p className="text-muted m-0">
                      {`${status.data.location.address.city}, ${status.data.location.address.state} ${status.data.location.address.postalCode}`}
                    </p>
                    {(status.data.location.email || status.data.location.phoneNumber) && (
                      <>
                        <p className="mb-1"></p>
                        {status.data.location.email && (
                          <p className="text-muted m-0">
                            <MdOutlineEmail className="me-1" />
                            {status.data.location.email}
                          </p>
                        )}
                        {status.data.location.phoneNumber && (
                          <p className="text-muted m-0">
                            <MdPhone className="me-1" />
                            {status.data.location.phoneNumber}
                          </p>
                        )}
                      </>
                    )}
                    <p/>
                  </>
                ) : (
                  <p>-</p>
                )}
              </Col>
              <Col className="ps-0">
                <h5>Company</h5>
                {status.data.company ? (
                  <>
                    <p className="mb-1 text-decoration-underline">{status.data.company.name}</p>
                    <p className="m-0">{status.data.company.address.street}</p>
                    <p className="text-muted m-0">
                      {`${status.data.company.address.city}, ${status.data.company.address.state} ${status.data.company.address.postalCode}`}
                    </p>
                    {status.data.company.phoneNumber && (
                      <>
                        <p className="mb-1"></p>
                        <p className="text-muted m-0">
                          <MdPhone className="me-1" />
                          {status.data.company.phoneNumber}
                        </p>
                      </>
                    )}
                    <p/>
                  </>
                ) : (
                  <p>-</p>
                )}
              </Col>
              <Col className="p-0">
                <h5>Details</h5>
                {(status.data.properties.locationInSite ||
                  status.data.properties.type ||
                  status.data.properties.manufacturer ||
                  status.data.properties.model ||
                  status.data.properties.serialNumber
                ) ? (
                  <>
                    {status.data.properties.locationInSite && (
                      <p className="m-0 p-0">
                        <span>Location In Site: </span>
                        <span className="text-muted">{status.data.properties.locationInSite}</span>
                      </p>
                    )}
                    {status.data.properties.type && (
                      <p className="m-0 p-0">
                        <span>Type: </span>
                        <span className="text-muted">{status.data.properties.type}</span>
                      </p>
                    )}
                    {status.data.properties.manufacturer && (
                      <p className="m-0 p-0">
                        <span>Manufacturer: </span>
                        <span className="text-muted">{status.data.properties.manufacturer}</span>
                      </p>
                    )}
                    {status.data.properties.model && (
                      <p className="m-0 p-0">
                        <span>Model: </span>
                        <span className="text-muted">{status.data.properties.model}</span>
                      </p>
                    )}
                    {status.data.properties.serialNumber && (
                      <p className="m-0 p-0">
                        <span>Serial: </span>
                        <span className="text-muted">{status.data.properties.serialNumber}</span>
                      </p>
                    )}
                    <p/>
                  </>
                ) : (
                  <p>-</p>
                )}
              </Col>
            </Row>
          </div>
          <Row className="mt-4" />
          <SubsectionTabs
            maxContentHeight={45}
            sections={[
              { label: <><MdConstruction size={20} /> Services</>, content: <Services type="asset" /> },
              { label: <><MdWork size={20} /> Projects</>, content: <AssetJobs jobs={status.data.jobs} /> },
              { label: <><MdInsertDriveFile size={20} /> Documents</>, content: <Documents onDocumentsChanged={handleNewDocumentUploaded} entityId={status.data.id} entityType={"asset"} documents={status.data.attachments} /> }
            ]}
          />
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  );
}
