import {useEffect, useMemo, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { ServiceRequest, Services as ServicesType, ServiceStatus } from '../../interfaces/interfaces';
import { useApiData } from '../../hooks/useApiData';
import { getAssetServicesRequest, getLocationServicesRequest } from '../../api/requests';
import { ServiceTable } from '../../components/service/ServiceTable';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TableSkeleton } from '../../components/common/TableSkeleton';
import { SmallError } from '../SmallError';
import {useFiltered} from '../../hooks/useFiltered';

export const Services = ({ type }: { type: 'asset' | 'location' }) => {
  const { id } = useParams<{ id: string }>();
  const { status, onSuccess, onFailure } = useApiData<ServiceRequest[]>();
  // const [ filteredServices, setFilteredServices ] = useState<ServiceRequest[]>([]);
  // const [ filteredHistoric, setFilteredHistoric ] = useState<ServiceRequest[]>([]);
  const [ showHistoric, setShowHistoric ] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [historicSearchTerm, setHistoricSearchTerm] = useState('');

  const dataToFilter = status.type === 'SUCCESS' ? status.data : [];

  const [filteredServices, filteredHistoric] = useFiltered(dataToFilter, [
    {
      filter: s => s.status !== ServiceStatus.Closed,
      searchTerm: searchTerm,
      searchFields: ['asset.name', 'description'],
      sortBy: 'windowEnd',
    },
    {
      filter: s => s.status === ServiceStatus.Closed,
      searchTerm: historicSearchTerm,
      searchFields: ['asset.name', 'description'],
      sortBy: 'windowEnd',
    }
  ])

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const handleHistoricSearch = (search: string) => {
    setHistoricSearchTerm(search);
  }

  const onRequestSuccess = (data: ServicesType) => {
    onSuccess(data.services);
  }

  useEffect(() => {
    if (!id) return;
    if (type === 'asset') {
      getAssetServicesRequest(onRequestSuccess, onFailure, id, "open,in_progress,closed,void,pending"); // all except canceled
    } else {
      getLocationServicesRequest(onRequestSuccess, onFailure, id, "open,in_progress,closed,void,pending");
    }
  }, [id]);

  if (status.type === 'LOADING') {
    return (
      <Container className="p-1 m-0 justify-content-start">
        <TableSkeleton />
      </Container>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <Container fluid className="p-1 m-0 justify-content-center">
        <SmallError error={status.error} type="Services" />
      </Container>
    );
  }

  return (
    <Container fluid className="p-1 m-0 justify-content-center">
      <ServiceTable searchKey={`${type}.current`} services={filteredServices} handleSearch={handleSearch} />
      <Container fluid className="mt-4 d-flex flex-row p-0">
        <Button
          variant="light"
          className="mb-3 flex-grow-1 border"
          onClick={() => setShowHistoric(!showHistoric)}
        >
          <div className="flex-grow-1 d-flex flex-row align-items-center">
            <h5 className="m-0 flex-grow-1">Closed services</h5>
            <h6 className="m-0">
              {showHistoric ? <MdKeyboardArrowUp size={26} /> : <MdKeyboardArrowDown size={26} />}
            </h6>
          </div>
        </Button>
      </Container>
      {showHistoric && (
        <ServiceTable searchKey={`${type}.historic`} services={filteredHistoric} handleSearch={handleHistoricSearch} />
      )}
    </Container>
  );
}
