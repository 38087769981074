import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { ServiceRequest } from '../../interfaces/interfaces';
import {Link, useLocation, useRoutes} from 'react-router-dom';
import { ServiceLineIcon } from './ServiceLineIcon';
import { HiOutlineRefresh, HiOutlineClock } from 'react-icons/hi';
import { getDate, getWindow } from '../../utils/parseDate';
import { SearchBar } from '../common/SearchBar';
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

interface ServiceTableProps {
  services: ServiceRequest[];
  handleSearch: (search: string) => void;
  searchKey?: string;
}

function ServiceDescription(props: {service: ServiceRequest, shouldNavigateToAsset: boolean, navigateTo: string}) {
  return <Container fluid className="d-inline-flex flex-row p-0">
    <ServiceLineIcon data={props.service.serviceLine} />
    <Col className="ms-2">
      {(props.service.serviceRecurrence || props.service.estimatedPrice || props.service.duration) && (
        <Container fluid className="d-inline-flex flex-row p-0 gap-1">
          {props.service.serviceRecurrence && (
            <Badge pill bg="secondary" className="d-inline-flex align-items-center">
              <HiOutlineRefresh className="me-1" />
              {props.service.serviceRecurrence.frequency.toUpperCase()}
            </Badge>
          )}
          {props.service.estimatedPrice && (
            <Badge pill bg="success">
              {`$ ${props.service.estimatedPrice.toFixed(2)}`}
            </Badge>
          )}
          {props.service.duration && (
            <Badge pill bg="dark" className="d-inline-flex align-items-center">
              <HiOutlineClock className="me-1" />
              {(props.service.duration / 3600).toFixed(2)}
            </Badge>
          )}
        </Container>
      )}
      <p className="m-0">
        {props.shouldNavigateToAsset ? (
          <span>{props.service.asset?.name}</span>
        ) : (
          <Link
            to={props.navigateTo}>
            {props.service.asset?.name}
          </Link>
        )}
      </p>
    </Col>
  </Container>;
}

function ServiceJob(props: {service: ServiceRequest}) {
  return <>
    {props.service.job ? (
      <Container fluid className="d-md-inline-flex flex-column m-0 p-0">
        <Link className="text-md-center" to={`/project/${props.service.job.id}`}>{props.service.job.number}</Link>
        <p className="m-0 text-muted text-md-center">{getDate(props.service.job.created)}</p>
      </Container>
    ) : (
      <p>-</p>
    )}
  </>;
}

export const ServiceTable = ({ services, handleSearch, searchKey }: ServiceTableProps) => {

  const location = useLocation()
  return (
    <>
      <SearchBar searchKey={`serviceTable.${location.pathname}.${searchKey}`} placeholder="Search service" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={services.map(service => {
            const assetLocation = `/asset/${service.asset?.id}`
            const alreadyOnAssetPage = location.pathname === assetLocation
            return ({
              id: service.id,
              header: <ServiceDescription service={service} shouldNavigateToAsset={alreadyOnAssetPage} navigateTo={assetLocation} />,
              expanded: <div>
                <div>
                  Active Job: <ServiceJob service={service} />
                </div>
                <div>
                  Currently Due: {getWindow(service.windowStart, service.windowEnd, service.preferredStartTime)}
                </div>
                <p className="m-0 text-muted">{service.description}</p>
              </div>,
            })
          })} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Description</th>
                <th className="text-nowrap">Active Job</th>
                <th className="text-nowrap">Currently Due</th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {services.length > 0 ? (
                services.map(service => {
                  const assetLocation = `/asset/${service.asset?.id}`
                  const alreadyOnAssetPage = location.pathname === assetLocation
                  return (
                    <tr key={service.id}>
                      <td>
                        <Col className="m-0 p-0" style={{minWidth: '300px'}}>
                          <ServiceDescription service={service} shouldNavigateToAsset={alreadyOnAssetPage}
                                              navigateTo={assetLocation} />
                          <p className="m-0 text-muted">{service.description}</p>
                        </Col>
                      </td>
                      <td className="text-center">
                        <ServiceJob service={service} />
                      </td>
                      <td className="text-center">
                        {getWindow(service.windowStart, service.windowEnd, service.preferredStartTime)}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No services found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
