import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { approveQuote } from '../../api/requests';

interface ApproveModalProps {
  quoteId: string;
  show: boolean;
  handleClose: () => void;
  onApprove: () => void;
}

export const ApproveModal = ({ quoteId, show, handleClose, onApprove }: ApproveModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onClose = () => {
    setError(undefined);
    handleClose();
  }

  const handleAccept = () => {
    setError(undefined);
    setLoading(true);
    approveQuote(
      () => {
        onApprove();
        handleClose();
        toast.success('Quote approved successfully');
        setLoading(false);
      },
      () => {
        setError('Error accepting quote. Please try again later.');
        setLoading(false);
      },
      quoteId,
    );
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Approve Quote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to approve this quote? This action cannot be undone.
        {error && (
          <div className="alert alert-danger mt-3 p-2">
            <p className="mb-0">{error}</p>
          </div>
        )}
        <div className="w-100 d-flex justify-content-end mt-2">
          <Button variant="danger" onClick={onClose} disabled={loading}>
            Cancel
            {loading && (
              <span className="spinner-border spinner-border-sm ms-1" />
            )}
          </Button>
          <Button className="ms-2" variant="primary" onClick={handleAccept} disabled={loading}>
            Accept
            {loading && (
              <span className="spinner-border spinner-border-sm ms-1" />
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
