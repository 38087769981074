import Card from 'react-bootstrap/Card';
import { useAppSelector } from '../hooks/redux';
import { SectionContainer } from '../components/container/SectionContainer';
import { CardContainer } from '../components/container/CardContainer';
import { UpdateProfile } from '../components/settings/UpdateProfile';
import { ChangePassword } from '../components/settings/ChangePassword';
import { UpdateCompany } from '../components/settings/UpdateCompany';

export const AccountSettings = () => {
  const { user } = useAppSelector(state => state.profile);

  return (
    <SectionContainer style={{ maxWidth: '900px' }}>
      <CardContainer className={"p-3 p-md-0"}>
        <Card.Title>
          Account Settings
        </Card.Title>
        <Card.Body className="pb-0 pe-0 ps-0">
          {user && (
            <>
              <UpdateProfile />
              <hr />
            </>
          )}
          {user && user.companyId && (
            <>
              <UpdateCompany />
              <hr />
            </>
          )}
          <ChangePassword />
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  );
}
