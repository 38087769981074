import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown'
import { useParams } from "react-router-dom";
import { MdPhone } from 'react-icons/md';
import { parseDate } from '../utils/parseDate';
import { useApiData } from '../hooks/useApiData';
import { QuoteDetail } from '../interfaces/interfaces';
import { getQuoteDetailRequest } from '../api/requests';
import { BackButton } from '../components/common/BackButton';
import { DetailSkeleton } from '../components/common/DetailSkeleton';
import { Error } from '../components/Error';
import { CardContainer } from '../components/container/CardContainer';
import { SectionContainer } from '../components/container/SectionContainer';
import { QuoteItemTable } from '../components/quote/QuoteItemTable';
import { RequestChangeModal } from '../components/quote/RequestChangeModal';
import { ApproveModal } from '../components/quote/ApproveModal';
import { QuoteServiceTable } from '../components/quote/QuoteServiceTable';
import {baseURL} from '../api/api';
import Ratio from 'react-bootstrap/esm/Ratio';

export const Quote = () => {
  const { id } = useParams<{ id: string }>();
  const { status, onSuccess, onFailure } = useApiData<QuoteDetail>();
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const pdfUrl = `${baseURL}/quote/${id}/pdf`;

  useEffect(() => {
    if (!id) return;
    getQuoteDetailRequest(onSuccess, onFailure, id);
  }, [id]);

  const displayDate = (date: number) => {
    const {day, month, year} = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  const displayPrice = (price: number) => {
    return `$${price.toFixed(2)}`;
  }

  const onApprove = () => {
    if (status.type === 'SUCCESS') {
      status.data.status = 'accepted';
    }
  }

  if (status.type === 'LOADING') {
    return (
      <SectionContainer>
        <DetailSkeleton />
      </SectionContainer>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={status.error} type="Quote" link={{ title: 'Go to Quotes', ref: '/quotes' }} />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
      <RequestChangeModal quoteId={id?? ''} show={showChangeModal} handleClose={() => setShowChangeModal(false)} />
      <ApproveModal quoteId={id?? ''} show={showApproveModal} handleClose={() => setShowApproveModal(false)} onApprove={onApprove} />
      <CardContainer className={"d-flex flex-fill"}>
        <Card.Title>
          <div className="d-md-flex flex-row align-items-start">
            <h3 className="flex-fill m-md-0 m-2">
              <BackButton className="p-0 me-3 float-left" />
              {status.data.name}
              {status.data.status === 'accepted' && (
                <span className="text-success fs-6 ms-3">Approved</span>
              )}
            </h3>
            <Button className="text-nowrap ms-2 btn-warning" onClick={() => { setShowChangeModal(true) }}>
              Request Change
            </Button>
            <Button className="text-nowrap ms-2 btn-success" onClick={() => { setShowApproveModal(true) }}>Approve</Button>
            <Button href={pdfUrl} className="text-nowrap ms-2 btn-info">Download PDF</Button>
          </div>
        </Card.Title>
        <Card.Body className="pb-0 pe-0 ps-0 d-flex flex-fill" >
          <iframe className={'d-flex flex-fill'} src={pdfUrl} title={"PDF view"} />
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  );
}
