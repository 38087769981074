import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAccountRequest } from '../../api/requests';
import { useForm, SubmitHandler} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup' 
import * as Yup from 'yup';
import { AppError, LoginResponse } from '../../interfaces/interfaces';
import { PublicHeader } from '../../components/header/PublicHeader';
import { useAppDispatch } from '../../hooks/redux';
import Constants from '../../constants/fieldConstants';
import './auth.css';
import { NotFound } from '../NotFound';

interface ConfirmInput {
  password: string;
  repeatPassword: string;
}

export const ConfirmAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email, code } = useParams();
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .min(Constants.passwordMinLength, 'Password must be at least 8 characters long')
      .max(Constants.passwordMaxLength, 'Password too long')
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'Password must contain at least one letter and one number'),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password')], "Passwords do not match")
  })
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const { register, handleSubmit, formState } = useForm<ConfirmInput>({ resolver: yupResolver(formSchema), mode: 'onTouched' });

  const onSuccess = (data: LoginResponse) => {
    dispatch({ type: 'auth/login', payload: data });
    navigate('/', { replace: true });
  }

  const onFailure = (_error: AppError) => {
    if (_error.errorCode === 'AUTHENTICATION') {
      setMessage('The provided code is invalid. If you think this is an error, please contact an administrator.')
    } else {
      setMessage(_error.message);
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<ConfirmInput> = (input: ConfirmInput) => {
    if (loading) return;
    if (!code || !email) {
      setMessage('Invalid code or email.');
      return;
    };
    setLoading(true);
    setMessage(undefined);
    confirmAccountRequest({ code, email, password: input.password }, onSuccess, onFailure);
  };

  const validEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  if (!email || !code || !validEmail(email)) {
    return <NotFound />
  }

  return (
    <div className="auth-container">
      <form className="card auth-card" onSubmit={handleSubmit(onSubmit)}>
        <h3>Create Password</h3>
        <p>Welcome to Customer Portal! Please create a password to finish setting up your account</p>
        <Form.Group className="form-group mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Enter password"
            type="password"
            maxLength={Constants.passwordMaxLength}
            disabled={loading}
            required
            {...register('password')}
          />
          <p className={`mt-1 mb-1 ${formState.errors.password ? "text-danger" : "text-muted"}`}>{`Password must be at least ${Constants.passwordMinLength} characters long and contain at least one letter and one number`}</p>
        </Form.Group>
        <Form.Group className="form-group mt-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            placeholder="Repeat password"
            type="password"
            maxLength={Constants.passwordMaxLength}
            disabled={loading}
            required
            {...register('repeatPassword')}
          />
          {formState.errors.repeatPassword && <p className="text-danger mt-1 mb-1">{formState.errors.repeatPassword.message}</p>}
        </Form.Group>
        {message && (
          <div className="alert mt-3 p-2 alert-danger">
            <p className="mb-0">{message}</p>
          </div>
        )}
        <div className="d-grid mt-4">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>Confirm</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
