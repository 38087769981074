
type FilterFunc<K> = (element: K) => boolean;

type Group<U> = {
  searchTerm: string;
  searchFields: string[];
  filter: FilterFunc<U>;
  sortBy?: string;
}
export const useFiltered = <T>(data: T[], groups: Group<T>[]) => {

  const results: T[][] = []

  for (const group of groups) {
    const { searchTerm, searchFields, filter } = group;
    const filtered = data.filter(elem =>
      filter(elem) &&
      searchFields.some(field => {
        const value = getNestedField(elem, field);
        return value != null && value.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );

    if (group.sortBy) {
      filtered.sort((a, b) => {
        const aVal = getNestedField(a, group.sortBy as any);
        const bVal = getNestedField(b, group.sortBy as any);
        if (aVal == null || bVal == null) return 0;
        if (aVal == null) return 1;
        if (bVal == null) return -1;
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
      });
    }


    results.push(filtered);
  }

  return results;
}

const getNestedField = (object: any, field: string): any => {
  const fields = field.split('.');
  let result = object;
  for (const field of fields) {
    if (result == null) return null;
    result = result[field];
  }
  return result;
};
