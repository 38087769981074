import Modal from 'react-bootstrap/Modal';
import {Form, InputGroup, Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useForm} from 'react-hook-form';
import {createAttachment, deleteAttachment, editAttachment, EditAttachmentData} from '../api/requests';
import {Category, DocumentEntityTypes} from '../interfaces/interfaces';
import {useState} from 'react';
import {ImBin2, ImPencil} from 'react-icons/im';
import {useDocumentCategories} from '../hooks/useDocumentCategories';

export const categories = [
  {id: 1, name: 'Job Paperwork'},
  {id: 2, name: 'Job Vendor Bill'},
  {id: 3, name: 'Job Quality Control Picture'},
  {id: 5, name: 'Deficiency Repair Proposal'},
  {id: 7, name: 'Generic Attachment (attachments that do not fall into a more specific category)'},
  {id: 8, name: 'Avatar Image'},
  {id: 9, name: 'Import'},
  {id: 10, name: 'Blank Paperwork'},
  {id: 11, name: 'Work Acknowledgement'},
  {id: 12, name: 'Logo'},
  {id: 14, name: 'Job Invoice'},
];

type FormData = {
  description: string;
  file: FileList | null;
  category: string;
}

const defaultValues: FormData = {
  description: '',
  file: null,
  category: '',
}

type Props = {
  onEdited?: () => void;
  documentId: number;

  entityId: number;
  entityType: DocumentEntityTypes;
  initialState: EditAttachmentData;
  availableCategories: Category[];
}

export function EditDocument(props: Props) {
  const [editing, setEditing] = useState<number>();
  const [loadingEdit, setLoadingEdit] = useState(false);

  const {register, handleSubmit} = useForm({
    defaultValues: props.initialState,
  })

  const handleEdit = (id: number) => () => {
    setEditing(id);
  }

  const cancelEdit = () => {
    setEditing(undefined);
  }

  const performEdit = (data: EditAttachmentData) => {
    if (editing) {
      setLoadingEdit(true);
      editAttachment(
        data => {
          setLoadingEdit(false);
          setEditing(undefined);
          props.onEdited?.();
        },
        error => {
          setLoadingEdit(false);
          setEditing(undefined);
        },
        editing.toString(),
        props.entityId.toString(),
        props.entityType,
        data,
      )
    }
  };

  return (
    <>
      <button onClick={handleEdit(props.documentId)} className="btn btn-primary mx-2"><ImPencil /></button>
      <Modal
        show={editing !== undefined}
        onHide={cancelEdit}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as={"textarea"}
            rows={3}
            {...register("description")}
          />
          <Form.Select className={"mt-3"} {...register("categoryId")}>
            {props.availableCategories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button disabled={loadingEdit} variant="primary" onClick={handleSubmit(performEdit)}>
            {loadingEdit && <Spinner animation="border" size="sm" className={"me-2"} />}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
