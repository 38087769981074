import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { requestChangeQuote } from '../../api/requests';
import Constants from '../../constants/fieldConstants';

interface RequestChangeModalProps {
  quoteId: string;
  show: boolean;
  handleClose: () => void;
}

export const RequestChangeModal = ({ quoteId, show, handleClose }: RequestChangeModalProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [description, setDescription] = useState<string>();

  const onClose = () => {
    setError(undefined);
    setDescription(undefined);
    handleClose();
  }

  const handleSubmit = () => {
    if (!description) return;
    setError(undefined);
    setLoading(true);
    requestChangeQuote(
      () => {
        handleClose();
        toast.success('Change request submitted successfully');
        setDescription(undefined);
        setLoading(false);
        navigate('/quotes'); // status changes to draft so its no longer visible
      },
      () => {
        setLoading(false);
        setError('Error submitting request. Please try again later.');
      },
      quoteId,
      description
    );
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Request Change</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => { 
          e.preventDefault();
          handleSubmit();
        }}>
          <Form.Group className="mb-3">
            <Form.Label>Enter any parts, services, or labor on this quote that you would like the vendor to change</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Describe the change to be made"
              maxLength={Constants.descriptionMaxLength}
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          {error && (
            <div className="alert alert-danger mt-3 p-2">
              <p className="mb-0">{error}</p>
            </div>
          )}
          <div className="w-100 d-flex justify-content-end">
            <Button variant="danger" onClick={onClose} disabled={loading}>
              Cancel
              {loading && (
                <span className="spinner-border spinner-border-sm ms-1" />
              )}
            </Button>
            <Button className="ms-2" variant="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <span className="spinner-border spinner-border-sm ms-1" />
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
