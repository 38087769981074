import Joi from 'joi';
import {
  CommonResponse,
  AppError,
  CommonSuccess,
  FieldError,
  User,
  LoginResponse,
  Location,
  Locations,
  Address,
  Contact,
  ServiceRequest,
  Services,
  CompanyPreview,
  LocationDetail,
  Company,
  Asset,
  Assets,
  AssetDetail,
  Job,
  JobInvoice,
  Jobs,
  JobDetail,
  RefreshToken,
  ProjectJob,
  Projects,
  CompanyData,
  ServiceLines,
  Attachment,
  Deficiency,
  ServiceLine,
  Quote,
  QuoteDetail,
  QuoteItem,
} from './interfaces';

export const AppErrorSchema = Joi.object<AppError>({
  errorCode: Joi.string().required(),
  message: Joi.string().required(),
  errors: Joi.array().items(Joi.object<FieldError>({
    field: Joi.string().required(),
    message: Joi.string().required(),
  }))
});

export const CommonSuccessSchema = Joi.object<CommonSuccess>({
  message: Joi.string().required(),
});

export function CommonResponseSchema<T>() {
  return Joi.object<CommonResponse<T>>({
    status: Joi.string().valid('success', 'error').required(),
    data: Joi.when('status', {
      is: 'success',
      then: Joi.object<T>(),
      otherwise: Joi.any().forbidden(),
    }),
    error: Joi.when('status', {
      is: 'error',
      then: AppErrorSchema.required(),
      otherwise: Joi.any().forbidden(),
    }),
  });
}

export const AddressSchema = Joi.object<Address>({
  street: Joi.string().allow('').required(),
  city: Joi.string().allow('').required(),
  state: Joi.string().allow('').required(),
  postalCode: Joi.string().allow('').required(),
});

export const CompanyPreviewSchema = Joi.object<CompanyPreview>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  status: Joi.string().valid('pending', 'active', 'on_hold', 'inactive').required(),
});

export const CompanySchema = Joi.object<Company>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  status: Joi.string().valid('pending', 'active', 'on_hold', 'inactive').required(),
  phoneNumber: Joi.string().allow('').required(),
  address: AddressSchema.required(),
});

export const UserSchema = Joi.object<User>({
  id: Joi.string().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().required(),
  phoneNumber: Joi.string().allow(''),
  companyId: Joi.string().allow(''),
  acceptedTermsDate: Joi.string().allow(''),
}).unknown(true);

export const LoginResponseSchema = Joi.object<LoginResponse>({
  token: Joi.string().required(),
  role: Joi.string().valid('Superadmin', 'Admin', 'User').required(),
  refreshToken: Joi.when('role', {
    is: 'Superadmin',
    then: Joi.string().allow(''),
    otherwise: Joi.string().required(),
  }),
  appRole: Joi.when('role', {
    is: 'Superadmin',
    then: Joi.string().allow(''),
    otherwise: Joi.string().valid('Admin', 'User').required(),
  }),
  user: Joi.when('role', {
    is: 'Superadmin',
    then: Joi.any().forbidden(),
    otherwise: UserSchema.required(),
  }),
}).unknown(true);

export const RefreshTokenSchema = Joi.object<{ status: 'success', data: RefreshToken}>({
  status: Joi.string().valid('success').required(),
  data: Joi.object({
    refreshToken: Joi.string().required(),
    token: Joi.string().required(),
  }).required(),
}).unknown(true);

export const AttachmentSchema = Joi.object<Attachment>({
  id: Joi.number().required(),
  contentUrl: Joi.string().uri().required(),
  fileType: Joi.string().required(),
  fileName: Joi.string().required(),
  description: Joi.string().allow(''),
});

export const ContactSchema = Joi.object<Contact>({
  id: Joi.number().required(),
  firstName: Joi.string().allow('').required(),
  lastName: Joi.string().allow('').required(),
  phone: Joi.string().allow('').required(),
  mobile: Joi.string().allow('').required(),
  alternatePhone: Joi.string().allow('').required(),
  email: Joi.string().allow('').required(),
  type: Joi.string().allow('').required(),
});

export const LocationSchema = Joi.object<Location>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
  phoneNumber: Joi.string().allow(''),
  email: Joi.string().allow(''),
  company: CompanyPreviewSchema.unknown(true).required(),
  primaryContact: ContactSchema.unknown(true).allow(null).required(),
  address: AddressSchema.unknown(true).required(),
}).unknown(true);

export const LocationsSchema = Joi.object<Locations>({
  locations: Joi.array().items(LocationSchema).required(),
}).unknown(true);

export const LocationDetailSchema = Joi.object<LocationDetail>({
  location: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow(''),
    email: Joi.string().allow(''),
    company: CompanySchema.unknown(true).required(),
    primaryContact: ContactSchema.unknown(true).allow(null).required(),
    address: AddressSchema.unknown(true).required(),
    attachments: Joi.array().items(AttachmentSchema.unknown(true)).required(),
  }).unknown(true).required(),
}).unknown(true);

export const ServiceLineSchema = Joi.object<ServiceLine>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  abbr: Joi.string().required(),
  icon: Joi.string().uri().required(),
});

export const ServiceLinesSchema = Joi.object<ServiceLines>({
  serviceLines: Joi.array().items(ServiceLineSchema.unknown(true)).required(),
}).unknown(true);

export const ServiceSchema = Joi.object<ServiceRequest>({
  id: Joi.number().required(),
  status: Joi.string().valid('open', 'in_progress', 'closed', 'canceled', 'void', 'pending').required(),
  description: Joi.string().allow('').required(),
  serviceLine: ServiceLineSchema.unknown(true).required(),
  asset: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'inactive').required(),
  }).unknown(true),
  location: Joi.number().allow(null),
  job: Joi.object({
    id: Joi.number().required(),
    number: Joi.number().required(),
    name: Joi.string().required(),
    type: Joi.string().required(),
    created: Joi.date().timestamp('unix').required(),
    updated: Joi.date().timestamp('unix').required(),
    dueBy: Joi.date().timestamp('unix').allow(null).required(),
    scheduledDate: Joi.date().timestamp('unix').allow(null).required(),
  }).unknown(true).allow(null).required(),
  windowStart: Joi.date().timestamp('unix').allow(null).required(),
  windowEnd: Joi.date().timestamp('unix').allow(null).required(),
  created: Joi.date().timestamp('unix').required(),
  updated: Joi.date().timestamp('unix').required(),
  serviceRecurrence: Joi.object({
    id: Joi.number().required(),
    description: Joi.string().allow('').required(),
    frequency: Joi.string().required(),
    repeatWeekday: Joi.boolean().required(),
  }).unknown(true).allow(null),
  estimatedPrice: Joi.number().allow(null).required(),
  duration: Joi.number().allow(null).required(),
  preferredStartTime: Joi.number().max(24 * 3600).allow(null).required(),
}).unknown(true);

export const ServicesSchema = Joi.object<Services>({
  services: Joi.array().items(ServiceSchema).required(),
}).unknown(true);

export const AssetSchema = Joi.object<Asset>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  status: Joi.string().valid('active', 'inactive').required(),
  type: Joi.string().required(),
  display: Joi.string().required(),
  serviceLine: ServiceLineSchema.unknown(true).allow(null).required(),
  properties: Joi.object({
    locationInSite: Joi.string().allow('').required(),
    manufacturer: Joi.string().allow('').required(),
    model: Joi.string().allow('').required(),
    serialNumber: Joi.string().allow('').required(),
    type: Joi.string().allow('').required(),
    notes: Joi.string().allow('').required(),
  }).unknown(true).required(),
}).unknown(true).required();

export const AssetsSchema = Joi.object<Assets>({
  assets: Joi.array().items(AssetSchema).required(),
}).unknown(true);

export const JobSchema = Joi.object<Job>({
  id: Joi.number().required(),
  number: Joi.number().required(),
  name: Joi.string().required(),
  type: Joi.string().required(),
  description: Joi.string().allow('').required(),
  status: Joi.string().valid('new', 'scheduled', 'canceled', 'bidding', 'completed', 'pending_invoice', 'sending_invoice', 'invoiced', 'closed').required(),
  dueBy: Joi.date().timestamp('unix').allow(null).required(),
  scheduledDate: Joi.date().timestamp('unix').allow(null).required(),
  estimatedPrice: Joi.number().allow(null).required(),
  currentAppointment: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('scheduled', 'unscheduled', 'canceled_by_vendor', 'canceled_by_customer', 'completed', 'no_show').required(),
    techs: Joi.array().items(Joi.object({
      id: Joi.number().required(),
      avatar: Joi.object({
        small: Joi.string().uri().allow('').required(),
        medium: Joi.string().uri().allow('').required(),
        large: Joi.string().uri().allow('').required(),
      }).unknown(true).required(),
      firstName: Joi.string().required(),
      lastName: Joi.string().required(),
      email: Joi.string().allow('').required(),
      phoneNumber: Joi.string().allow('').required(),
    }).unknown(true)).required(),
  }).unknown(true).allow(null).required(),
  location: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
  }).unknown(true).required(),
  invoices: Joi.array().items(Joi.object<JobInvoice>({
    id: Joi.number().required(),
    name: Joi.string().required(),
    number: Joi.string().required(),
    totalPrice: Joi.number().required(),
  }).unknown(true)).required(),
});

export const JobsSchema = Joi.object<Jobs>({
  jobs: Joi.array().items(JobSchema.unknown(true)).required(),
}).unknown(true);

export const AssetDetailSchema = Joi.object<AssetDetail>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  status: Joi.string().valid('active', 'inactive').required(),
  type: Joi.string().allow('').required(),
  display: Joi.string().allow('').required(),
  serviceLine: ServiceLineSchema.unknown(true).allow(null).required(),
  location: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow('').required(),
    email: Joi.string().allow('').required(),
    address: AddressSchema.unknown(true).required(),
  }).unknown(true).required(),
  company: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow('').required(),
    address: AddressSchema.unknown(true).required(),
  }).unknown(true).required(),
  properties: Joi.object({
    locationInSite: Joi.string().allow('').required(),
    manufacturer: Joi.string().allow('').required(),
    model: Joi.string().allow('').required(),
    serialNumber: Joi.string().allow('').required(),
    type: Joi.string().allow('').required(),
    notes: Joi.string().allow('').required(),
  }).unknown(true).required(),
  jobs: Joi.array().items(JobSchema.unknown(true)).required(),
  attachments: Joi.array().items(AttachmentSchema.unknown(true)).required(),
}).unknown(true);

export const JobDetailSchema = Joi.object<JobDetail>({
  id: Joi.number().required(),
  number: Joi.number().required(),
  name: Joi.string().required(),
  type: Joi.string().required(),
  description: Joi.string().allow('').required(),
  dueBy: Joi.date().timestamp('unix').allow(null).required(),
  scheduledDate: Joi.date().timestamp('unix').allow(null).required(),
  estimatedPrice: Joi.number().allow(null).required(),
  status: Joi.string().valid('new', 'scheduled', 'canceled', 'bidding', 'completed', 'pending_invoice', 'sending_invoice', 'invoiced', 'closed').required(),
  currentAppointment: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('scheduled', 'unscheduled', 'canceled_by_vendor', 'canceled_by_customer', 'completed', 'no_show').required(),
    techs: Joi.array().items(Joi.object({
      id: Joi.number().required(),
      avatar: Joi.object({
        small: Joi.string().uri().allow('').required(),
        medium: Joi.string().uri().allow('').required(),
        large: Joi.string().uri().allow('').required(),
      }).unknown(true).required(),
      firstName: Joi.string().required(),
      lastName: Joi.string().required(),
      email: Joi.string().allow('').required(),
      phoneNumber: Joi.string().allow('').required(),
    }).unknown(true)).required(),
  }).unknown(true).allow(null).required(),
  location: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow('').required(),
    email: Joi.string().allow('').required(),
    address: AddressSchema.unknown(true).required(),
  }).unknown(true).required(),
  company: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow('').required(),
    address: AddressSchema.unknown(true).required(),
  }).unknown(true).required(),
  invoices: Joi.array().items(Joi.object<JobInvoice>({
    id: Joi.number().required(),
    name: Joi.string().required(),
    number: Joi.string().required(),
    totalPrice: Joi.number().required(),
  }).unknown(true)).required(),
  asset: AssetSchema.unknown(true).allow(null).required(),
  attachments: Joi.array().items(AttachmentSchema.unknown(true)).required(),
}).unknown(true);

export const ProjectSchema = Joi.object<ProjectJob>({
  id: Joi.number().required(),
  number: Joi.number().required(),
  name: Joi.string().required(),
  type: Joi.string().required(),
  description: Joi.string().allow('').required(),
  dueBy: Joi.date().timestamp('unix').allow(null).required(),
  scheduledDate: Joi.date().timestamp('unix').allow(null).required(),
  estimatedPrice: Joi.number().allow(null).required(),
  status: Joi.string().valid('new', 'scheduled', 'canceled', 'bidding', 'completed', 'pending_invoice', 'sending_invoice', 'invoiced', 'closed').required(),
  currentAppointment: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('scheduled', 'unscheduled', 'canceled_by_vendor', 'canceled_by_customer', 'completed', 'no_show').required(),
    serviceLines: Joi.array().items(ServiceLineSchema.unknown(true)).required(),
    techs: Joi.array().items(Joi.object({
      id: Joi.number().required(),
      avatar: Joi.object({
        small: Joi.string().uri().allow('').required(),
        medium: Joi.string().uri().allow('').required(),
        large: Joi.string().uri().allow('').required(),
      }).unknown(true).required(),
      firstName: Joi.string().required(),
      lastName: Joi.string().required(),
      email: Joi.string().allow('').required(),
      phoneNumber: Joi.string().allow('').required(),
    }).unknown(true)).required(),
  }).unknown(true).allow(null).required(),
  location: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    status: Joi.string().valid('active', 'pending', 'on_hold', 'inactive').required(),
    phoneNumber: Joi.string().allow('').required(),
    email: Joi.string().allow('').required(),
    address: AddressSchema.unknown(true).required(),
  }).unknown(true).required(),
  asset: AssetSchema.unknown(true).allow(null).required(),
}).unknown(true);

export const ProjectsSchema = Joi.object<Projects>({
  jobs: Joi.array().items(ProjectSchema.unknown(true)).required(),
}).unknown(true);

export const CompanyDataSchema = Joi.object<CompanyData>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  phoneNumber: Joi.string().allow(''),
  address: Joi.object({
    street: Joi.string().allow(''),
    city: Joi.string().allow(''),
    state: Joi.string().allow(''),
    postalCode: Joi.string().allow(''),
  }).unknown(true).required(),
}).unknown(true);

export const DeficienciesSchema = Joi.object({
  deficiencies: Joi.array().items(Joi.object<Deficiency>({
    id: Joi.number().required(),
    reportedOn: Joi.date().timestamp('unix').allow(null).required(),
    severity: Joi.string().allow(''),
    title: Joi.string().allow(''),
    status: Joi.string().required(),
    description: Joi.string().allow(''),
    asset: Joi.object({
      id: Joi.number().required(),
      name: Joi.string().required(),
      type: Joi.string().required(),
    }).unknown(true).allow(null),
    location: Joi.object({
      id: Joi.number().required(),
      name: Joi.string().required(),
    }).unknown(true).allow(null),
    serviceLine: ServiceLineSchema.unknown(true).allow(null),
  }).unknown(true)).required(),
}).unknown(true);

export const QuotesSchema = Joi.object({
  quotes: Joi.array().items(Joi.object<Quote>({
    id: Joi.number().required(),
    name: Joi.string().required(),
    refNumber: Joi.string().required(),
    status: Joi.string().required(),
    description: Joi.string().allow('').required(),
    totalPrice: Joi.string().allow('').allow(null).required(),
    expiresOn: Joi.date().timestamp('unix').allow(null).required(),
    location: Joi.object({
      id: Joi.number().required(),
      name: Joi.string().required(),
    }).unknown(true).allow(null),
    serviceLines: Joi.array().items(ServiceLineSchema.unknown(true)).required(),
  }).unknown(true)).required(),
}).unknown(true);

export const QuoteDetailSchema = Joi.object<QuoteDetail>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  refNumber: Joi.string().required(),
  notes: Joi.string().allow(''),
  description: Joi.string().allow(''),
  type: Joi.string().allow('').required(),
  jobType: Joi.string().allow('').required(),
  status: Joi.string().required(),
  totalPrice: Joi.number().allow(null).required(),
  taxAmount: Joi.number().allow(null).required(),
  subtotal: Joi.number().allow(null).required(),
  expiresOn: Joi.date().timestamp('unix').allow(null).required(),
  created: Joi.date().timestamp('unix').required(),
  updated: Joi.date().timestamp('unix').required(),
  vendor: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    address: AddressSchema.unknown(true).required(),
    phoneNumber: Joi.string().allow(''),
    logo: Joi.object({
      id: Joi.number().required(),
      contentUrl: Joi.string().uri().required(),
    }).allow(null),
  }).unknown(true).required(),
  customer: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    address: AddressSchema.unknown(true).required(),
    phoneNumber: Joi.string().allow(''),
  }).unknown(true).required(),
  assignedTo: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    email: Joi.string().allow(''),
  }).unknown(true).allow(null),
  items: Joi.array().items(Joi.object<QuoteItem>({
    id: Joi.number().required(),
    description: Joi.string().allow(''),
    price: Joi.number().allow(null).required(),
    quantity: Joi.number().allow(null).required(),
    cost: Joi.number().allow(null).required(),
  }).unknown(true)).required(),
  terms: Joi.object({
    id: Joi.number().required(),
    name: Joi.string().required(),
    content: Joi.string().allow(''),
  }).unknown(true).allow(null),
  serviceRequests: Joi.array().items(Joi.object({
    id: Joi.number().required(),
    description: Joi.string().allow('').required(),
    asset: Joi.object({
      id: Joi.number().required(),
      name: Joi.string().required(),
    }).unknown(true).allow(null).required(),
    serviceLine: ServiceLineSchema.unknown(true).required(),
    windowStart: Joi.date().timestamp('unix').allow(null).required(),
    windowEnd: Joi.date().timestamp('unix').allow(null).required(),
    preferredStartTime: Joi.number().max(24 * 3600).allow(null).required(),
  }).unknown(true)).required(),
}).unknown(true);

export const TermsSchema = Joi.object({
  content: Joi.string().allow(''),
  date: Joi.date().iso().required(),
}).unknown(true);

export const CategorySchema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
  canDelete: Joi.boolean().optional(),
});
export const AttachmentCategoriesSchema = Joi.object({
  categories: Joi.array().items(CategorySchema),
});
