import {Attachment, DocumentEntityTypes} from '../interfaces/interfaces';
import Table from 'react-bootstrap/Table';
import {Form} from 'react-bootstrap';
import {useState} from 'react';
import {AddDocument} from './AddDocument';
import {DeleteDocument} from './DeleteDocument';
import {EditDocument} from './EditDocument';
import MobileConditional from './MobileConditional';
import SmallTable from './SmallTable';
import ManageCategories from './ManageCategories';
import {useDocumentCategories} from '../hooks/useDocumentCategories';
import {FiFilePlus} from 'react-icons/fi';

// document categories
// 1	Job Paperwork
// 2	Job Vendor Bill
// 3	Job Quality Control Picture
// 5	Deficiency Repair Proposal
// 7	Generic Attachment (attachments that do not fall into a more specific category)
// 8	Avatar Image
// 9	Import
// 10	Blank Paperwork
// 11	Work Acknowledgement
// 12	Logo
// 14	Job Invoice


type Props = {
  documents: Attachment[];
  entityId: number;
  entityType: DocumentEntityTypes;
  onDocumentsChanged?: () => void;
}

export const Documents = ({ documents, entityType, entityId, onDocumentsChanged }: Props) => {

  const [category, setCategory] = useState("all");
  const [show, setShow] = useState(false)

  const {status, refetch} = useDocumentCategories(entityId.toString(), entityType);
  console.log(status)
  const apiCategories = status.type === 'SUCCESS' ? status.data.custom : [];
  const defaultCategories = status.type === 'SUCCESS' ? status.data.default : [];
  const categories = [...defaultCategories, ...apiCategories]

  const handleClose = () => setShow(false);

  const handleDocumentChange = () => {
    refetch();
    onDocumentsChanged?.();
  }

  const filtered = documents.filter(doc => {
    if (category === "all") return true;

    return doc.category === category;
  })

  const getCategoryName = (id: string) => {
    return categories.find(cat => cat.id === id)?.name || "Unknown";
  };

  return (
    <div className="d-flex flex-column m-3 m-md-0">
      <div className={"flex-row d-flex align-items-center justify-content-between mb-2 flex-wrap"}>
        <div className={"flex-row d-flex flex-grow-1"}>
          <Form.Select value={category} onChange={e => {
            setCategory(e.currentTarget.value);
          }} className={"form-select w-auto"}>
            <option value="all">All</option>
            {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
          </Form.Select>
          <div className={"me-3"} />
          <ManageCategories entityType={entityType} entityId={entityId} categories={apiCategories} onCategoriesChanged={handleDocumentChange} />
        </div>
        <button onClick={() => setShow(true)} className="btn btn-primary align-self-end text-nowrap">
          <MobileConditional>
            <FiFilePlus className={""} />
            <div>Add Document</div>
          </MobileConditional>
        </button>
      </div>
      {filtered.length === 0 ? (
        <p>No documents found</p>
      ) : (
        <MobileConditional>
          <SmallTable
            data={filtered.map(doc => ({
              id: doc.id,
              expanded: (
                <div className={"d-flex flex-row justify-content-between align-items-center"}>
                  <div>
                    {doc.description}
                  </div>
                  <div>
                  <EditDocument
                    entityType={entityType}
                    entityId={entityId}
                    documentId={doc.id}
                    initialState={{
                      description: doc.description || "",
                      categoryId: doc.category,
                    }}
                    onEdited={onDocumentsChanged}
                    availableCategories={categories}
                  />
                  <DeleteDocument documentId={doc.id} onDeleted={onDocumentsChanged} entityId={entityId} entityType={entityType} />
                  </div>
                </div>
              ),
              header: (
                <a key={doc.id} target="_blank" rel="noreferrer" href={doc.contentUrl}>
                  {doc.fileName}
                </a>
              ),
            }))}
          />
          <Table bordered hover>
            <thead>
            <tr>
              <th>File Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {filtered.map((doc) => (
              <tr key={doc.id}>
                <td>
                  <a key={doc.id} target="_blank" rel="noreferrer" href={doc.contentUrl}>
                    {doc.fileName}
                  </a>
                </td>
                <td>
                  {doc.description}
                </td>
                <td>
                  {getCategoryName(doc.category)}
                </td>
                <td>
                  <EditDocument
                    entityType={entityType}
                    entityId={entityId}
                    documentId={doc.id}
                    initialState={{
                      description: doc.description || "",
                      categoryId: doc.category,
                    }}
                    onEdited={onDocumentsChanged}
                    availableCategories={categories}
                  />
                  <DeleteDocument documentId={doc.id} onDeleted={onDocumentsChanged} entityId={entityId} entityType={entityType} />
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </MobileConditional>
      )}
      <AddDocument
        entityType={entityType}
        entityId={entityId}
        show={show}
        onHide={handleClose}
        onUpload={handleDocumentChange}
        categories={categories}
      />
    </div>
  )
}
