import { useState } from 'react';
import {useSelector} from 'react-redux';
import {selectSort, setSort as saveSort} from '../store/slices/sort';
import {useAppDispatch} from './redux';

export interface Sort {
  field: string;
  direction: "asc" | "desc";
}

export const useSort = (key?: string) => {

  const dispatch = useAppDispatch();
  const [localSort, setLocalSort] = useState<Sort | null>(null);
  const persistedSort = useSelector(selectSort(key));

  const sort = key ? persistedSort : localSort;
  const setSort = (sort: Sort) => {
    if (key) {
      dispatch(saveSort({ key, sort }));
    } else {
      setLocalSort(sort);
    }
  };

  const handleSort = (field: string) => {
    if (sort && sort.field === field) {
      setSort({ field, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ field, direction: 'asc' });
    }
  };

  const compareFields = (a: string | number | null, b: string | number | null) => {
    if (!sort) { return 0; }
    if (a === null && b === null) { return 0; }
    if (a === null) { return sort.direction === 'asc' ? 1 : -1; }
    if (b === null) { return sort.direction === 'asc' ? -1 : 1; }
    if (a < b) { return sort.direction === 'asc' ? -1 : 1; }
    if (a > b) { return sort.direction === 'asc' ? 1 : -1; }
    return 0;
  };

  return { sort, handleSort, compareFields };
}
