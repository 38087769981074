import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { JobTable } from '../../components/project/JobTable';
import { Job, JobStatus } from '../../interfaces/interfaces';
import {useFiltered} from '../../hooks/useFiltered';

export const AssetJobs = ({ jobs }: { jobs: Job[] }) => {
  const [ showCompleted, setShowCompleted ] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [completedSearchTerm, setCompletedSearchTerm] = useState('');

  const [filtered, filteredCompleted] = useFiltered(jobs, [
    {
      filter: s => s.status !== JobStatus.Scheduled,
      searchTerm: searchTerm,
      searchFields: ['location.name', 'description', 'status', 'type'],
    },
    {
      filter: s => s.status === JobStatus.Completed,
      searchTerm: completedSearchTerm,
      searchFields: ['location.name', 'description', 'status', 'type'],
    }
  ]);

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const handleCompletedSearch = (search: string) => {
    setCompletedSearchTerm(search)
  }

  return (
    <Container fluid className="p-1 m-0 justify-content-center">
      <JobTable searchKey={"assetJobsCurrent"} jobs={filtered} handleSearch={handleSearch} />
      <Container fluid className="mt-4 d-flex flex-row p-0">
        <Button
          variant="light"
          className="mb-3 flex-grow-1 border"
          onClick={() => setShowCompleted(!showCompleted)}
        >
          <div className="flex-grow-1 d-flex flex-row align-items-center">
            <h5 className="m-0 flex-grow-1">Completed projects</h5>
            <h6 className="m-0">
              {showCompleted ? <MdKeyboardArrowUp size={26} /> : <MdKeyboardArrowDown size={26} />}
            </h6>
          </div>
        </Button>
      </Container>
      {showCompleted && (
        <JobTable searchKey={"assetJobsCompleted"} jobs={filteredCompleted} handleSearch={handleCompletedSearch} />
      )}
    </Container>
  );
}
