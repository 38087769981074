import Card from 'react-bootstrap/Card'
import './container.css';

interface CardContainerProps {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  className?: string;
}

export const CardContainer = ({ children, style = {}, className }: CardContainerProps) => {
  return (
    <Card className={'custom-card w-100 h-100 p-0 p-md-4 m-0 ' + className} style={style}>
      {children}
    </Card>
  )
}
