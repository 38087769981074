import {useEffect, useState} from 'react';
import {getTermsOfService} from '../api/requests';
import {Terms} from '../interfaces/interfaces';

export const useTerms = () => {
  const [terms, setTerms] = useState<Terms>();

  useEffect(() => {
    getTermsOfService(terms => {
      setTerms(terms)
    }, e => {
      console.log("Error getting terms of service: ", e)
    })
  }, []);

  return terms;
}
