import { Sort } from "../../hooks/useSort";
import { TbArrowDown, TbArrowUp, TbArrowsDownUp } from "react-icons/tb"; 

interface SortButtonProps {
  field: string;
  sort: Sort | null;
  handleSort: (field: string) => void;
}

export const SortButton = ({ field, sort, handleSort }: SortButtonProps) => {
  const onSort = () => {
    handleSort(field);
  };

  if (sort && sort.field === field) {
    return (
      <h6 className="m-0 d-inline-flex">
        {sort.direction === "asc" ? (
          <TbArrowUp onClick={onSort} style={{ cursor: 'pointer' }} />
        ) : (
          <TbArrowDown onClick={onSort} style={{ cursor: 'pointer' }} />
        )}
      </h6>
    );
  }

  return (
    <p className="text-muted m-0 d-inline-flex">
      <TbArrowsDownUp onClick={onSort} style={{ cursor: 'pointer' }} />
    </p>
  );
}
