import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from '../../assets/mis-logo.jpg';
import './header.css';

export const PublicHeader = () => {
  return (
    <div className="header-main-container border-bottom">
      <Navbar className="p-2 justify-content-center" expand="md" style={{ backgroundColor: 'white', zIndex: '100' }}>
        <Navbar.Brand className="p-0 me-1 ms-1 flex-grow-1 d-flex align-items-center justify-content-center">
          <Image className="me-3 ms-3" src={logo} alt="Logo" style={{ height: '38px', objectFit: 'contain' }} />
          <h4 className="m-0 me-3 header-title">Customer Portal</h4>
        </Navbar.Brand>
      </Navbar>
    </div>
  );
}
