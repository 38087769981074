import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import { Asset } from '../../interfaces/interfaces'
import './asset.css'

export function AssetTitle(props: {id: number, name: string, display: string}) {
  return <h6 className="m-0">
    <Link to={`/asset/${props.id}`}>
      {props.name}
    </Link>
    {''} - {props.display}
  </h6>;
}

export function AssetDetails(props: {properties: {locationInSite: string; manufacturer: string; model: string; serialNumber: string; type: string; notes: string}}) {
  return <>
    {props.properties.locationInSite && (
      <p className="m-0 p-0">
        <span>Location In Site: </span>
        <span className="text-muted">{props.properties.locationInSite}</span>
      </p>
    )}
    {props.properties.type && (
      <p className="m-0 p-0">
        <span>Type: </span>
        <span className="text-muted">{props.properties.type}</span>
      </p>
    )}
    {props.properties.manufacturer && (
      <p className="m-0 p-0">
        <span>Manufacturer: </span>
        <span className="text-muted">{props.properties.manufacturer}</span>
      </p>
    )}
    {props.properties.model && (
      <p className="m-0 p-0">
        <span>Model: </span>
        <span className="text-muted">{props.properties.model}</span>
      </p>
    )}
    {props.properties.serialNumber && (
      <p className="m-0 p-0">
        <span>Serial: </span>
        <span className="text-muted">{props.properties.serialNumber}</span>
      </p>
    )}
  </>;
}

export const AssetTableItem = ({ asset }: { asset: Asset }) => {
  return (
    <Col className="m-0 p-0">
      <Container fluid className="d-inline-flex flex-row p-0 mb-1">
        <AssetTitle id={asset.id} name={asset.name} display={asset.display} />
      </Container>
      <Container fluid className="d-inline-flex flex-column p-0" style={{minWidth: '300px', flexWrap: 'wrap'}}>
        <Row className="m-0 asset-row">
          <AssetDetails properties={asset.properties} />
        </Row>
      </Container>
      {asset.properties.notes && (
        <p className="m-0 p-0">
          <span className="fw-bold">Notes: </span>
          <span>{asset.properties.notes}</span>
        </p>
      )}
    </Col>
  )
}
