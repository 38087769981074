import { useEffect, useState } from 'react';
import { getProjectsRequest } from '../../api/requests';
import { TableSkeleton } from '../../components/common/TableSkeleton';
import { CardContainer } from '../../components/container/CardContainer';
import { SectionContainer } from '../../components/container/SectionContainer';
import { Error } from '../../components/Error';
import { ProjectServices } from '../../components/project/ProjectServices';
import { ProjectJobs } from '../../components/project/ProjectJobs';
import { useApiData } from '../../hooks/useApiData';
import {JobStatus, ProjectJob, Projects as ProjectsType, ProjectService} from '../../interfaces/interfaces';
import { TabContainer, TabsContainer } from '../../components/tabs/TabsContainer';

export const Projects = () => {
  const upcoming = useApiData<ProjectsType>();
  const history = useApiData<ProjectsType>();
  // const [ filtered, setFiltered ] = useState<ProjectJob[]>([]);
  // const [ filteredCompleted, setFilteredCompleted ] = useState<ProjectJob[]>([]);

  /*
  const handleSearch = (search: string) => {
    if (status.type !== 'SUCCESS') return;
    const searchString = search.toLowerCase();
    const filter = status.data.filter(job => {
      return (
        (job.number.toString().toLowerCase().includes(searchString)
        || job.description.toLowerCase().includes(searchString)
        || job.asset?.name.toLowerCase().includes(searchString)
        || job.location.name.toLowerCase().includes(searchString)
        || job.type.toLowerCase().includes(searchString))
        && (job.status === JobStatus.Scheduled)
      );
    });
    filter.sort((a, b) => {
      if (a.dueBy === null && b.dueBy === null) return 0;
      if (a.dueBy === null) return 1;
      if (b.dueBy === null) return -1;
      return a.dueBy - b.dueBy;
    });
    setFiltered(filter);
  }
  */

  /*const handleCompletedSearch = (search: string) => {
    if (status.type !== 'SUCCESS') return;
    const searchString = search.toLowerCase();
    const filter = status.data.filter(job => {
      return (
        (job.number.toString().toLowerCase().includes(searchString)
        || job.location.name.toLowerCase().includes(searchString)
        || job.type.toLowerCase().includes(searchString))
        && (job.status === JobStatus.Completed)
      );
    });
    setFilteredCompleted(filter);
  }*/

  /*const onRequestSuccess = (data: ProjectsType) => {
    let scheduled: ProjectJob[] = [];
    let completed: ProjectJob[] = [];
    data.jobs.forEach(job => {
      if (job.status === JobStatus.Scheduled) {
        scheduled.push(job);
      } else if (job.status === JobStatus.Completed) {
        completed.push(job);
      }
    });
    scheduled.sort((a, b) => {
      if (a.dueBy === null && b.dueBy === null) return 0;
      if (a.dueBy === null) return 1;
      if (b.dueBy === null) return -1;
      return a.dueBy - b.dueBy;
    });
    setFiltered(scheduled);
    setFilteredCompleted(completed);
    onSuccess(data.jobs);
  }*/

  useEffect(() => {
    getProjectsRequest(history.onSuccess, history.onFailure, 'history');
    getProjectsRequest(upcoming.onSuccess, upcoming.onFailure, 'upcoming');
  }, []);

  if (history.status.type === 'LOADING' || upcoming.status.type === 'LOADING') {
    return (
      <SectionContainer>
        <TableSkeleton />
      </SectionContainer>
    );
  }

  if (history.status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={history.status.error} type="Projects" />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  if (upcoming.status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={upcoming.status.error} type="Projects" />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <TabsContainer defaultKey={"upcoming"}>
        <TabContainer eventKey={"upcoming"} title={"Open Projects"}>
          <CardContainer style={{marginTop: 0}}>
            <h4 className="mb-3 mt-md-0 mt-3 mx-md-0 mx-3">Jobs</h4>
            <ProjectJobs projects={upcoming.status.data.jobs} />
          </CardContainer>
          <CardContainer className={"mt-4"}>
            <h4 className="mb-3 mt-md-0 mt-3 mx-md-0 mx-3">Services</h4>
            <ProjectServices projects={upcoming.status.data.services} />
          </CardContainer>
        </TabContainer>
        <TabContainer eventKey={"history"} title={"History"}>
          <CardContainer>
            <h4 className="mb-3 mt-md-0 mt-3 mx-md-0 mx-3">Jobs</h4>
            <ProjectJobs projects={history.status.data.jobs} />
          </CardContainer>
          <CardContainer className={"mt-4"}>
            <h4 className="mb-3 mt-md-0 mt-3 mx-md-0 mx-3">Services</h4>
            <ProjectServices projects={history.status.data.services} />
          </CardContainer>
        </TabContainer>
      </TabsContainer>
    </SectionContainer>
  );
}
