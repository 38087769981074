import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { LoginResponse, RefreshToken, Session } from '../../interfaces/interfaces';

export interface SessionState {
  session: Session | null,
}

const initialState: SessionState = {
  session: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginResponse>) {
      const { user, ...session } = action.payload;
      return { session };
    },
    logout() {
      return initialState;
    },
    refreshToken(state, action: PayloadAction<RefreshToken>) {
      return (state.session) ? {
        session: {
          ...state.session,
          token: action.payload.token,
          refreshToken: action.payload.refreshToken
        }
      } : state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, _ => {
      return initialState;
    });
  }
});

export const { login, logout, refreshToken } = authSlice.actions;

export default authSlice.reducer;
