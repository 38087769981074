import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdEdit } from 'react-icons/md';

export const EditButton = ({ onClick, disabled = false }: { onClick: () => void, disabled?: boolean }) => {
  return (
    <span>
      <OverlayTrigger
        placement="bottom"
        overlay={(props) => <Tooltip {...props}>Edit</Tooltip>}
      >
        <button onClick={onClick} disabled={disabled} style={{ border: 'none', background: 'transparent' }}>
          <MdEdit className="ps-1 pb-1" size={22} />
        </button>
      </OverlayTrigger>
    </span>
  );
}