import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { Asset, Assets } from '../../interfaces/interfaces';
import { useApiData } from '../../hooks/useApiData';
import { getLocationAssetsRequest } from '../../api/requests';
import { TableSkeleton } from '../../components/common/TableSkeleton';
import {AssetDetails, AssetTableItem, AssetTitle} from '../../components/asset/AssetTableItem';
import { FaBell, FaTv, FaHistory } from 'react-icons/fa';
import { SearchBar } from '../../components/common/SearchBar';
import { SmallError } from '../../components/SmallError';
import {useFiltered} from '../../hooks/useFiltered';
import MobileConditional from '../../components/MobileConditional';
import SmallTable from '../../components/SmallTable';

export const LocationAssets = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { status, onSuccess, onFailure } = useApiData<Asset[]>();
  const [searchTerm, setSearchTerm] = useState('');

  const [filtered] = useFiltered(status.type === 'SUCCESS' ? status.data : [], [
    {
      filter: s => true,
      searchTerm: searchTerm,
      searchFields: [
        'name',
        'display',
        'properties.locationInSite',
        'properties.type',
        'properties.manufacturer',
        'properties.model',
        'properties.serialNumber',
      ],
      sortBy: 'name',
    }
  ])

  // const [ filtered, setFiltered ] = useState<Asset[]>([]);
  //
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  useEffect(() => {
    if (!id) return;
    getLocationAssetsRequest((data: Assets) => {
      onSuccess(data.assets);
    }, onFailure, id);
  }, [id]);

  const onMonitorClick = (id: number, type: 'alarm' | 'monitor' | 'trend') => {
    if (status.type !== 'SUCCESS') return;
    const asset = status.data.find(asset => asset.id === id);
    if (!asset) return;
    console.log("asset: ", asset);
    switch (type) {
      case 'alarm':
        if (!asset.link?.alerts) return;
        navigate(`/asset/${id}/${type}?link=${encodeURIComponent(asset.link?.alerts)}`);
        break;
      case 'monitor':
        if (!asset.link?.base) return;
        navigate(`/asset/${id}/${type}?link=${encodeURIComponent(asset.link?.base)}`);
        break;
      case 'trend':
        if (!asset.link?.trending) return;
        navigate(`/asset/${id}/${type}?link=${encodeURIComponent(asset.link?.trending)}`);
        break;
    }
  }

  if (status.type === 'LOADING') {
    return (
      <Container fluid className="p-1 m-0 justify-content-center">
        <TableSkeleton />
      </Container>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <Container fluid className="p-1 m-0 justify-content-center">
        <SmallError error={status.error} type="Assets" />
      </Container>
    );
  }

  return (
    <Container fluid className="p-1 m-0 justify-content-center">
      <SearchBar searchKey={"locationAssets"} placeholder="Search asset" handleSearch={handleSearch} />
      <Container fluid className="p-0" style={{ overflowX: 'auto' }}>
        <MobileConditional>
          <SmallTable data={filtered.map(asset => {
            return {
              id: asset.id,
              header: (
                <div>
                  <AssetTitle id={asset.id} name={asset.name} display={asset.display} />
                </div>
              ),
              expanded: (
                <div className={"d-flex flex-row justify-content-between"}>
                  <div>
                    <AssetDetails properties={asset.properties} />
                  </div>
                  <div>

                    <button disabled={!asset.link?.trending} onClick={() => onMonitorClick(asset.id, 'trend')} style={{ border: 'none', background: 'transparent' }}>
                      <FaHistory />
                    </button>
                    <button disabled={!asset.link?.alerts} onClick={() => onMonitorClick(asset.id, 'alarm')} style={{ border: 'none', background: 'transparent' }}>
                      <FaBell />
                    </button>
                    <button disabled={!asset.link?.base} onClick={() => onMonitorClick(asset.id, 'monitor')} style={{ border: 'none', background: 'transparent' }}>
                      <FaTv />
                    </button>
                  </div>
                </div>
              )
            }
          })} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Trends</th>
                <th>Alarms</th>
                <th>Monitoring</th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {filtered.length > 0 ? (
                filtered.map(asset => (
                  <tr key={asset.id}>
                    <td>
                      <AssetTableItem asset={asset} />
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      <button disabled={!asset.link?.trending} onClick={() => onMonitorClick(asset.id, 'trend')} style={{ border: 'none', background: 'transparent' }}>
                        <FaHistory />
                      </button>
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      <button disabled={!asset.link?.alerts} onClick={() => onMonitorClick(asset.id, 'alarm')} style={{ border: 'none', background: 'transparent' }}>
                        <FaBell />
                      </button>
                    </td>
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      <button disabled={!asset.link?.base} onClick={() => onMonitorClick(asset.id, 'monitor')} style={{ border: 'none', background: 'transparent' }}>
                        <FaTv />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No assets found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </Container>
  );
}
