import Container from 'react-bootstrap/Container';

interface SectionContainerProps {
  children: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

export const SectionContainer = ({ children, style = { maxWidth: '1600px' } }: SectionContainerProps) => {
  return (
    <Container fluid className="p-0 p-md-3 justify-content-center" style={style}>
      {children}
    </Container>
  );
};
