import {useParams, useSearchParams} from 'react-router-dom';
import {Monitor, MonitorLink} from '../../components/Monitor';

interface AssetMonitorProps {
  type: 'alarm' | 'monitor' | 'trend';
}

export const AssetMonitor = ({ type }: AssetMonitorProps) => {
  const { id } = useParams<{ id: string }>();

  const [searchParams] = useSearchParams();

  if (!id) return null;

  return (
    <MonitorLink link={searchParams.get("link")} />
  );
};
