import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CardContainer } from '../components/container/CardContainer';
import { SectionContainer } from '../components/container/SectionContainer';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';

export const ContactSupport = () => {
  return (
    <SectionContainer style={{ maxWidth: '900px' }}>
      <CardContainer className={"p-3 p-md-0"}>
        <Card.Title>
          Contact Support
        </Card.Title>
        <Card.Body className="p-0 m-0">
          <p className="m-0">
            If you are having issues with Customer Portal or have any doubts on how it works,
            please contact us providing a detailed description of the problem. We'll get back to you as soon as possible.
          </p>
          <Row className="p-0 mt-2 justify-content-around" xs={1} sm={2}>
            <Col className="p-0 m-0 justify-content-center text-center mt-5">
              <MdOutlineEmail size={28} />
              <h5>Email</h5>
              {/* TODO: change email */}
              <a className="m-0" href="mailto:support@modularintegrated.com">support@modularintegrated.com</a>
            </Col>
            <Col className="p-0 m-0 justify-content-center text-center mt-5">
              <MdOutlinePhone size={28} />
              <h5>Phone</h5>
              <p className="m-0 text-muted">Monday to Friday, 9am to 4pm</p>
              <p className="m-0">(727) 304-3435</p>
            </Col>
          </Row>
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  )
}
