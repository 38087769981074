import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { SearchBar } from '../common/SearchBar';
import { Quote } from '../../interfaces/interfaces';
import { useApiData } from '../../hooks/useApiData';
import { getQuotesRequest } from '../../api/requests';
import { TableSkeleton } from '../common/TableSkeleton';
import { Error } from '../Error';
import { ServiceLineIcon } from '../service/ServiceLineIcon';
import { parseDate } from '../../utils/parseDate';
import { useSort } from '../../hooks/useSort';
import { SortButton } from '../common/SortButton';
import "./QuotesTable.css";
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

export const QuotesTable = () => {
  const { status, onSuccess, onFailure } = useApiData<Quote[]>();
  const [ filtered, setFiltered ] = useState<Quote[]>([]);
  const [ searchTerm, setSearchTerm ] = useState<string>('');
  const { sort, handleSort, compareFields } = useSort("quotes");

  const expireDate = (date: number) => {
    const { year, month, day } = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const onRequestSuccess = (data: { quotes: Quote[] }) => {
    setFiltered(data.quotes);
    onSuccess(data.quotes);
  }

  useEffect(() => {
    if (status.type !== 'SUCCESS') return;
    let filter = status.data;
    if (searchTerm) {
      const searchString = searchTerm.toLowerCase();
      filter = status.data.filter(def => {
        return (
          def.refNumber.includes(searchString)
          || def.location?.name.toLowerCase().includes(searchString)
          || def.description.toLowerCase().includes(searchString)
          || def.serviceLines.some(serviceLine => serviceLine.name.toLowerCase().includes(searchString))
          || def.totalPrice?.includes(searchString)
        );
      });
    }
    if (sort) {
      filter = filter.sort((a, b) => {
        switch (sort.field) {
          case 'number':
            return compareFields(a.refNumber, b.refNumber);
          case 'location':
            return compareFields(a.location ? a.location.name : null, b.location ? b.location.name : null);
          case 'price':
            return compareFields(a.totalPrice ? a.totalPrice : null, b.totalPrice ? b.totalPrice : null);
          case 'expiresOn':
            return compareFields(a.expiresOn ? a.expiresOn : null, b.expiresOn ? b.expiresOn : null);
          default:
            return 0;
        }
      });
    }
    setFiltered(filter);
  }, [searchTerm, sort, status]);

  useEffect(() => {
    getQuotesRequest(onRequestSuccess, onFailure);
  }, []);

  if (status.type === 'LOADING') {
    return (
      <Container fluid className="m-0 p-0 flex-grow-1">
        <TableSkeleton n={4} />
      </Container>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <Container fluid className="m-0 p-0 flex-grow-1">
        <Error error={status.error} type="Quotes" />
      </Container>
    );
  }

  return (
    <>
      <SearchBar searchKey={"quotes"} placeholder="Search quote" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={filtered.map(quote => {
            return {
              id: quote.id,
              header: (
                <div className={"d-flex flex-row"}>
                  <div className={"d-flex flex-column"}>
                    <div className={"d-flex"}>
                      # <Link to={`/quote/${quote.id}`}>
                      {quote.refNumber}
                      <img src={require('../../assets/pdf.png')} alt="external link" className="ms-1 pdfIconSmall" />
                      </Link>
                    </div>
                    <div>
                      {quote.location ? (
                        <Link className={"figure-caption"} to={`/location/${quote.location.id}`}>{quote.location.name}</Link>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </div>
                    <p className="text-muted m-0 mt-2">{quote.description}</p>
                  </div>
                </div>
              ),
              expanded: (
                <div>
                  <div className="">
                    Service Lines: {quote.serviceLines.length > 0 ? (
                      <>
                        {quote.serviceLines.map((serviceLine) => (
                          <div className="d-inline-block me-2" key={serviceLine.id}>
                            <ServiceLineIcon data={serviceLine} size={24} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className="m-0">-</p>
                    )}
                  </div>
                  <div className="">Total price: {quote.totalPrice ? quote.totalPrice : '-'}</div>
                  <div className="d-flex flex-row">
                    Expires On: {quote.expiresOn ? (
                      expireDate(quote.expiresOn)
                    ) : (
                      <div className="m-0 mx-2">{"-"}</div>
                    )}
                  </div>
                </div>
              )
            }
          })} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Quote</p>
                    <SortButton field="number" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Location</p>
                    <SortButton field="location" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th className="text-nowrap">Service Lines</th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1 text-nowrap">Total Price</p>
                    <SortButton field="price" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1 text-nowrap">Expires On</p>
                    <SortButton field="expiresOn" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {filtered.length > 0 ? (
                filtered.map(quote => (
                  <tr key={quote.id}>
                    <td className={"d-flex"}>
                      <div className={"d-flex flex-row flex-fill align-items-center"}>
                        <div className={"flex-column"}>
                          # <Link to={`/quote/${quote.id}`}>
                            {quote.refNumber}
                          </Link>
                          <p className="text-muted m-0">{quote.description}</p>
                        </div>
                        <div className={"flex-fill"}/>
                        <Link to={`/quote/${quote.id}`}>
                          <img src={require('../../assets/pdf.png')} alt="external link" className="ms-1 pdfIcon" />
                        </Link>
                      </div>
                    </td>
                    <td>
                      {quote.location ? (
                        <Link to={`/location/${quote.location.id}`}>{quote.location.name}</Link>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td className="text-center">
                      {quote.serviceLines.length > 0 ? (
                        <>
                          {quote.serviceLines.map((serviceLine) => (
                            <div className="d-inline-block me-2" key={serviceLine.id}>
                              <ServiceLineIcon data={serviceLine} size={24} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td className="text-center">{quote.totalPrice ? quote.totalPrice : '-'}</td>
                    <td className="text-center">
                      {quote.expiresOn ? (
                        expireDate(quote.expiresOn)
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No quotes found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
