import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../../api/requests';
import { useAppDispatch } from '../../hooks/redux';
import { useForm, SubmitHandler} from 'react-hook-form';
import { AppError, LoginResponse } from '../../interfaces/interfaces';
import { PublicHeader } from '../../components/header/PublicHeader';
import './auth.css';

interface LoginInput {
  username: string;
  password: string;
}

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { register, handleSubmit } = useForm<LoginInput>();

  const onSuccess = (data: LoginResponse) => {
    dispatch({ type: 'auth/login', payload: data });
    navigate('/', { replace: true });
  }

  const onFailure = (_error: AppError) => {
    if (_error.errorCode === 'INVALID_CREDENTIALS') {
      setError('Incorrect email or password');
    } else {
      setError(_error.message);
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<LoginInput> = (input: LoginInput) => {
    if (loading) return;
    setLoading(true);
    loginRequest(input, onSuccess, onFailure);
  };

  return (
    <div className="auth-container">
      <form className="card auth-card" onSubmit={handleSubmit(onSubmit)}>
        <h3>Login</h3>
        <p>Sign in to your account. If you do not have an account or forgot your password, please contact an administrator.</p>
        <Form.Group className="form-group mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Enter your email"
            type="text"
            disabled={loading}
            spellCheck={false}
            maxLength={150}
            required
            {...register('username')}
          />
        </Form.Group>
        <Form.Group className="form-group mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder="Enter your password"
            type="password"
            disabled={loading}
            maxLength={150}
            required
            {...register('password')}
          />
        </Form.Group>
        {error && (
          <div className="alert alert-danger mt-3 p-2">
            <p className="mb-0">{error}</p>
          </div>
        )}
        <div className="d-grid mt-4">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              <>Login</>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
