import Container from 'react-bootstrap/Container';
import { MdErrorOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AppError } from '../interfaces/interfaces';

interface ErrorProps {
  error: AppError;
  type: string;
  link?: { title: string, ref: string };
}

export const Error = ({ error, type, link }: ErrorProps) => {
  const title = error.errorCode === 'NETWORK' ? 'Network Error'
    : error.errorCode === 'NOT_FOUND' ? `${type} Not Found` : 'Unexpected error';

  return (
    <Container fluid className="d-flex flex-column align-items-center" style={{ height: '100%', maxWidth: '600px' }}>
      <div style={{ flex: 1 }}></div>
      <h2 className="m-0">
        <MdErrorOutline className="text-danger me-2" />
        {title}
      </h2>
      {error.errorCode === 'NETWORK' ? (
        <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
          {'Check your internet connection and try again later. If the error persists, please '}
          <span><a href="/contact">contact support</a></span>.
        </p>
      ) : (error.errorCode === 'NOT_FOUND') ? (
        <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
          {`${type} not found or you do not have access to it. If you think this is an error, please `}
          <span><a href="/contact">contact support</a></span>.
        </p>
      ) : (
        <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
          {error.message}
          <br />
          {'If the error persists, please '} 
          <span><a href="/contact">contact support</a></span>.
        </p>
      )}
      {link && (
        <>
          <div className="d-flex flex-row align-items-center text-center text-muted mt-2 mb-2">
            <hr style={{ width: '15px', height: '1px', border: 'none', opacity: '0.75', backgroundColor: '#6c757d' }} />
            <p className="m-0 text-muted ps-2 pe-2">or</p>
            <hr style={{ width: '15px', height: '1px', border: 'none', opacity: '0.75', backgroundColor: '#6c757d' }} />
          </div>
          <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
            <Link to={link.ref}>{link.title}</Link>
          </p>
        </>
      )}
      <div style={{ flex: 3 }}></div>
    </Container>
  )
}