import React from 'react';
import {useIsMobile} from '../hooks/useIsMobile';

type Props = {
  children: [JSX.Element, JSX.Element];
}

// This component is used to conditionally render a component based on the screen size.
// If the screen is mobile, the first child is rendered, otherwise the second child is rendered.
const MobileConditional: React.FC<Props> = ({children}) => {

  const isMobile = useIsMobile();

  return (
    <div>
      {isMobile ? children[0] : children[1]}
    </div>
  );
};

export default MobileConditional;
