import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Constants from '../../constants/fieldConstants';
import { updateProfileRequest } from '../../api/requests';
import { User } from '../../interfaces/interfaces';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { EditButton } from './EditButton';

interface ProfileForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

type Status =
  | { type: 'INIT' }
  | { type: 'LOADING' }
  | { type: 'ERROR', message: string };

export const UpdateProfile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.profile);
  const [profileStatus, setProfileStatus] = useState<Status>({ type: 'INIT' });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const profileFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(Constants.nameMaxLength, 'First name too long'),
    lastName: Yup.string()
      .max(Constants.nameMaxLength, 'First name too long'),
    phoneNumber: Yup.string()
      .max(Constants.phoneNumberMaxLength, 'Phone number too long')
  });
  const { register, handleSubmit, formState, watch, reset } = useForm<ProfileForm>({
    resolver: yupResolver(profileFormSchema),
    mode: 'onTouched',
    defaultValues: user ? {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    } : {
      firstName: '',
      lastName: '',
      phoneNumber: ''
    }
  });

  const profileChanged = () => {
    if (!user) return false;
    const { firstName, lastName, phoneNumber } = watch();
    return user.firstName !== firstName || user.lastName !== lastName || user.phoneNumber !== phoneNumber;
  }

  const onSubmit: SubmitHandler<ProfileForm> = (data: ProfileForm) => {
    setProfileStatus({ type: 'LOADING' });
    updateProfileRequest((user: User) => {
      setProfileStatus({ type: 'INIT' });
      dispatch({ type: 'profile/setProfile', payload: user });
      setIsEditing(false);
      toast.success('Profile updated successfully');
    }, () => {
      setProfileStatus({ type: 'ERROR', message: 'Error updating profile. Please try again later.' });
    }, data);
  }

  const onEdit = () => {
    setIsEditing(true);
  }

  const onCancel = () => {
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber
    });
    setIsEditing(false);
  }

  if (!user) return null;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="pt-2">
        Profile
        {!isEditing && (
          <EditButton onClick={onEdit} />
        )}
      </h5>
      <Row xs={2} sm={2} md={2}>
        <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            placeholder="Enter your first name"
            type="text"
            maxLength={Constants.nameMaxLength}
            disabled={profileStatus.type === 'LOADING' || !isEditing}
            required
            {...register('firstName')}
          />
          {formState.errors.firstName && <p className="text-danger">{formState.errors.firstName.message}</p>}
        </Form.Group>
        <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            placeholder="Enter your last name"
            type="text"
            maxLength={Constants.nameMaxLength}
            disabled={profileStatus.type === 'LOADING' || !isEditing}
            required
            {...register('lastName')}
          />
          {formState.errors.lastName && <p className="text-danger">{formState.errors.lastName.message}</p>}
        </Form.Group>
      </Row>
      <Row xs={2} sm={2} md={2}>
        <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            disabled
            value={user?.email}
          />
        </Form.Group>
        <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
          <Form.Label>Phone</Form.Label>
          <Form.Control
            placeholder="Enter your phone number"
            type="tel"
            maxLength={Constants.phoneNumberMaxLength}
            disabled={profileStatus.type === 'LOADING' || !isEditing}
            {...register('phoneNumber')}
          />
          {formState.errors.phoneNumber && <p className="text-danger">{formState.errors.phoneNumber.message}</p>}
        </Form.Group>
      </Row>
      {(isEditing && profileStatus.type === 'ERROR') && (
        <div className="alert alert-danger mt-3 p-2">
          <p className="m-0">{profileStatus.message}</p>
        </div>
      )}
      {isEditing && (
        <div className="flex-grow-1 d-flex justify-content-end">
          <Button
            variant="danger"
            className="mt-3 ps-2 pe-2 me-2"
            disabled={profileStatus.type === 'LOADING'}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="mt-3 ps-2 pe-2"
            disabled={profileStatus.type === 'LOADING' || !profileChanged() || !formState.isValid}
          >
            Save Changes
            {profileStatus.type === 'LOADING' && (
              <span className="spinner-border spinner-border-sm ms-2" />
            )}
          </Button>
        </div>
      )}
    </Form>
  );
}