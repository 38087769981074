import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/parseDate';
import { SearchBar } from '../common/SearchBar';
import {ProjectJob, ProjectService} from '../../interfaces/interfaces';
import { ServiceLineIcon } from '../service/ServiceLineIcon';
import {useState} from 'react';
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

interface ProjectTableProps {
  projects: ProjectService[];
}

export const ProjectServices = ({ projects }: ProjectTableProps) => {
  const jobDate = (date: number) => {
    const { year, month, day } = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  const [filtered, setFiltered] = useState<ProjectService[] | undefined>(undefined);

  const handleSearch = (term: string) => {
    if (term === '') {
      setFiltered(undefined);
    } else {
      const filtered = projects.filter(project => {
        return project.id?.toString().toLowerCase().includes(term.toLowerCase()) ||
          project.description?.toLowerCase().includes(term.toLowerCase()) ||
          project.asset?.name.toLowerCase().includes(term.toLowerCase()) ||
          project.location?.name.toLowerCase().includes(term.toLowerCase());
      });
      setFiltered(filtered);
    }
  };

  const servicesToUse = filtered ? filtered : projects;

  return (
    <>
      <SearchBar searchKey={"projectServices"} placeholder="Search project" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={servicesToUse.map(service => ({
            id: service.id,
            header: (
              <div className={"d-flex flex-column"}>
                <Link to={`/asset/${service.asset?.id}`}>{service.asset?.name}</Link>
                <Link className={"figure-caption mt-2"} to={`/location/${service.location?.id}`}>{service.location?.name}</Link>
              </div>
            ),
            expanded: (
              <div>
                <div>Active Job Due: {service.activeJobDueDate ? jobDate(service.activeJobDueDate) : '-'}</div>
                <div>Currently Due: {service.currentlyDueDate ? jobDate(service.currentlyDueDate) : '-'}</div>
                <div className={"figure-caption mt-2"}>{service.description}</div>
              </div>
            ),
          }))} />
          <Table bordered hover>
          <thead>
            <tr>
              <th>Service ID</th>
              <th className="text-nowrap">Asset</th>
              <th>Description</th>
              <th className="text-nowrap">Active Job Due</th>
              <th>Currently Due</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody className="m-0 p-0">
            {servicesToUse.length > 0 ? (
              servicesToUse.map(service => (
                <tr key={service.id}>
                  <td style={{ minWidth: '150px' }}>
                    <p className="text-muted m-0">{service.id}</p>
                  </td>
                  <td className="text-center">
                    <Link to={`/asset/${service.asset?.id}`}>{service.asset?.name}</Link>
                  </td>
                  <td>
                    <p className="m-0">{service.description}</p>
                  </td>
                  <td className="text-center">{service.activeJobDueDate ? jobDate(service.activeJobDueDate) : '-'}</td>
                  <td className="text-center">{service.currentlyDueDate ? jobDate(service.currentlyDueDate) : '-'}</td>
                  <td>
                    <Link to={`/location/${service.location?.id}`}>{service.location?.name}</Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No projects found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
