import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ServiceLine } from '../../interfaces/interfaces';

interface ServiceLineProps {
  data: ServiceLine;
  size?: number;
} 

export const ServiceLineIcon = ({ data, size = 32 }: ServiceLineProps) => {
  const renderTooltip = (props: any) => (
    <Tooltip {...props}>
      {data.name}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      overlay={renderTooltip}
    >
      <img
        src={data.icon}
        alt={data.name}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </OverlayTrigger>
  )
}