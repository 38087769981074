import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { AppError } from '../interfaces/interfaces';
import { MdErrorOutline } from 'react-icons/md';

interface ErrorProps {
  error: AppError;
  type: string;
  link?: { title: string, ref: string };
}

export const SmallError = ({ error, type, link }: ErrorProps) => {
  const title = `Error while loading ${type.toLowerCase()}`
  
  const description = error.errorCode === 'NETWORK' ? 'Please check your internet connection and try again later.'
    : error.errorCode === 'NOT_FOUND' ? `${type} not found or you do not have access to it. If you think this is an error, please contact an administrator.` : error.message;

  return (
    <Container className="d-flex flex-column m-0 mt-3 align-items-start justify-content-start" style={{ height: '100%', maxWidth: '600px' }}>
      <div style={{ flex: 1 }}></div>
      <h4 className="m-0">
        <MdErrorOutline className="text-danger me-2" />
        {title}
      </h4>
      {error.errorCode === 'NETWORK' ? (
        <p className="mt-2" style={{ fontSize: '1.1rem' }}>
          {'Check your internet connection and try again later. If the error persists, please '}
          <span><a href="/contact">contact support</a></span>.
        </p>
      ) : (error.errorCode === 'NOT_FOUND') ? (
        <p className="mt-2" style={{ fontSize: '1.1rem' }}>
          {`${type} not found or you do not have access to it. If you think this is an error, please `}
          <span><a href="/contact">contact support</a></span>.
        </p>
      ) : (
        <p className="mt-2" style={{ fontSize: '1.1rem' }}>
          {error.message}
          <br />
          {'If the error persists, please '} 
          <span><a href="/contact">contact support</a></span>.
        </p>
      )}
      {link && (
        <>
          <div className="d-flex flex-row align-items-center text-center text-muted mt-2 mb-2">
            <hr style={{ width: '15px', height: '1px', border: 'none', opacity: '0.75', backgroundColor: '#6c757d' }} />
            <p className="m-0 text-muted ps-2 pe-2">or</p>
            <hr style={{ width: '15px', height: '1px', border: 'none', opacity: '0.75', backgroundColor: '#6c757d' }} />
          </div>
          <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
            <Link to={link.ref}>{link.title}</Link>
          </p>
        </>
      )}
      <div style={{ flex: 3 }}></div>
    </Container>
  )
}