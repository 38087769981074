import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const DetailSkeleton = () => {
  return (
    <>
      <Skeleton style={{ width: '50%', height: '50px' }} />
      <Container fluid className="d-flex flex-row gap-2 p-0 mt-4 mb-5">
        <Col>
          <Skeleton className="mt-1" count={4} style={{ height: '30px' }} /> 
        </Col>
        <Col>
          <Skeleton className="mt-1" count={4} style={{ height: '30px' }} /> 
        </Col>
        <Col>
          <Skeleton className="mt-1" count={4} style={{ height: '30px' }} /> 
        </Col>
      </Container>
      <Skeleton count={3} style={{ height: '20px', width: '15%' }} /> 
    </>
  );
}