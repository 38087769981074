import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

export interface Sort {
  field: string;
  direction: "asc" | "desc";
}

interface State {
  [key: string]: Sort;
}

interface Reducers {
  [key: string]: (state: State, action: any) => void;
  setSort: (state: State, action: PayloadAction<{key: string, sort: Sort}>) => void;
}

const sortSlice = createSlice({
  name: 'sort',
  initialState: {} as State,
  reducers: {
    setSort: (state, action) => {
      state[action.payload.key] = action.payload.sort;
    }
  } as Reducers,
});

export const { setSort } = sortSlice.actions;
export default sortSlice.reducer;

// selectors
export const selectSort = (key?: string) => (state: any) => key ? state.sort[key] : undefined;
