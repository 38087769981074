export function parseDate(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const monthName = date.toLocaleString('en-us', { month: 'short' });
  return { date, year, month, monthName, day, hours, minutes, seconds };
}

export function parseTime(timestamp: number) {
  const hour = Math.floor(timestamp / 3600);
  const minutes = Math.floor((timestamp - hour * 3600) / 60);
  const minuteDisplay =  (minutes < 10) ? `0${minutes}` : `${minutes}`;
  return hour <= 12 ? { hour, minute: minuteDisplay, type: 'AM' } : { hour: hour - 12, minute: minuteDisplay, type: 'PM' };
}

export const getDate = (date: number) => {
  const { year, month, day } = parseDate(date);
  return `${month}/${day}/${year}`;
}

export const getWindow = (start: number | null, end: number | null, time?: number | null) => {
  if (!start || !end) return '-';
  const { year: year1, month: month1, day: day1 } = parseDate(start);
  const { year: year2, month: month2, day: day2 } = parseDate(end);
  
  if (start === end) {
    if (time) {
      const { hour, minute, type } = parseTime(time);
      return `${month1}/${day1}/${year1} at ${hour}:${minute} ${type}`;
    }
    return `${month1}/${day1}/${year1}`;
  }
  return `${month1}/${day1}/${year1} - ${month2}/${day2}/${year2}`;
}
