import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './subsections.css'

interface SubsectionTabsProps {
  sections: {
    label: JSX.Element;
    content: JSX.Element;
  }[];
  maxContentHeight?: number;
}

export const SubsectionTabs = ({ sections, maxContentHeight = 50 }: SubsectionTabsProps) => {
  const [title, setTitle] = useState(<>{sections.length > 0 ? sections[0].label : 'Sections'}</>);

  return (
    <Tab.Container defaultActiveKey="1">
      <Row id="subsections-container" className="d-flex m-0 p-0">
        <Col id="subsections-tabs-tabs" className="d-inline-flex flex-grow-1 col-12 w-100 p-0 d-md-none mb-2" style={{ minWidth: '150px' }}>
          <Nav navbarScroll variant="tabs" className="flex-row p-0 flex-grow-1 flex-nowrap overflow-auto">
            {sections.map((section, index) => (
              <Nav.Item key={`navitem-${index}`} className="p-0 m-0 text-center text-nowrap">
                <Nav.Link eventKey={index + 1}>{section.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col id="subsections-tabs" md={1} className="d-inline-flex flex-grow-0 p-0" style={{ minWidth: '150px' }}>
          <Nav variant="pills" className="flex-column p-0">
            {sections.map((section, index) => (
              <Nav.Item key={`navitem-${index}`} className="p-0 m-0">
                <Nav.Link eventKey={index + 1}>{section.label}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col className="flex-grow-1 p-0">
          <Tab.Content id="subsections-content" className="flex-fill p-0" style={{ "--content-height": `${maxContentHeight}vh` } as React.CSSProperties} >
            {sections.map((section, index) => (
              <Tab.Pane key={`navcontent-${index}`} eventKey={index + 1}>{section.content}</Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}
