import Container from 'react-bootstrap/Container';
import { MdErrorOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Container fluid className="d-flex flex-column align-items-center" style={{ flex: 1, height: '100%', maxWidth: '600px' }}>
      <div style={{ flex: 1 }}></div>
      <h2 className="m-0">
        <MdErrorOutline className="text-danger me-2" />
        Page not found
      </h2>
      <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
        The page you are looking for does not exist.
      </p>
      <p className="text-center mt-2" style={{ fontSize: '1.1rem' }}>
        <Link to="/">Go to Homepage</Link>
      </p>
      <div style={{ flex: 3 }}></div>
    </Container>
  );
}
