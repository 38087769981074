import {useEffect, useMemo, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Location, Locations as LocationsData } from '../../interfaces/interfaces';
import { getLocationsRequest } from '../../api/requests';
import { useApiData } from '../../hooks/useApiData';
import { useSort } from '../../hooks/useSort';
import { SortButton } from '../../components/common/SortButton';
import { TableSkeleton } from '../../components/common/TableSkeleton';
import { FaBell, FaTv } from 'react-icons/fa';
import { Error } from '../../components/Error';
import { SearchBar } from '../../components/common/SearchBar';
import { SectionContainer } from '../../components/container/SectionContainer';
import { CardContainer } from '../../components/container/CardContainer';
import {useWindowWidth} from '../../hooks/useWindowWidth';
import SmallTable from '../../components/SmallTable';
import * as React from 'react';
import {useIsMobile} from '../../hooks/useIsMobile';
import MobileConditional from '../../components/MobileConditional';

export const Locations = () => {
  const navigate = useNavigate();
  const { status, onSuccess, onFailure } = useApiData<Location[]>();
  const [ searchTerm, setSearchTerm ] = useState('');
  const { sort, handleSort, compareFields } = useSort("locations");
  // const [ filteredLocations, setFilteredLocations ] = useState<Location[]>([]);

  const isMobile = useIsMobile();

  const onRequestSuccess = (data: LocationsData) => {
    // setFilteredLocations(data.locations);
    onSuccess(data.locations);
  };

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const onAlarmClick = (locationId: number) => {
    const alarm = status.type === 'SUCCESS' ? status.data?.find(location => location.id === locationId)?.link?.alerts : '';
    if (alarm) {
      navigate(`/location/${locationId}/alarm?link=${encodeURIComponent(alarm)}`);
    }
  }

  const onMonitorClick = (locationId: number) => {
    const base = status.type === 'SUCCESS' ? status.data?.find(location => location.id === locationId)?.link?.base : '';
    if (base) {
      navigate(`/location/${locationId}/monitor?link=${encodeURIComponent(base)}`);
    }
  }

  useEffect(() => {
    getLocationsRequest(onRequestSuccess, onFailure);
  }, []);

  const filteredLocations: Location[] = useMemo(() => {
    if (status.type !== 'SUCCESS') return [];
    let filter = status.data;
    if (searchTerm) {
      const searchString = searchTerm.toLowerCase();
      filter = status.data.filter(location => {
        return (location.name.toLowerCase().includes(searchString)
          || location.company?.name.toLowerCase().includes(searchString)
          || location.address.street.toLowerCase().includes(searchString)
          || location.address.city.toLowerCase().includes(searchString)
          || location.address.state.toLowerCase().includes(searchString)
          || location.address.postalCode.toLowerCase().includes(searchString)
        );
      });
    }
    if (sort) {
      filter = filter.sort((a, b) => {
        switch (sort.field) {
          case 'name':
            return compareFields(a.name, b.name);
          case 'company.name':
            return compareFields(a.company?.name || '', b.company?.name || '');
          case 'address.street':
            return compareFields(a.address.street, b.address.street);
          case 'address.city':
            return compareFields(a.address.city, b.address.city);
          case 'address.state':
            return compareFields(a.address.state, b.address.state);
          case 'address.postalCode':
            return compareFields(a.address.postalCode, b.address.postalCode);
          default:
            return 0;
        }
      });
    }
    return filter;
  }, [searchTerm, sort, status]);

  // useEffect(() => {
  // }, [searchTerm, sort, status]);

  if (status.type === 'LOADING') {
    return (
      <SectionContainer>
        <TableSkeleton />
      </SectionContainer>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={status.error} type="Locations" />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <CardContainer className={"pt-3"}>
        <SearchBar searchKey={"locations"} placeholder="Search location" handleSearch={handleSearch} />
        <Container fluid className="p-0" style={{ overflowX: 'auto' }}>
          <MobileConditional>
            <SmallTable data={filteredLocations.map(l => ({
              id: l.id,
              header: (
                <div className={"d-flex flex-grow-1 flex-row justify-content-start"}>
                  <div className={"flex-column d-flex"}>
                    <div className={"h6"}><Link to={`/location/${l.id}`}>{l.name}</Link></div>
                    <div className={"figure-caption"}>{l.company?.name || ''}</div>
                  </div>
                  <div className={""}>
                    <button disabled={!l.link?.alerts} onClick={() => onAlarmClick(l.id)} style={{ border: 'none', background: 'transparent' }}>
                      <FaBell />
                    </button>
                    <button disabled={!l.link?.base} onClick={() => onMonitorClick(l.id)} style={{ border: 'none', background: 'transparent' }}>
                      <FaTv />
                    </button>
                  </div>
                </div>
              ),
              expanded: (
                <div className={"d-flex flex-row justify-content-between"}>
                  <div>
                    <p className="mb-0">{l.address.street}</p>
                    <p className="mb-0">{l.address.city}, {l.address.state} {l.address.postalCode}</p>
                  </div>
                </div>
              )
            }))} />
            <Table bordered hover>
              <thead>
                <tr>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1">Name</p>
                      <SortButton field="name" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1">Company</p>
                      <SortButton field="company.name" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1">Address</p>
                      <SortButton field="address.street" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1">City</p>
                      <SortButton field="address.city" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1">State</p>
                      <SortButton field="address.state" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>
                    <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                      <p className="mb-0 me-1 text-nowrap">Zip Code</p>
                      <SortButton field="address.postalCode" sort={sort} handleSort={handleSort} />
                    </Container>
                  </th>
                  <th>Alarms</th>
                  <th>Monitoring</th>
                </tr>
              </thead>
              <tbody>
                {filteredLocations.length > 0 ? (
                  filteredLocations.map(location => (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                      </td>
                      <td>{location.company?.name}</td>
                      <td>{location.address.street}</td>
                      <td>{location.address.city}</td>
                      <td>{location.address.state}</td>
                      <td>{location.address.postalCode}</td>
                      <td className="text-center" style={{ verticalAlign: 'middle' }}>
                        <button disabled={!location.link?.alerts} onClick={() => onAlarmClick(location.id)} style={{ border: 'none', background: 'transparent' }}>
                          <FaBell />
                        </button>
                      </td>
                      <td className="text-center" style={{ verticalAlign: 'middle' }}>
                        <button disabled={!location.link?.base} onClick={() => onMonitorClick(location.id)} style={{ border: 'none', background: 'transparent' }}>
                          <FaTv />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No locations found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </MobileConditional>
        </Container>
      </CardContainer>
    </SectionContainer>
  );
}
