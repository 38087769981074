import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';
import { Login } from '../screens/auth/Login';
import { ConfirmAccount } from '../screens/auth/ConfirmAccount';
import { Locations, ProjectRequest, Projects, Quotes, RealTimeMonitoring } from '../screens/tabs';
import { Header } from '../components/header/Header';
import { AccountSettings, Asset, Job, Quote, NotFound, AssetMonitor, Location, LocationMonitor, ContactSupport } from '../screens';
import { PublicHeader } from '../components/header/PublicHeader';
import {Terms, TermsState} from '../screens/terms/Terms';
import {useMyProfile} from '../hooks/useMyProfile';
import {useTerms} from '../hooks/useTerms';
import {LogoutHeader} from '../components/header/LogoutHeader';

const AppRouter = () => {
  const { session } = useAppSelector(state => state.auth);
  const user = useMyProfile();
  const terms = useTerms();

  const termsState = (() => {
    if (session && terms && user) {
      if (!user.acceptedTermsDate) {
        return TermsState.NOT_ACCEPTED;
      } else {
        const acceptedTermsDate = Date.parse(user.acceptedTermsDate);
        const termsDate = Date.parse(terms.date);
        if (acceptedTermsDate < termsDate) {
          return TermsState.EXPIRED;
        }
        return TermsState.ACCEPTED;
      }
    }
    return TermsState.NO_DATA;
  })();

  const needsToAcceptTerms = () => {
    return termsState === TermsState.NOT_ACCEPTED || termsState === TermsState.EXPIRED;
  }

  if (needsToAcceptTerms()) {
    return (
      <BrowserRouter>
        <LogoutHeader />
        <Routes>
          <Route path={"/terms"} element={<Terms status={termsState} />} />
          <Route path="*" element={<Navigate to="/terms" />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      { session === null ? (
        <>
          <PublicHeader />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/confirmation/:email/:code" element={<ConfirmAccount />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </>
      ) : (
        <>
          <Header/>
          <Routes>
            <Route path="/locations" element={<Locations />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/quotes" element={<Quotes />} />
            <Route path="/monitoring" element={<RealTimeMonitoring />} />
            <Route path="/project-request" element={<ProjectRequest />} />
            <Route path="/location/:id" element={<Location />} />
            <Route path="/location/:id/alarm" element={<LocationMonitor type="alarm" />} />
            <Route path="/location/:id/monitor" element={<LocationMonitor type="monitor" />} />
            <Route path="/quote/:id" element={<Quote />} />
            <Route path="/project/:id" element={<Job />} />
            <Route path="/asset/:id" element={<Asset />} />
            <Route path="/asset/:id/alarm" element={<AssetMonitor type="alarm" />} />
            <Route path="/asset/:id/monitor" element={<AssetMonitor type="monitor" />} />
            <Route path="/asset/:id/trend" element={<AssetMonitor type="trend" />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/contact" element={<ContactSupport />} />

            <Route path="/confirmation/:email/:code" element={<ConfirmAccount />} />
            <Route path="/login" element={<Navigate to="/locations" />} />
            <Route path="/terms" element={<Navigate to="/locations" />} />
            <Route path="/" element={<Navigate to="/locations" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      ) }
    </BrowserRouter>
  )
}

export default AppRouter;
