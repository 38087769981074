import Card from 'react-bootstrap/Card';
import { BackButton } from './common/BackButton';
import { SectionContainer } from './container/SectionContainer';

interface MonitorProps {
  detail?: { type: 'location' | 'asset', id: string, detail: 'alarm' | 'monitor' | 'trend' };
}

export const Monitor = ({ detail }: MonitorProps) => {
  const pageNumber = () => {
    if (detail?.type === 'location') {
      if (detail.detail === 'alarm') {
        return '1691893908';
      }
      return '0913202246';
    }
    if (detail?.type === 'asset') {
      if (detail.detail === 'alarm') {
        return '1691893908';
      } else if (detail.detail === 'monitor') {
        return '0490701850';
      } else {
        return '1987233216';
      }
    }
    return '1761967594';
  }

  return (
    <SectionContainer style={{ maxWidth: '1800px', flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Card style={{ height: '100%', width: '100%', flex: 1 }} className="p-1">
        {detail && (
          <Card.Header className="d-flex flex-row justify-content-center">
            <BackButton className="p-0 me-3" />
            <h4 className="m-0 flex-fill">{detail.type === 'location' ? 'Site' : 'Asset'} {detail.id} - {detail.detail}</h4>
          </Card.Header>
        )}
        <iframe
          title="monitoring"
          style={{ height: '100%', width: '100%', flex: 1 }}
          src={`https://20.75.9.19:8443/lweb900/?project=TBI%2FCustomer%20Level&lweb900prj=Modular%20Integrated&page=${pageNumber()}&username=Federico&password=pjFGA9#lvisPage`}
        />
      </Card>
    </SectionContainer>
  );
}

type MonitorLinkProps = {
  link: string | null;
}
export const MonitorLink = ({link}: MonitorLinkProps) => {
  return (
    <SectionContainer style={{ maxWidth: '1800px', flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Card style={{ height: '100%', width: '100%', flex: 1 }} className="p-1">
        <div style={{padding: '1rem'}} className="d-flex flex-row justify-content-flex-star align-items-center">
          <BackButton className="p-0 me-3" />
          Go back
        </div>
        {link && <iframe
          title="monitoring"
          style={{ height: '100%', width: '100%', flex: 1 }}
          src={link}
        />}
      </Card>
    </SectionContainer>
  )
}
