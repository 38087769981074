import { useState } from 'react';
import { AppError } from '../interfaces/interfaces';

export type Status<T> =
  | { type: 'LOADING' }
  | { type: 'SUCCESS'; data: T }
  | { type: 'ERROR'; error: AppError };

export const useApiData = <T>(initialStatus: Status<T> = {type: 'LOADING'}) => {
  const [status, setStatus] = useState<Status<T>>(initialStatus);

  const onSuccess = (data: T) => {
    setStatus({ type: 'SUCCESS', data });
  }

  const onFailure = (error: AppError) => {
    setStatus({ type: 'ERROR', error });
  }

  const setLoading = () => {
    setStatus({ type: 'LOADING' });
  }

  const setData = (data: T) => {
    setStatus({ type: 'SUCCESS', data });
  }

  return { status, setLoading, onSuccess, onFailure, setData };
}
