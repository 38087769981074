import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import logo from '../../assets/mis-logo.jpg'
import { logoutRequest } from '../../api/requests';
import { useAppSelector } from '../../hooks/redux';
import './header.css';
import { Offcanvas } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import {useIsMobile} from '../../hooks/useIsMobile';
import {FaUserCircle} from 'react-icons/fa';

const pathToName = (path: string) => {
  switch (path) {
    case '/locations':
      return 'Locations';
    case '/projects':
      return 'Projects';
    case '/quotes':
      return 'Quotes';
  }
}

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const { user } = useAppSelector((state) => state.profile);

  const onRequestClick = () => {
    navigate('/project-request');
  }

  const onLogout = () => {
    logoutRequest();
  }

  const onHomeClick = () => {
    navigate('/');
  }

  const onSettings = () => {
    navigate('/account-settings');
  }

  const onTerms = () => {
    window.open('https://www.modularintegrated.com/copy-of-mis-privacy-policy', "self", "noreferrer");
  }

  const onSupport = () => {
    navigate('/contact');
  }

  return (
    <div className="header-main-container border-bottom">
      <Navbar className="p-2" expand="md">
        <Container className={"flex-md-column p-0 align-content-md-stretch"} fluid>
          <Navbar.Brand className="p-0 p-md-2 col-md-12 me-0 flex-grow-1 d-flex align-items-center justify-content-between">
            <div className={"center-absolute-horizontal"}>
              <button onClick={onHomeClick} style={{ border: 'none', background: 'transparent' }}>
                <Image src={logo} alt="Logo" style={{ height: '38px', objectFit: 'contain' }} />
              </button>
            </div>
            <div className={"d-flex ms-1 flex-row justify-content-start"}>
              {isMobile &&
                <Navbar.Toggle aria-controls={"drawer"} className="navtabs-toggler p-1" />
              }
            </div>
            <div className="header-dropdown me-1 me-md-4 ms-0 flex-grow-0 ">
              <NavDropdown
                title={isMobile ? (
                  <FaUserCircle color={"grey"} size={24}>
                  </FaUserCircle>
                ) : (
                  <Navbar.Text>
                    {user? `${user.firstName || user.lastName}` : 'Modular Int.'}
                  </Navbar.Text>
                )}
                align="end"
                style={{ zIndex: '999' }}
                className="header-dropdown-menu"
              >
                {user && (
                  <NavDropdown.Item
                    onClick={onSettings}
                    className="header-menu-button"
                  >
                    <p className="m-0">Account Settings</p>
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item
                  onClick={onSupport}
                  className="header-menu-button"
                >
                  <p className="m-0">Contact Support</p>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={onTerms}
                  className="header-menu-button"
                >
                  <p className="m-0">Terms of Service</p>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={onLogout}
                  className="header-logout-button text-danger"
                >
                  <MdLogout size={19} className="me-1" />
                  <p className="m-0">Sign Out</p>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Navbar.Brand>
          <Navbar.Offcanvas className={"mt-md-2 align-self-stretch"}>
            <Offcanvas.Header closeButton />
            <Offcanvas.Body className="p-0">
              <Nav fill className="flex-grow-1">
                <Nav.Item className="m-0 drawer-item">
                  <Nav.Link className="navtabs-nav-link" as={Link} eventKey="2" to="/locations" active={location.pathname === '/locations'}>Locations</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-0 drawer-item">
                  <Nav.Link className="navtabs-nav-link" as={Link} eventKey="3" to="/projects" active={location.pathname === '/projects'}>Projects</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-0 drawer-item">
                  <Nav.Link className="navtabs-nav-link" as={Link} eventKey="4" to="/quotes" active={location.pathname === '/quotes'}>Quotes & Deficiencies</Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-0 drawer-item mt-md-0 mt-2">
                  <Button className="pt-1 pb-1 mb-1 navtabs-nav-link" onClick={onRequestClick}>Request a Project</Button>
                </Nav.Item>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
