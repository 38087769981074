import {useEffect} from 'react';
import {Status, useApiData} from './useApiData';
import {getCategories} from '../api/requests';
import {AttachmentCategoriesSuccess, Category, DocumentEntityTypes} from '../interfaces/interfaces';

const defaultCategories = [
  {
    id: '0',
    name: 'General'
  }
];

export type DocumentCategories = {
  default: Category[],
  custom: Category[]
}

export const useDocumentCategories = (entityId: string, entityType: DocumentEntityTypes) => {
  const {status, onSuccess, onFailure, setLoading} = useApiData<AttachmentCategoriesSuccess>();

  useEffect(() => {
    setLoading();
    getCategories(onSuccess, onFailure, entityId, entityType)
  }, []);

  const refetch = () => {
    getCategories(onSuccess, onFailure, entityId, entityType)
  }

  return {
    status: {
      ...status,
      data: status.type === 'SUCCESS' ? {
        default: [],
        custom: [...defaultCategories, ...status.data.categories],
      } : undefined,
    } as Status<DocumentCategories>,
    refetch
  };
};
