import Modal from 'react-bootstrap/Modal';
import {Form, InputGroup, Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useForm} from 'react-hook-form';
import {createAttachment, deleteAttachment} from '../api/requests';
import {DocumentEntityTypes} from '../interfaces/interfaces';
import {useState} from 'react';
import {ImBin2} from 'react-icons/im';

export const categories = [
  {id: 1, name: 'Job Paperwork'},
  {id: 2, name: 'Job Vendor Bill'},
  {id: 3, name: 'Job Quality Control Picture'},
  {id: 5, name: 'Deficiency Repair Proposal'},
  {id: 7, name: 'Generic Attachment (attachments that do not fall into a more specific category)'},
  {id: 8, name: 'Avatar Image'},
  {id: 9, name: 'Import'},
  {id: 10, name: 'Blank Paperwork'},
  {id: 11, name: 'Work Acknowledgement'},
  {id: 12, name: 'Logo'},
  {id: 14, name: 'Job Invoice'},
];

type FormData = {
  description: string;
  file: FileList | null;
  category: string;
}

const defaultValues: FormData = {
  description: '',
  file: null,
  category: '',
}

type Props = {
  onDeleted?: () => void;
  documentId: number;
  entityId: number;
  entityType: DocumentEntityTypes;
}

export function DeleteDocument(props: Props) {
  const [deleting, setDeleting] = useState<number>();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = (id: number) => () => {
    setDeleting(id);
  }

  const cancelDelete = () => {
    setDeleting(undefined);
  }

  const performDelete = () => {
    if (deleting) {
      setLoadingDelete(true);
      deleteAttachment(
        data => {
          setLoadingDelete(false);
          setDeleting(undefined);
          props.onDeleted?.();
        },
        error => {
          setLoadingDelete(false);
          setDeleting(undefined);
        },
        deleting.toString(),
        props.entityId.toString(),
        props.entityType,
      )
    }
  };

  return (
    <>
      <button onClick={handleDelete(props.documentId)} className="btn btn-danger"><ImBin2 /></button>
      <Modal
        show={deleting !== undefined}
        onHide={cancelDelete}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this document?
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button disabled={loadingDelete} variant="primary" onClick={performDelete}>
            {loadingDelete && <Spinner animation="border" size="sm" className={"me-2"} />}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
