import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {MdDelete, MdEdit} from 'react-icons/md';
import {Category, DocumentEntityTypes} from '../interfaces/interfaces';
import {Form, Spinner} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import {createCategory, deleteCategory, editCategory} from '../api/requests';
import {useApiData} from '../hooks/useApiData';
import {stat} from 'fs';
import {RiFolderSettingsLine} from 'react-icons/ri';
import MobileConditional from './MobileConditional';
import {FiSettings} from 'react-icons/fi';

type Props = {
  categories: Category[];
  onCategoriesChanged: () => void;
  entityId: number;
  entityType: DocumentEntityTypes;
}

type FormData = {
  newCategory: string;
}

type EditFormData = {
  name: string;
}
const ManageCategories = ({categories, entityId, entityType, onCategoriesChanged}: Props) => {
  const [show, setShow] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category>();
  const [categoryToEdit, setCategoryToEdit] = useState<Category>();
  const {status, onSuccess, onFailure, setLoading} = useApiData({type: 'SUCCESS', data: {}})
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const newCat = useForm<FormData>()
  const editCat = useForm<EditFormData>()

  const showDeleteConfirmation = (category: Category) => () => {
    setCategoryToDelete(category);
  }

  const startEdit = (category: Category) => () => {
    setCategoryToEdit(category);
  }

  const handleCreate = async (data: FormData) => {
    setLoading();
    await createCategory(
      onSuccess,
      onFailure,
      entityId.toString(),
      entityType,
      data.newCategory,
    )
    newCat.reset({
      newCategory: '',
    });
    onCategoriesChanged();
  };

  const handleDelete = async () => {
    if (!categoryToDelete) return;
    setLoadingDelete(true);
    await deleteCategory(() => {}, () => {}, entityId.toString(), entityType, categoryToDelete.id).finally(() => {
      setLoadingDelete(false);
    });
    setCategoryToDelete(undefined);
    onCategoriesChanged();
  }

  const handleEdit = async (data: EditFormData) => {
    if (!categoryToEdit) return;
    setLoadingEdit(true);
    await editCategory(
      () => {},
      () => {},
      entityId.toString(),
      entityType,
      categoryToEdit.id,
      data,
    ).finally(() => {
      setLoadingEdit(false);
    })
    editCat.reset();
    setCategoryToEdit(undefined);
    onCategoriesChanged();
  }

  return (
    <div className={"d-flex flex-row flex-grow-1"}>
      <button onClick={() => setShow(true)} className="btn btn-light align-self-end text-nowrap">
        <MobileConditional>
          <FiSettings />
          <>Manage categories</>
        </MobileConditional>
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <div className={"h4"}>Manage categories</div>
        </Modal.Header>
        <Modal.Body style={{maxHeight: "100%", overflowY: "scroll"}}>
          {categories.map((cat, i) => (
            <>
              <div className={"d-flex flex-row align-items-center justify-content-between"}>

                {categoryToEdit?.id === cat.id ? (
                  <>
                    <Form.Control
                      {...editCat.register("name", {required: "Please enter a category name", validate: (value) => value.trim().length > 0,})}
                      className={"me-3"}
                      defaultValue={cat.name}
                    />
                    <div className={"d-flex flex-row align-items-center"}>
                      <Button onClick={editCat.handleSubmit(handleEdit)} className={"me-3 text-nowrap"}>
                        {loadingEdit && <Spinner animation="border" size="sm" className={"me-2"} />}
                        Save
                      </Button>
                      <Button className={"btn-danger"} onClick={() => setCategoryToEdit(undefined)}>
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>{cat.name}</div>
                    <div className={"d-flex flex-row align-items-center"}>
                      {cat.canDelete && <MdEdit onClick={startEdit(cat)} className={'text-info me-2'} />}
                      {cat.canDelete && <MdDelete onClick={showDeleteConfirmation(cat)} className={'text-danger'} />}
                    </div>
                  </>
                )}

              </div>
              {i < categories.length - 1 && <hr />}
            </>
          ))}
          {newCat.formState.errors.newCategory && <div className={"text-danger"}>{newCat.formState.errors.newCategory.message}</div>}
          <Modal
            style={{backgroundColor: 'rgb(0,0,0,0.5)'}}
            show={categoryToDelete !== undefined}
            onHide={() => setCategoryToDelete(undefined)}
          >
            <Modal.Header>
              <div className={"h4"}>Delete category</div>
            </Modal.Header>
            <Modal.Body>
              <div className={"d-flex"}>Are you sure you want to delete the category <div className={"fw-bold ms-1"}>{categoryToDelete?.name}</div>?</div>
              <div>This will move all the documents in this category to General.</div>
              <div>This action cannot be undone.</div>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={() => setCategoryToDelete(undefined)} className="btn btn-primary">Cancel</button>
              <button onClick={handleDelete} className="btn btn-danger">
                {loadingDelete && <Spinner animation="border" size="sm" className={"me-2"} />}
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: "center", flexDirection: "column"}}>
          <div className={"d-flex flex-column align-self-stretch"}>
            <div className={"d-flex flex-row text-nowrap"}>
              <Form.Control
                className={"me-3"}
                {...newCat.register("newCategory", {required: "Please enter a category name", validate: (value) => value.trim().length > 0,})}
                placeholder={'New category'}
              />
              <Button onClick={newCat.handleSubmit(handleCreate)} className={""}>
                {status.type === 'LOADING' && <Spinner animation="border" size="sm" className={"me-2"} />}
                Add category
              </Button>
            </div>
            <button onClick={() => setShow(false)} className="btn btn-primary align-self-end mt-3">Close</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageCategories;
