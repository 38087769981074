import * as React from 'react';
import {Accordion} from 'react-bootstrap';

export type Data = {
  id: number,
  header: React.ReactNode,
  expanded?: React.ReactNode;
}

type Props = {
  data: Data[]
}

const SmallTable = (props: Props) => {
  return (
    <div>
        <Accordion>
          {props.data.map((item) => (
            <Accordion.Item eventKey={item.id.toString()}>
              <Accordion.Header className={"d-flex flex-row"}>
                {item.header}
              </Accordion.Header>
              {!!item.expanded &&
                <Accordion.Body>
                  {item.expanded}
                </Accordion.Body>
              }
            </Accordion.Item>
          ))}
        </Accordion>
    </div>
  );
};

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default SmallTable;
