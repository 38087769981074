import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/parseDate';
import { SearchBar } from '../common/SearchBar';
import {ProjectJob, ProjectService} from '../../interfaces/interfaces';
import {useState} from 'react';
import {useIsMobile} from '../../hooks/useIsMobile';
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

interface ProjectTableProps {
  projects: ProjectJob[];
}

export const ProjectJobs = ({ projects }: ProjectTableProps) => {
  const isMobile = useIsMobile();
  const jobDate = (date: number) => {
    const { year, month, day } = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  const [filtered, setFiltered] = useState<ProjectJob[] | undefined>(undefined);

  const handleSearch = (term: string) => {
    if (term === '') {
      setFiltered(undefined);
    } else {
      const filtered = projects.filter(project => {
        console.log("comparing term with ID: ", term, project.id?.toString().toLowerCase(), project.id?.toString().toLowerCase().includes(term.toLowerCase()))
        return project.number?.toString().toLowerCase().includes(term.toLowerCase()) ||
          project.description?.toLowerCase().includes(term.toLowerCase()) ||
          project.asset?.name.toLowerCase().includes(term.toLowerCase()) ||
          project.location?.name.toLowerCase().includes(term.toLowerCase())
      });
      setFiltered(filtered);
    }
  };

  const jobsToUse = filtered ? filtered : projects;

  return (
    <>
      <SearchBar searchKey={"projectJobs"} placeholder="Search project" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={jobsToUse.map(job => ({
            id: job.id,
            header: (
              <div className={"d-flex flex-column"}>
                {job.asset ? (
                  <Link to={`/asset/${job.asset.id}`}>{job.asset.name}</Link>
                ) : (
                  <p className="m-0">-</p>
                )}
                <Link className={"figure-caption mt-2"} to={`/location/${job.location.id}`}>{job.location.name}</Link>
              </div>
            ),
            expanded: (
              <div className={"d-flex flex-column"}>
                <div>Due Date: {job.dueBy ? jobDate(job.dueBy) : '-'}</div>
                <div>Technician: {job.currentAppointment?.techs[0] ? `${job.currentAppointment?.techs[0]?.firstName} ${job.currentAppointment?.techs[0]?.lastName}` : '-'}</div>
                <div className="mt-2 figure-caption">{job.description}</div>
              </div>
            ),
          }))} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Job ID</th>
                <th>Asset</th>
                <th>Description</th>
                <th className="text-nowrap">Due Date</th>
                <th>Location</th>
                <th>Technician</th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {jobsToUse.length > 0 ? (
                jobsToUse.map(job => (
                  <tr key={job.id}>
                    <td style={{ minWidth: '150px' }}>
                      <Link to={`/project/${job.id}`}>{job.number}</Link>
                    </td>
                    <td style={{ minWidth: '150px' }}>
                      {job.asset ? (
                        <Link to={`/asset/${job.asset.id}`}>{job.asset.name}</Link>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td>
                      <p className="text-muted m-0">{job.description}</p>
                    </td>
                    <td className="text-center">{job.dueBy ? jobDate(job.dueBy) : '-'}</td>
                    <td>
                      <Link to={`/location/${job.location.id}`}>{job.location.name}</Link>
                    </td>
                    <td>
                      {/* there should always be at most one tech */}
                      {job.currentAppointment?.techs[0] ? `${job.currentAppointment?.techs[0]?.firstName} ${job.currentAppointment?.techs[0]?.lastName}` : '-'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No projects found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
