import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { SearchBar } from '../common/SearchBar';
import { Deficiency } from '../../interfaces/interfaces';
import { useApiData } from '../../hooks/useApiData';
import { getDeficienciesRequest } from '../../api/requests';
import { TableSkeleton } from '../common/TableSkeleton';
import { Error } from '../Error';
import { ServiceLineIcon } from '../service/ServiceLineIcon';
import { parseDate } from '../../utils/parseDate';
import { useSort } from '../../hooks/useSort';
import { SortButton } from '../common/SortButton';
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

export const DeficienciesTable = () => {
  const { status, onSuccess, onFailure } = useApiData<Deficiency[]>();
  const [ filtered, setFiltered ] = useState<Deficiency[]>([]);
  const [ searchTerm, setSearchTerm ] = useState<string>('');
  const { sort, handleSort, compareFields } = useSort("deficiencies");

  const reportedDate = (date: number) => {
    const { year, month, day } = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  }

  const onRequestSuccess = (data: { deficiencies: Deficiency[] }) => {
    setFiltered(data.deficiencies);
    onSuccess(data.deficiencies);
  }

  useEffect(() => {
    if (status.type !== 'SUCCESS') return;
    let filter = status.data;
    if (searchTerm) {
      const searchString = searchTerm.toLowerCase();
      filter = status.data.filter(def => {
        return (
          def.asset?.name.toLowerCase().includes(searchString)
          || def.location?.name.toLowerCase().includes(searchString)
          || def.serviceLine?.name.toLowerCase().includes(searchString)
          || def.severity.toLowerCase().includes(searchString)
          || def.description.toLowerCase().includes(searchString)
          || def.status.toLowerCase().includes(searchString)
        );
      });
    }
    if (sort) {
      filter = filter.sort((a, b) => {
        switch (sort.field) {
          case 'asset':
            return compareFields(a.asset ? a.asset.name : null, b.asset ? b.asset.name : null);
          case 'location':
            return compareFields(a.location ? a.location.name : null, b.location ? b.location.name : null);
          case 'serviceLine':
            return compareFields(a.serviceLine ? a.serviceLine.name : null, b.serviceLine ? b.serviceLine.name : null);
          case 'severity':
            return compareFields(a.severity, b.severity);
          case 'description':
            return compareFields(a.description, b.description);
          case 'reportedOn':
            return compareFields(a.reportedOn, b.reportedOn);
          case 'status':
            return compareFields(a.status, b.status);
          default:
            return 0;
        }
      });
    }
    setFiltered(filter);
  }, [searchTerm, sort, status]);

  useEffect(() => {
    getDeficienciesRequest(onRequestSuccess, onFailure);
  }, []);

  if (status.type === 'LOADING') {
    return (
      <Container fluid className="m-0 p-0 flex-grow-1">
        <TableSkeleton n={4} />
      </Container>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <Container fluid className="m-0 p-0 flex-grow-1">
        <Error error={status.error} type="Deficiencies" />
      </Container>
    );
  }

  return (
    <>
      <SearchBar searchKey={"deficiencies"} placeholder="Search deficiency" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={filtered.map(def => {
            return {
              id: def.id,
              header: (
                <div className={"d-flex flex-row"}>
                  <div>
                    {def.serviceLine ? (
                      <ServiceLineIcon data={def.serviceLine} size={24} />
                    ) : (
                      <p className="m-0">-</p>
                    )}
                  </div>
                  <div className={"d-flex flex-column mx-3"}>
                    {def.asset ? (
                      <Link to={`/asset/${def.asset.id}`}>{def.asset.name}</Link>
                    ) : (
                      <p className="m-0">-</p>
                    )}
                    {def.location ? (
                      <Link className={"mt-2 figure-caption"} to={`/location/${def.location.id}`}>{def.location.name}</Link>
                    ) : (
                      <p className="m-0">-</p>
                    )}
                  </div>
                </div>
              ),
              expanded: (
                <div className={"d-flex flex-column"}>
                  <div>Severity: {def.severity}</div>
                  <div className="">
                    Reported On: {def.reportedOn ? (
                      reportedDate(def.reportedOn)
                    ) : (
                      <p className="m-0">-</p>
                    )}
                  </div>
                  <div>Status: {def.status}</div>
                  <div className="text-muted mt-2">{def.description}</div>
                </div>
              )
            }
          })} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Asset</p>
                    <SortButton field="asset" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Location</p>
                    <SortButton field="location" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1 text-nowrap">Service Line</p>
                    <SortButton field="serviceLine" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Severity</p>
                    <SortButton field="severity" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Description</p>
                    <SortButton field="description" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1 text-nowrap">First Reported On</p>
                    <SortButton field="reportedOn" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
                <th>
                  <Container fluid className="p-0 m-0 d-flex flex-row align-items-center">
                    <p className="mb-0 me-1">Status</p>
                    <SortButton field="status" sort={sort} handleSort={handleSort} />
                  </Container>
                </th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {filtered.length > 0 ? (
                filtered.map(def => (
                  <tr key={def.id}>
                    <td style={{ minWidth: '150px' }}>
                      {def.asset ? (
                        <Link to={`/asset/${def.asset.id}`}>{def.asset.name}</Link>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td>
                      {def.location ? (
                        <Link to={`/location/${def.location.id}`}>{def.location.name}</Link>
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td className="text-center">
                      {def.serviceLine ? (
                        <ServiceLineIcon data={def.serviceLine} size={24} />
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td>{def.severity}</td>
                    <td className="text-muted">{def.description}</td>
                    <td className="text-center">
                      {def.reportedOn ? (
                        reportedDate(def.reportedOn)
                      ) : (
                        <p className="m-0">-</p>
                      )}
                    </td>
                    <td>{def.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No deficiencies found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
