import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { parseDate } from '../../utils/parseDate';
import { SearchBar } from '../common/SearchBar';
import { AppointmentStatus, Job, JobStatus } from '../../interfaces/interfaces';
import MobileConditional from '../MobileConditional';
import SmallTable from '../SmallTable';

interface JobTableProps {
  jobs: Job[];
  handleSearch: (search: string) => void;
  searchKey?: string;
}

export const JobTable = ({ jobs, handleSearch, searchKey }: JobTableProps) => {
  const jobDate = (date: number) => {
    const { year, month, day, hours, minutes } = parseDate(date);
    const hours12 = hours > 12 ? hours - 12 : hours;
    const minutes12 = minutes < 10 ? `0${minutes}` : minutes;
    const ampm = hours > 11 ? 'pm' : 'am';
    return `${month}/${day}/${year} at ${hours12}:${minutes12}${ampm}`;
  }

  return (
    <>
      <SearchBar searchKey={searchKey ? `jobs.${searchKey}` : undefined} placeholder="Search project" handleSearch={handleSearch} />
      <Container fluid style={{ overflowX: 'auto' }} className="p-0">
        <MobileConditional>
          <SmallTable data={jobs.map(job => {
            return {
              id: job.id,
              header: (
                <div>
                  <Link to={`/project/${job.id}`}>#{job.number}</Link>
                  <div>Scheduled For: {job.scheduledDate ? jobDate(job.scheduledDate) : '-'}</div>
                  <div className="text-capitalize">Type: {job.type.replaceAll('_', ' ')}</div>
                </div>
              ),
              expanded: (
                <>
                  <p className="text-muted m-0">{job.description}</p>
                  <Link to={`/location/${job.location.id}`}>{job.location.name}</Link>
                  <div className="text-capitalize">Status: {job.status.replaceAll('_', ' ')}</div>
                  <div>
                    {/* there should always be at most one tech */}
                    Technician: {job.currentAppointment?.techs[0] ? `${job.currentAppointment?.techs[0]?.firstName} ${job.currentAppointment?.techs[0]?.lastName}` : '-'}
                  </div>
                  <div className="">
                    Invoice details:{" "}
                    {job.invoices.length === 0 ? '-' : (
                      <>
                        <p className="m-0"># {job.invoices[0].number}</p>
                        <p className="m-0">${job.invoices[0].totalPrice.toFixed(2)}</p>
                      </>
                    )}
                  </div>
                </>
              )
            }
          })} />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Project</th>
                <th>Location</th>
                <th className="text-nowrap">Scheduled For</th>
                <th>Status</th>
                <th>Type</th>
                <th>Technician</th>
                <th className="text-nowrap">Invoice Details</th>
              </tr>
            </thead>
            <tbody className="m-0 p-0">
              {jobs.length > 0 ? (
                jobs.map(job => (
                  <tr key={job.id}>
                    <td style={{ minWidth: '150px' }}>
                      <Link to={`/project/${job.id}`}>{job.number}</Link>
                      <p className="text-muted m-0">{job.description}</p>
                    </td>
                    <td>
                      <Link to={`/location/${job.location.id}`}>{job.location.name}</Link>
                    </td>
                    <td>{job.scheduledDate ? jobDate(job.scheduledDate) : '-'}</td>
                    <td className="text-capitalize">{job.status.replaceAll('_', ' ')}</td>
                    <td className="text-capitalize">{job.type.replaceAll('_', ' ')}</td>
                    <td>
                      {/* there should always be at most one tech */}
                      {job.currentAppointment?.techs[0] ? `${job.currentAppointment?.techs[0]?.firstName} ${job.currentAppointment?.techs[0]?.lastName}` : '-'}
                    </td>
                    <td className="text-center">
                      {job.invoices.length === 0 ? '-' : (
                        <>
                          <p className="m-0"># {job.invoices[0].number}</p>
                          <p className="m-0">${job.invoices[0].totalPrice.toFixed(2)}</p>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No projects found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </MobileConditional>
      </Container>
    </>
  );
}
