export type CommonResponse<T> =
  | { status: 'success'; data: T }
  | { status: 'error'; error: AppError };

export interface AppError {
  errorCode: string;
  message: string;
  errors?: FieldError[];
}

export interface CommonSuccess {
  message: string;
}

export interface FieldError {
  field: string;
  message: string;
}

export enum Role {
  Superadmin = 'Superadmin',
  Admin = 'Admin',
  User = 'User',
}

export enum AppRole {
  Admin = 'Admin',
  User = 'User',
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum CompanyStatus {
  Pending = 'pending',
  Active = 'active',
  OnHold = 'on_hold',
  Inactive = 'inactive',
}

export enum LocationStatus {
  Pending = 'pending',
  Active = 'active',
  OnHold = 'on_hold',
  Inactive = 'inactive',
}

export enum AssetStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum ServiceStatus {
  Open = 'open',
  InProgress = 'in_progress',
  Closed = 'closed',
  Canceled = 'canceled',
  Void = 'void',
  Pending = 'pending',
}

export enum JobStatus {
  New = 'new',
  Scheduled = 'scheduled',
  Canceled  = 'canceled',
  Bidding = 'bidding',
  Completed = 'completed',
  PendingInvoice = 'pending_invoice',
  SendingInvoice = 'sending_invoice',
  Invoiced = 'invoiced',
  Closed = 'closed',
}

export enum AppointmentStatus {
  Scheduled = 'scheduled',
  Unscheduled = 'unscheduled',
  CanceledByVendor = 'canceled_by_vendor',
  CanceledByCustomer = 'canceled_by_customer',
  Completed = 'completed',
  NoShow = 'no_show',
}

export interface Session {
  token: string;
  refreshToken?: string;
  role: Role;
  appRole?: AppRole;
}

export interface RefreshToken {
  token: string;
  refreshToken: string;
}

export interface LoginResponse extends Session {
  user: User;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  companyId?: string
  acceptedTermsDate?: string;
}

export interface Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface CompanyPreview {
  id: number;
  name: string;
  status: CompanyStatus;
}

export interface Company {
  id: number;
  name: string;
  status: CompanyStatus;
  phoneNumber: string;
  address: Address;
}

export interface Contact {
  id: number,
  firstName: string,
  lastName: string,
  phone: string,
  mobile: string,
  alternatePhone: string,
  email: string,
  type: string,
}

export interface Location {
  id: number;
  name: string;
  status: LocationStatus,
  primaryContact: Contact | null;
  phoneNumber: string
  email: string;
  company: CompanyPreview | null;
  address: Address;
  link?: {
    base?: string,
    alerts?: string,
  }
}

export interface Locations {
  locations: Location[];
}

export interface LocationDetail {
  location: {
    id: number;
    name: string;
    status: LocationStatus,
    primaryContact: Contact | null;
    phoneNumber: string;
    email: string;
    company: Company | null;
    address: Address;
    attachments: Attachment[];
    link?: {
      base?: string,
      alerts?: string,
    }
  }
}

export interface Attachment {
  id: number;
  contentUrl: string;
  fileType: string;
  fileName: string;
  description?: string;
  purpose?: number;

  category: string;
}

export interface ServiceLine {
  id: number;
  name: string;
  abbr: string;
  icon: string;
}

export interface ServiceLines {
  serviceLines: ServiceLine[];
}

export interface ServiceRequest {
  id: number;
  status:	ServiceStatus;
  description: string;
  serviceLine: ServiceLine;
  asset?: {
    id: number;
    name: string;
    status: AssetStatus;
  };
  location: number | null;
  job: {
    id: number;
    number: number;
    name: string;
    type: string;
    created: number;
    updated: number;
    dueBy: number | null;
    scheduledDate: number | null;
  } | null;
  windowStart: number | null; // integer	Unix timestamp of the servicerequest’s window start date and time
  windowEnd: number | null; // integer	Unix timestamp of the servicerequest’s window end date and time
  created: number; // integer	Unix timestamp of the servicerequest’s creation date and time
  updated: number;// integer	Unix timestamp of the servicerequest’s last updated date and time
  serviceRecurrence: {
    id: number;
    description: string;
    frequency: string;
    repeatWeekday: boolean;
  } | null;
  estimatedPrice: number | null;
  duration: number | null; // how long this service is expected to take, in seconds
  preferredStartTime: number | null;	// preferred start time, in seconds representing hour and minute (e. g. 35100 = 9:45 AM)
}

export interface Services {
  services: ServiceRequest[];
}

export interface Asset {
  id: number,
  name: string,
  type: string,
  serviceLine: ServiceLine | null,
  status: AssetStatus,
  display: string,
  properties: {
    locationInSite: string,
    manufacturer: string,
    model: string,
    serialNumber: string,
    type: string,
    notes: string,
  },
  link?: {
    base?: string,
    alerts?: string,
    trending?: string,
  }
}

export interface Assets {
  assets: Asset[];
}

export interface JobInvoice {
  id: number,
  name: string,
  number: string,
  totalPrice: number,
}

export interface Job {
  id: number,
  number: number,
  name: string,
  type: string,
  description: string,
  status: JobStatus,
  dueBy: number | null,
  scheduledDate: number | null,
  estimatedPrice: number | null,
  currentAppointment: {
    id: number,
    name: string,
    status: AppointmentStatus,
    techs: {
      id: number,
      avatar: {
        small: string,
        medium: string,
        large: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
    }[],
  } | null,
  location: {
    id: number,
    name: string,
  },
  invoices: JobInvoice[],
}

export interface Jobs {
  jobs: Job[];
}

export interface AssetDetail {
  id: number,
  name: string,
  status: AssetStatus,
  type: string,
  display: string,
  serviceLine: ServiceLine | null,
  location: {
    id: number,
    name: string,
    status: LocationStatus,
    phoneNumber: string,
    email: string,
    address: Address,
  } | null,
  company: {
    id: number,
    name: string,
    status: CompanyStatus,
    phoneNumber: string,
    address: Address,
  } | null,
  properties: {
    locationInSite: string,
    manufacturer: string,
    model: string,
    serialNumber: string,
    type: string,
    notes: string,
  },
  jobs: Job[],
  attachments: Attachment[],
  link?: {
    base?: string,
    alerts?: string,
    trending?: string,
  }
}

export interface JobDetail {
  id: number,
  number: number,
  name: string,
  type: string,
  description: string,
  dueBy: number | null,
  scheduledDate: number | null,
  estimatedPrice: number | null,
  status: JobStatus,
  currentAppointment: {
    id: number,
    name: string,
    status: AppointmentStatus,
    techs: {
      id: number,
      avatar: {
        small: string,
        medium: string,
        large: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
    }[],
  } | null,
  location: {
    id: number,
    name: string,
    status: LocationStatus,
    phoneNumber: string,
    email: string,
    address: Address,
  },
  company: {
    id: number,
    name: string,
    status: CompanyStatus,
    phoneNumber: string,
    address: Address,
  },
  invoices: JobInvoice[],
  asset: Asset | null,
  attachments: Attachment[],
}

export interface ProjectJob {
  id: number,
  number: number,
  name: string,
  type: string,
  description: string,
  dueBy: number | null,
  scheduledDate: number | null,
  estimatedPrice: number | null,
  status: JobStatus,
  currentAppointment: {
    id: number,
    name: string,
    status: AppointmentStatus,
    serviceLines: ServiceLine[],
    techs: {
      id: number,
      avatar: {
        small: string,
        medium: string,
        large: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
    }[],
  } | null,
  location: {
    id: number,
    name: string,
    status: LocationStatus,
    phoneNumber: string,
    email: string,
    address: Address,
  },
  asset: Asset | null,
}

export interface Projects {
  jobs: ProjectJob[];
  services: ProjectService[];
}

export interface CompanyData {
  id: number,
  name: string,
  phoneNumber?: string,
  address: {
    street?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  },
}

export interface Deficiency {
  id: number;
  reportedOn: number | null;
  severity: string;
  title: string;
  status: string;
  description: string;
  asset: {
    id: number;
    name: string;
    type: string;
  } | null;
  location: {
    id: number;
    name: string;
  } | null;
  serviceLine: ServiceLine | null
}

export interface Quote {
  id: number;
  name: string;
  refNumber: string;
  status: string;
  description: string;
  totalPrice: string | null;
  expiresOn: number | null;
  location: {
    id: number;
    name: string;
  } | null;
  serviceLines: ServiceLine[];
}

export interface QuoteItem {
  id: number;
  description: string;
  price: number | null;
  quantity: number | null;
  cost: number | null;
}

export interface QuoteService {
  id: number;
  description: string;
  asset: {
    id: number;
    name: string;
  } | null,
  serviceLine: ServiceLine,
  windowStart: number | null,
  windowEnd: number | null,
  preferredStartTime: number | null,
}

export interface QuoteDetail {
  id: number;
  name: string;
  refNumber: string;
  notes?: string;
  description?: string;
  status: string;
  type: string;
  jobType: string;
  totalPrice: number | null;
  taxAmount: number | null;
  subtotal: number | null;
  expiresOn: number | null;
  created: number;
  updated: number;
  vendor: {
    id: number;
    name: string;
    address: Address;
    phoneNumber?: string;
    logo: {
      id: number;
      contentUrl: string;
    } | null,
  },
  customer: {
    id: number;
    name: string;
    address: Address;
    phoneNumber?: string;
  },
  assignedTo: {
    id: number;
    name: string;
    email: string;
  } | null,
  items: QuoteItem[],
  terms: {
    id: number;
    name: string;
    content: string;
  } | null,
  serviceRequests: QuoteService[]
}

export interface ProjectService {
  id: number,
  description: string | null,
  asset: Asset | null,
  activeJobDueDate: number | null,
  currentlyDueDate: number | null,
  location: Location | null,
}

export interface Terms {
  content: string;
  date: string;
}

export type DocumentEntityTypes = "location" | "asset" | "job"

export interface Category {
  id: string;
  name: string;
  canDelete?: boolean;
}
export interface AttachmentCategoriesSuccess {
  categories: Category[]
}

export interface EditCategoryData {
  name: string;
}
