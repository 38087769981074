import Form from 'react-bootstrap/Form';
import {useAppDispatch} from '../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectSearchTerm, setSearchTerm as saveSearchTerm} from '../../store/slices/search';
import {useEffect, useRef, useState} from 'react';
import {InputGroup} from 'react-bootstrap';
import { IoSearchSharp } from "react-icons/io5";
interface SearchBarProps {
  placeholder?: string;
  handleSearch?: (search: string) => void;
  searchKey?: string;
}

export const SearchBar = ({ placeholder = 'Search', handleSearch, searchKey }: SearchBarProps) => {

  const [searchTermLocal, setSearchTermLocal] = useState('');

  const dispatch = useAppDispatch();
  const searchTerm = useSelector(selectSearchTerm(searchKey));
  const setSearchTerm = (search: string) => {
    if (searchKey) {
      dispatch(saveSearchTerm({term: searchKey, value: search}));
    } else {
      setSearchTermLocal(search)
    }
    handleSearch?.(search);
  }

  useEffect(() => {
    handleSearch?.(searchTerm || searchTermLocal);
  }, [])

  return (
    <Form className="mb-2 mx-2 mx-md-0 align-self-md-stretch align-self-lg-start " style={{ minWidth: '20%' }} onSubmit={e => { e.preventDefault(); }}>
      <InputGroup>
        <Form.Control
          value={searchTerm || searchTermLocal}
          type="search"
          title="Search"
          placeholder={placeholder + "..."}
          // onKeyDown={e => {
          //   if(e.key === 'Enter') {
          //     handleSearch(e.currentTarget.value);
          //   }
          // }}
          onChange={e => {
            setSearchTerm(e.currentTarget.value);
          }}
        />
        <InputGroup.Text><IoSearchSharp/></InputGroup.Text>
      </InputGroup>
    </Form>
  )
}
