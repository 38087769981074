import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams, Link } from "react-router-dom";
import { MdOutlineEmail } from 'react-icons/md';
import { MdPhone } from 'react-icons/md';
import { parseDate } from '../utils/parseDate';
import { useApiData } from '../hooks/useApiData';
import { JobDetail } from '../interfaces/interfaces';
import { getJobRequest } from '../api/requests';
import { BackButton } from '../components/common/BackButton';
import { AssetTableItem } from '../components/asset/AssetTableItem';
import { Documents } from '../components/Documents';
import { DetailSkeleton } from '../components/common/DetailSkeleton';
import { Error } from '../components/Error';
import { CardContainer } from '../components/container/CardContainer';
import { SectionContainer } from '../components/container/SectionContainer';

export const Job = () => {
  const { id } = useParams<{ id: string }>();
  const { status, onSuccess, onFailure } = useApiData<JobDetail>();

  useEffect(() => {
    if (!id) return;
    getJobRequest(onSuccess, onFailure, id);
  }, [id]);

  const handleNewDocumentUploaded = () => {
    if (!id) return;
    getJobRequest(onSuccess, onFailure, id);
  }

  const displayDate = (date: number) => {
    const {day, month, year} = parseDate(date);
    return `${month}/${day}/${year}`;
  }

  if (status.type === 'LOADING') {
    return (
      <SectionContainer>
        <DetailSkeleton />
      </SectionContainer>
    );
  }

  if (status.type === 'ERROR') {
    return (
      <SectionContainer style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1 }} />
        <Error error={status.error} type="Project" link={{ title: 'Go to Projects', ref: '/projects' }} />
        <div style={{ flex: 2 }} />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <CardContainer className={"m-0"}>
        <div className={"p-3 p-md-0"}>
          <Card.Title>
            <div className="d-flex flex-row align-items-start">
              <h3 className="flex-fill m-0">
                <BackButton className="p-0 me-3 float-left" />
                {status.data.name}
              </h3>
            </div>
          </Card.Title>
          <>
            {status.data.description && (
              <div className="d-flex align-items-center">
                <p className="mb-0">
                  <span className="fw-bold">Description: </span>
                  {status.data.description}
                </p>
              </div>
            )}
          </>
          <Card.Body className="pb-0 pe-0 ps-0">
          <Row className="p-0 m-0" xs={1} sm={1} md={3}>
            <Col className="ps-0">
              <h5>Location</h5>
              {status.data.location ? (
                <>
                  <p className="mb-1">
                    <Link to={`/location/${status.data.location.id}`}>
                      {status.data.location.name}
                    </Link>
                  </p>
                  <p className="m-0">{status.data.location.address.street}</p>
                  <p className="text-muted m-0">
                    {`${status.data.location.address.city}, ${status.data.location.address.state} ${status.data.location.address.postalCode}`}
                  </p>
                  {(status.data.location.email || status.data.location.phoneNumber) && (
                    <>
                      <p className="mb-1"></p>
                      {status.data.location.email && (
                        <p className="text-muted m-0">
                          <MdOutlineEmail className="me-1" />
                          {status.data.location.email}
                        </p>
                      )}
                      {status.data.location.phoneNumber && (
                        <p className="text-muted m-0">
                          <MdPhone className="me-1" />
                          {status.data.location.phoneNumber}
                        </p>
                      )}
                    </>
                  )}
                  <p/>
                </>
              ) : (
                <p>-</p>
              )}
            </Col>
            <Col className="ps-0">
              <h5>Company</h5>
              {status.data.company ? (
                <>
                  <p className="mb-1 text-decoration-underline">{status.data.company.name}</p>
                  <p className="m-0">{status.data.company.address.street}</p>
                  <p className="text-muted m-0">
                    {`${status.data.company.address.city}, ${status.data.company.address.state} ${status.data.company.address.postalCode}`}
                  </p>
                  {status.data.company.phoneNumber && (
                    <>
                      <p className="mb-1"></p>
                      <p className="text-muted m-0">
                        <MdPhone className="me-1" />
                        {status.data.company.phoneNumber}
                      </p>
                    </>
                  )}
                  <p/>
                </>
              ) : (
                <p>-</p>
              )}
            </Col>
            <Col className="p-0">
              <h5>Details</h5>
              {status.data.dueBy && (
                <p className="m-0 p-0">
                  <span>Due date: </span>
                  <span className="text-muted">{displayDate(status.data.dueBy)}</span>
                </p>
              )}
              {status.data.estimatedPrice && (
                <p className="m-0 p-0">
                  <span>Estimated price: </span>
                  <span className="text-muted">{`$${status.data.estimatedPrice.toFixed(2)}`}</span>
                </p>
              )}
              {status.data.currentAppointment && status.data.currentAppointment.techs.length > 0 && (
                <p className="m-0 p-0">
                  <span>Technician: </span>
                  <span className="text-muted">
                    {`${status.data.currentAppointment.techs[0].firstName} ${status.data.currentAppointment.techs[0].lastName}`}
                  </span>
                </p>
              )}
              <p/>
            </Col>
          </Row>
          {status.data.asset && (
            <>
              <hr className="mt-4" />
              <h5>Asset</h5>
              <div className={"d-block text-wrap"}>
                <AssetTableItem asset={status.data.asset} />
              </div>
            </>
          )}
          <hr className="mt-4" />
          <h5>Documents</h5>
          <Documents onDocumentsChanged={handleNewDocumentUploaded} documents={status.data.attachments} entityId={status.data.id} entityType={"job"} />
        </Card.Body>
        </div>
      </CardContainer>
    </SectionContainer>
  );
}
