import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from '../../assets/mis-logo.jpg';
import './header.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {MdLogout} from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import {logoutRequest} from '../../api/requests';

export const LogoutHeader = () => {

  const onLogout = () => {
    logoutRequest();
  }

  return (
    <div className="header-main-container border-bottom">
      <Navbar className="p-2 justify-content-center" expand="md" style={{ backgroundColor: 'white', zIndex: '100' }}>
        <Navbar.Brand className="p-0 me-1 ms-1 flex-grow-1 d-flex align-items-center justify-content-center">
          <Image className="me-3 ms-3" src={logo} alt="Logo" style={{ height: '38px', objectFit: 'contain' }} />
          <Button
            onClick={onLogout}
            className="btn-primary btn-sm d-flex align-items-center justify-content-center absolute-right"
          >
            <MdLogout size={19} className="me-1" />
            <p className="m-0">Sign Out</p>
          </Button>
        </Navbar.Brand>
      </Navbar>
    </div>
  );
}
