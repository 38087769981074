import React, { useState } from 'react';
import { useTerms } from '../../hooks/useTerms';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './terms.css';
import {MdArrowRight} from 'react-icons/md';
import {AiOutlineRight} from 'react-icons/ai';
import {acceptTermsOfService} from '../../api/requests';
import {useAppDispatch} from '../../hooks/redux';
import {setProfile} from '../../store/slices/profile';
import {useNavigate} from 'react-router-dom';
import {ModalDialog, Toast} from 'react-bootstrap';

export enum TermsState {
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  EXPIRED = 'EXPIRED',
  NO_DATA = 'NO_DATA',
}

export type Props = {
  status: TermsState;
}

export const Terms = ({status}: Props) => {
  const terms = useTerms();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(status === TermsState.EXPIRED);

  const termsDatePretty = () => {
    if (terms) {
      const date = new Date(terms.date);
      return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    }
  }

  const acceptTerms = () => {
    acceptTermsOfService(user => {
      dispatch(setProfile(user));
    }, error => {
      console.log(error);
    })
  }

  return (
    <>
      <div className={"d-flex column align-items-center justify-content-center p-3"}>
        <Toast show={showPopup} onClick={ () => setShowPopup(false) }>
          <Toast.Header>
            <strong className="me-auto">Terms of service</strong>
          </Toast.Header>
          <Toast.Body>
            Terms of Service have been updated on {termsDatePretty()}. Please review the changes and accept the new terms to continue using the application.
          </Toast.Body>
        </Toast>
      </div>
      <div className="container d-flex flex-grow-1">
        <div className="row">
          <div className="col-12">
            <p className={"mt-3"}>Last updated: {termsDatePretty()}</p>
            <div dangerouslySetInnerHTML={{__html: terms?.content || ""}} />
          </div>
        </div>
      </div>
      <div className={"footer-main-container"}>
        <Navbar className={"bg-light shadow-lg justify-content-end"}>
          <Navbar.Brand>
            <Button variant="primary" onClick={acceptTerms}>
              Accept Terms of Service
              <AiOutlineRight size={19} className="ms-1" />
            </Button>
          </Navbar.Brand>
        </Navbar>
      </div>
    </>
  )
}
