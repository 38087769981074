import api from './api';
import Joi from 'joi';
import axios from 'axios';
import {
  AppError,
  CommonResponse,
  LoginResponse,
  Locations,
  Services,
  LocationDetail,
  Assets,
  AssetDetail,
  Jobs,
  JobDetail,
  CommonSuccess,
  Projects,
  User,
  CompanyData,
  ServiceLines,
  Deficiency,
  Quote,
  QuoteDetail,
  Terms, DocumentEntityTypes, AttachmentCategoriesSuccess, Category, EditCategoryData,
} from '../interfaces/interfaces';
import {
  AppErrorSchema,
  AssetDetailSchema,
  AssetsSchema, AttachmentCategoriesSchema,
  CommonResponseSchema,
  CommonSuccessSchema,
  CompanyDataSchema,
  DeficienciesSchema,
  JobDetailSchema,
  JobsSchema,
  LocationDetailSchema,
  LocationsSchema,
  LoginResponseSchema,
  ProjectsSchema,
  QuoteDetailSchema,
  QuotesSchema,
  ServiceLinesSchema,
  ServicesSchema,
  TermsSchema,
  UserSchema,
} from '../interfaces/schemas';

function validateResponse<T>(
  response: CommonResponse<T>,
  schema: Joi.Schema,
  onSuccess: (data: T) => void,
  onFailure: (error: AppError) => void,
) {
  Joi.attempt(response, CommonResponseSchema<T>());
  if (response.status === 'success') {
    Joi.attempt(response.data, schema);
    onSuccess(response.data);
  } else {
    onFailure(response.error);
  }
}

function parseError(error: any): AppError {
  // console.warn(error);
  if (axios.isAxiosError(error)) {
    // console.log('request config', error.config);
    if(error.code === 'ERR_NETWORK') {
      return { errorCode: 'NETWORK', message: 'Network error. Check your internet connection and try again later.' };
    } else if (error.response?.status === 401) {
      return { errorCode: "AUTHENTICATION", message: error.response.data.error.message?? "Authentication error" }
    } else if (error.response?.data.error) {
      try {
        Joi.attempt(error.response.data.error, AppErrorSchema);
        return error.response.data.error;
      } catch (e) {
        // console.warn(error.response?.data.error);
      }
    }
  } else if (error instanceof Joi.ValidationError) {
    // console.warn('Invalid schema', error.details);
  }

  return { errorCode: "UNKNOWN", message: "Unexpected error. Try again later." }
}

function handleError(error: any, onFailure: (error: AppError) => void) {
  const err = parseError(error);
  if (err.errorCode === 'AUTHENTHICATION' && api.store) {
    api.store.dispatch({ type: 'auth/logout' });
  } else {
    onFailure(err);
  }
}

export const loginRequest = async (
  data: { username: string, password: string },
  onSuccess: (data: LoginResponse) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.post<CommonResponse<LoginResponse>>('/session', data);
    validateResponse<LoginResponse>(response.data, LoginResponseSchema, onSuccess, onFailure);
  } catch (e) {
    let error = { errorCode: "UNKNOWN", message: "Unexpected error. Try again later." };
    if (axios.isAxiosError(e)) {
      if(e.code === 'ERR_NETWORK') {
        error = { errorCode: 'NETWORK', message: 'Network error. Check your internet connection and try again later.' };
      } else if (e.response?.data.error) {
        try {
          Joi.attempt(e.response.data.error, AppErrorSchema);
          error = e.response.data.error;
        } catch (e) {
          // console.warn(e.response?.data.error);
        }
      }
    }
    onFailure(error);
  }
}

export const confirmAccountRequest = async (
  data: { code: string, email: string, password: string },
  onSuccess: (data: LoginResponse) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const { code, ...account } = data;
    const response = await api.post<CommonResponse<LoginResponse>>(`/user/account/${code}`, account);
    validateResponse<LoginResponse>(response.data, LoginResponseSchema, onSuccess, onFailure);
  } catch (e) {
    onFailure(parseError(e));
  }
}

export const logoutRequest = async () => {
  try {
    const refreshToken = api.store?.getState().auth.session?.refreshToken;
    if (refreshToken) {
      api.delete<CommonResponse<CommonSuccess>>('/session', { headers: { Authorization: `Bearer ${refreshToken}` } });
    }
    if (api.store) {
      api.store.dispatch({ type: 'auth/logout' });
    }
  } catch (e) {
    // console.warn('logout error: ', e);
  }
}

export const getLocationsRequest = async (
  onSuccess: (data: Locations) => void,
  onFailure: (error: AppError) => void,
  name?: string,
) => {
  try {
    const url = name ? `/location?name=${name}` : '/location';
    const response = await api.get<CommonResponse<Locations>>(url);
    validateResponse<Locations>(response.data, LocationsSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getLocationRequest = async (
  onSuccess: (data: LocationDetail) => void,
  onFailure: (error: AppError) => void,
  id: string,
) => {
  try {
    const response = await api.get<CommonResponse<LocationDetail>>(`/location/${id}`);
    validateResponse<LocationDetail>(response.data, LocationDetailSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getLocationServicesRequest = async (
  onSuccess: (data: Services) => void,
  onFailure: (error: AppError) => void,
  locationId: string,
  status?: string,
) => {
  try {
    const url = status ? `/location/${locationId}/service?status=${status}` : `/location/${locationId}/service`;
    const response = await api.get<CommonResponse<Services>>(url);
    console.log(response.data)
    validateResponse<Services>(response.data, ServicesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getLocationAssetsRequest = async (
  onSuccess: (data: Assets) => void,
  onFailure: (error: AppError) => void,
  locationId: string,
) => {
  try {
    const response = await api.get<CommonResponse<Assets>>(`/location/${locationId}/asset`);
    validateResponse<Assets>(response.data, AssetsSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getLocationJobsRequest = async (
  onSuccess: (data: Jobs) => void,
  onFailure: (error: AppError) => void,
  locationId: string,
  status?: string,
) => {
  try {
    let url = `/location/${locationId}/job`;
    if (status) {
      url += `?status=${status}`;
    }
    const response = await api.get<CommonResponse<Jobs>>(url);
    validateResponse<Jobs>(response.data, JobsSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getAssetRequest = async (
  onSuccess: (data: AssetDetail) => void,
  onFailure: (error: AppError) => void,
  id: string,
) => {
  try {
    const response = await api.get<CommonResponse<AssetDetail>>(`/asset/${id}`);
    validateResponse<AssetDetail>(response.data, AssetDetailSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getAssetServicesRequest = async (
  onSuccess: (data: Services) => void,
  onFailure: (error: AppError) => void,
  assetId: string,
  status?: string,
) => {
  try {
    const url = status ? `/asset/${assetId}/service?status=${status}` : `/asset/${assetId}/service`;
    const response = await api.get<CommonResponse<Services>>(url);
    validateResponse<Services>(response.data, ServicesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getJobRequest = async (
  onSuccess: (data: JobDetail) => void,
  onFailure: (error: AppError) => void,
  id: string,
) => {
  try {
    const response = await api.get<CommonResponse<JobDetail>>(`/project/${id}`);
    validateResponse<JobDetail>(response.data, JobDetailSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getProjectsRequest = async (
  onSuccess: (data: Projects) => void,
  onFailure: (error: AppError) => void,
  status?: string,
) => {
  try {
    const url = status ? `/project?status=${status}` : '/project';
    const response = await api.get<CommonResponse<Projects>>(url);
    validateResponse<Projects>(response.data, ProjectsSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const updateProfileRequest = async (
  onSuccess: (data: User) => void,
  onFailure: (error: AppError) => void,
  data: { phoneNumber?: string, firstName?: string, lastName?: string }
) => {
  try {
    const response = await api.put<CommonResponse<User>>('/user', data);
    validateResponse<User>(response.data, UserSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getCompanyRequest = async (
  onSuccess: (data: CompanyData) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.get<CommonResponse<CompanyData>>('/user/company');
    validateResponse<CompanyData>(response.data, CompanyDataSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const updateCompanyRequest = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  data: { name?: string, phoneNumber?: string, addressStreet?: string, addressCity?: string, addressState?: string, addressPostalCode?: string }
) => {
  try {
    const response = await api.put<CommonResponse<CommonSuccess>>('/user/company', data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const changePasswordRequest = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  data: { currentPassword: string, newPassword: string }
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>('/user/password', data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const requestService = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  data: { locationId: string, assetId: string, description: string, dueDate: number }
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>('/project/service', data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const requestQuote = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  data: { locationId: string, assetId: string, description: string, dueDate: number }
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>('/project/quote', data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getServiceLinesRequest = async (
  onSuccess: (data: ServiceLines) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.get<CommonResponse<ServiceLines>>('/project/serviceline');
    validateResponse<ServiceLines>(response.data, ServiceLinesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getDeficienciesRequest = async (
  onSuccess: (data: { deficiencies: Deficiency[] }) => void,
  onFailure: (error: AppError) => void,
  status?: string,
) => {
  try {
    const url = status ? `/quote/deficiency?status=${status}` : '/quote/deficiency?';
    const response = await api.get<CommonResponse<{ deficiencies: Deficiency[] }>>(url);
    validateResponse<{ deficiencies: Deficiency[] }>(response.data, DeficienciesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getQuotesRequest = async (
  onSuccess: (data: { quotes: Quote[] }) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.get<CommonResponse<{ quotes: Quote[] }>>('/quote');
    validateResponse<{ quotes: Quote[] }>(response.data, QuotesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getQuoteDetailRequest = async (
  onSuccess: (data: QuoteDetail) => void,
  onFailure: (error: AppError) => void,
  id: string,
) => {
  try {
    const response = await api.get<CommonResponse<QuoteDetail>>(`/quote/${id}`);
    validateResponse<QuoteDetail>(response.data, QuoteDetailSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const approveQuote = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  id: string,
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>(`/quote/${id}/approval`);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const requestChangeQuote = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  id: string,
  details: string,
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>(`/quote/${id}/changerequest`, { details });
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getTermsOfService = async (
  onSuccess: (data: Terms) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.get<CommonResponse<Terms>>('/terms');
    validateResponse<Terms>(response.data, TermsSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const acceptTermsOfService = async (
  onSuccess: (data: User) => void,
  onFailure: (error: AppError) => void,
) => {
  try {
    const response = await api.patch<CommonResponse<User>>('/user/accept-tos');
    validateResponse<User>(response.data, UserSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

type UploadDocument = {
  uploadedFile: File;
  description: string;
  entityId: string;
  entityType: DocumentEntityTypes;
  category: string;
}

export const createAttachment = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  document: UploadDocument,
) => {
  try {

    const formData = new FormData();
    formData.append('uploadedFile', document.uploadedFile);
    formData.append('description', document.description);
    formData.append('entityId', document.entityId);
    formData.append('entityType', document.entityType);
    formData.append('categoryId', document.category);

    const response = await api.post<CommonResponse<CommonSuccess>>('/attachment', formData);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const deleteAttachment = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  id: string,
  entityId: string,
  entityType: DocumentEntityTypes,
) => {
  try {
    const response = await api.delete<CommonResponse<CommonSuccess>>(`/attachment/${entityType}/items/${entityId}/attachments/${id}`);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export type EditAttachmentData = {
  description: string;
  categoryId: string;
}

export const editAttachment = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  id: string,
  entityId: string,
  entityType: DocumentEntityTypes,
  data: EditAttachmentData,
) => {
  try {
    const response = await api.put<CommonResponse<CommonSuccess>>(`/attachment/${entityType}/items/${entityId}/attachments/${id}`, data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const getCategories = async (
  onSuccess: (data: { categories: Category[] }) => void,
  onFailure: (error: AppError) => void,
  entityId: string,
  entityType: DocumentEntityTypes,
) => {
  try {
    const response = await api.get<CommonResponse<AttachmentCategoriesSuccess>>(`/attachment/${entityType}/items/${entityId}/categories`);
    validateResponse<AttachmentCategoriesSuccess>(response.data, AttachmentCategoriesSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const createCategory = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  entityId: string,
  entityType: DocumentEntityTypes,
  category: string,
) => {
  try {
    const response = await api.post<CommonResponse<CommonSuccess>>(`/attachment/${entityType}/items/${entityId}/categories`, {
      category,
    });
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const deleteCategory = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  entityId: string,
  entityType: DocumentEntityTypes,
  categoryId: string,
) => {
  try {
    const response = await api.delete<CommonResponse<CommonSuccess>>(`/attachment/${entityType}/items/${entityId}/categories/${categoryId}`);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

export const editCategory = async (
  onSuccess: (data: CommonSuccess) => void,
  onFailure: (error: AppError) => void,
  entityId: string,
  entityType: DocumentEntityTypes,
  categoryId: string,
  data: EditCategoryData,
) => {
  try {
    const response = await api.put<CommonResponse<CommonSuccess>>(`/attachment/${entityType}/items/${entityId}/categories/${categoryId}`, data);
    validateResponse<CommonSuccess>(response.data, CommonSuccessSchema, onSuccess, onFailure);
  } catch (error) {
    handleError(error, onFailure);
  }
}

