import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { useAppSelector } from '../../hooks/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SectionContainer } from '../../components/container/SectionContainer';
import { CardContainer } from '../../components/container/CardContainer';
import { useApiData } from '../../hooks/useApiData';
import { AppError, Asset, Assets, Location, Locations } from '../../interfaces/interfaces';
import { getLocationAssetsRequest, getLocationsRequest, requestQuote, requestService } from '../../api/requests';
import Skeleton from 'react-loading-skeleton';
import Constants from '../../constants/fieldConstants';

interface ContactForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface ServiceForm {
  description: string;
  dueDate: string;
}

interface QuoteForm {
  description: string;
  dueDate: string;
}

type ResponseStatus =
  | { type: 'INIT' }
  | { type: 'LOADING' }
  | { type: 'SUCCESS' }
  | { type: 'ERROR'; error: AppError };

export const ProjectRequest = () => {
  const {status: locationsStatus, onSuccess: locationsSuccess, onFailure: locationsFailure} = useApiData<Location[]>();
  const {status: assetsStatus, onSuccess: assetsSuccess, onFailure: assetsFailure, setLoading: setAssetsLoading} = useApiData<Asset[]>();
  const [type, setType] = useState('Service');
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>({ type: 'INIT' });
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [selectedAsset, setSelectedAsset] = useState<string>();

  const serviceFormSchema = Yup.object().shape({
    description: Yup.string()
      .required('Required')
      .max(Constants.descriptionMaxLength, 'Description too long'),
    dueDate: Yup.string().required('Required'),
  });
  const { register: serviceRegister, handleSubmit: serviceHandleSubmit, formState: serviceFormState, reset: serviceReset } = useForm<ServiceForm>({
    resolver: yupResolver(serviceFormSchema), mode: 'onTouched'
  });

  const quoteFormSchema = Yup.object().shape({
    description: Yup.string()
      .required('Required')
      .max(Constants.descriptionMaxLength, 'Description too long'),
    dueDate: Yup.string().required('Required'),
  });
  const { register: quoteRegister, handleSubmit: quoteHandleSubmit, formState: quoteFormState, reset: quoteReset } = useForm<QuoteForm>({
    resolver: yupResolver(quoteFormSchema), mode: 'onTouched'
  });

  // const contactFormSchema = Yup.object().shape({
  //   firstName: Yup.string()
  //     .required('Required')
  //     .max(Constants.nameMaxLength, 'First name too long'),
  //   lastName: Yup.string()
  //     .required('Required')
  //     .max(Constants.nameMaxLength, 'First name too long'),
  //   email: Yup.string()
  //     .required('Required')
  //     .email('Invalid email')
  //     .max(Constants.emailMaxLength, 'Email too long'),
  //   phoneNumber: Yup.string()
  //     .required('Required')
  //     .max(Constants.phoneNumberMaxLength, 'Phone number too long')
  // });
  // const { register, handleSubmit, formState, reset } = useForm<ContactForm>({
  //   resolver: yupResolver(contactFormSchema),
  //   mode: 'onTouched',
  //   defaultValues: user ? {
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     phoneNumber: user.phoneNumber
  //   } : {
  //     firstName: '',
  //     lastName: '',
  //     email: '',
  //     phoneNumber: ''
  //   }
  // });

  useEffect(() => {
    getLocationsRequest((data: Locations) => {
      locationsSuccess(data.locations);
      if (data.locations.length > 0) {
        setSelectedLocation(data.locations[0].id.toString());
      } else {
        locationsFailure({ errorCode: 'EMPTY', message: 'Your company does not have any locations.' });
      }
    }, locationsFailure);
  }, []);

  useEffect(() => {
    if (!selectedLocation) return;
    setAssetsLoading();
    getLocationAssetsRequest((data: Assets) => {
      const filter = data.assets.filter(asset => asset.type !== 'location');
      if (filter && filter.length > 0) {
        assetsSuccess(filter);
        setSelectedAsset(filter[0].id.toString());
      } else {
        assetsFailure({ errorCode: 'EMPTY', message: 'The selected location does not have any assets.' });
      }
    }, assetsFailure, selectedLocation);
  }, [selectedLocation]);

  const onSubmit = () => {
    if (type === 'Service') {
      if (!selectedLocation || !selectedAsset) return;
      serviceHandleSubmit((data: ServiceForm) => {
        const timestamp = new Date(data.dueDate).getTime() / 1000;
        setResponseStatus({ type: 'LOADING' });
        requestService(() => {
          setResponseStatus({ type: 'SUCCESS' });
          serviceReset();
          setTimeout(() => {
            setResponseStatus({ type: 'INIT' });
          } , 4000);
        }, (error: AppError) => {
          setResponseStatus({ type: 'ERROR', error });
        }, { locationId: selectedLocation, assetId: selectedAsset, description: data.description, dueDate: timestamp });
      })();
    } else if (type === 'Quote') {
      if (!selectedLocation || !selectedAsset) return;
      quoteHandleSubmit((data: QuoteForm) => {
        const timestamp = new Date(data.dueDate).getTime() / 1000;
        setResponseStatus({ type: 'LOADING' });
        requestQuote(() => {
          setResponseStatus({ type: 'SUCCESS' });
          quoteReset();
          setTimeout(() => {
            setResponseStatus({ type: 'INIT' });
          } , 4000);
        }, (error: AppError) => {
          setResponseStatus({ type: 'ERROR', error });
        }, { locationId: selectedLocation, assetId: selectedAsset, description: data.description, dueDate: timestamp });
      })();
    }
  }

  return (
    <SectionContainer style={{ maxWidth: '800px' }}>
      <CardContainer>
        <Card.Title>
          Request a Project
        </Card.Title>
        <Card.Body className="pb-0 pe-0 ps-0">
          <Form onSubmit={(event) => { 
            event.preventDefault();
            onSubmit();
          }}>
            <Form.Group className="mb-3">
              <Form.Label>Type of Project</Form.Label>
              <Form.Select value={type} onChange={e => setType(e.target.value)}>
                <option>Service</option>
                <option>Quote</option>
              </Form.Select>
              {/* TODO: Add text explaining difference between service and quote
              <p className="text-muted mt-1 mb-1">A service is ... and a quote ...</p>*/}
            </Form.Group>
            {type === 'Service' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  {locationsStatus.type === 'SUCCESS' ? (
                    <Form.Select required value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)}>
                      {locationsStatus.data.map(location => (
                        <option key={location.id} value={location.id.toString()}>{location.name}</option>
                      ))}
                    </Form.Select>
                  ) : (locationsStatus.type === 'ERROR') ? (
                    <p className="text-danger">{locationsStatus.error.message}</p>
                  ) : (
                    <Skeleton style={{ height: '38px', flexGrow: 1 }} />
                  )}
                </Form.Group>
                {locationsStatus.type !== 'ERROR' && (
                  <Form.Group className="mb-3">
                    <Form.Label>Asset</Form.Label>
                    {locationsStatus.type === 'SUCCESS' ? (
                      <>
                        {assetsStatus.type === 'SUCCESS' ? (
                          <Form.Select required value={selectedAsset} onChange={e => setSelectedAsset(e.target.value)}>
                            {assetsStatus.data.map(asset => (
                              <option key={asset.id} value={asset.id.toString()}>{asset.name}</option>
                            ))}
                          </Form.Select>
                        ) : (assetsStatus.type === 'ERROR') ? (
                          <p className="text-danger">{assetsStatus.error.message}</p>
                        ) : (
                          <Skeleton style={{ height: '38px', flexGrow: 1 }} />
                        )}
                      </>
                    ) : (
                      <Form.Select disabled>
                      </Form.Select>
                    )}
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Due on</Form.Label>
                  <Form.Control required type="date" placeholder="Select date" {...serviceRegister('dueDate')} min={new Date().toISOString().split('T')[0]} />
                  {serviceFormState.errors.dueDate && <p className="text-danger">{serviceFormState.errors.dueDate.message}</p>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Enter description"
                    maxLength={Constants.descriptionMaxLength}
                    required
                    {...serviceRegister('description')} />
                  {serviceFormState.errors.description && <p className="text-danger">{serviceFormState.errors.description.message}</p>}
                </Form.Group>
              </>
            )}
            {type === 'Quote' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  {locationsStatus.type === 'SUCCESS' ? (
                    <Form.Select required value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)}>
                      {locationsStatus.data.map(location => (
                        <option key={location.id} value={location.id.toString()}>{location.name}</option>
                      ))}
                    </Form.Select>
                  ) : (locationsStatus.type === 'ERROR') ? (
                    <p className="text-danger">{locationsStatus.error.message}</p>
                  ) : (
                    <Skeleton style={{ height: '38px', flexGrow: 1 }} />
                  )}
                </Form.Group>
                {locationsStatus.type !== 'ERROR' && (
                  <Form.Group className="mb-3">
                    <Form.Label>Asset</Form.Label>
                    {locationsStatus.type === 'SUCCESS' ? (
                      <>
                        {assetsStatus.type === 'SUCCESS' ? (
                          <Form.Select required value={selectedAsset} onChange={e => setSelectedAsset(e.target.value)}>
                            {assetsStatus.data.map(asset => (
                              <option key={asset.id} value={asset.id.toString()}>{asset.name}</option>
                            ))}
                          </Form.Select>
                        ) : (assetsStatus.type === 'ERROR') ? (
                          <p className="text-danger">{assetsStatus.error.message}</p>
                        ) : (
                          <Skeleton style={{ height: '38px', flexGrow: 1 }} />
                        )}
                      </>
                    ) : (
                      <Form.Select disabled>
                      </Form.Select>
                    )}
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Due on</Form.Label>
                  <Form.Control required type="date" placeholder="Select date" {...quoteRegister('dueDate')} min={new Date().toISOString().split('T')[0]} />
                  {quoteFormState.errors.dueDate && <p className="text-danger">{quoteFormState.errors.dueDate.message}</p>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Enter description"
                    maxLength={Constants.descriptionMaxLength}
                    required
                    {...quoteRegister('description')} />
                  {quoteFormState.errors.description && <p className="text-danger">{quoteFormState.errors.description.message}</p>}
                </Form.Group>
              </>
            )}
            {/* <h6 className="pt-2">Contact information</h6>
            <Row xs={2} sm={2} md={2}>
              <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="Enter your first name"
                  type="text"
                  maxLength={Constants.nameMaxLength}
                  {...register('firstName')}
                />
                {formState.errors.firstName && <p className="text-danger">{formState.errors.firstName.message}</p>}
              </Form.Group>
              <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Enter your last name"
                  type="text"
                  maxLength={Constants.nameMaxLength}
                  {...register('lastName')}
                />
                {formState.errors.lastName && <p className="text-danger">{formState.errors.lastName.message}</p>}
              </Form.Group>
            </Row>
            <Row xs={2} sm={2} md={2}>
              <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  placeholder="Enter your phone number"
                  type="tel"
                  maxLength={Constants.phoneNumberMaxLength}
                  required
                  {...register('phoneNumber')}
                />
                {formState.errors.phoneNumber && <p className="text-danger">{formState.errors.phoneNumber.message}</p>}
              </Form.Group>
              <Form.Group className="mb-3 flex-grow-1" style={{ minWidth: '240px' }}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Enter your email address"
                  type="email"
                  maxLength={Constants.emailMaxLength}
                  required
                  {...register('email')}
                />
                {formState.errors.email && <p className="text-danger">{formState.errors.email.message}</p>}
              </Form.Group>
            </Row> */}
            {responseStatus.type === 'ERROR' && (
              <div className="alert alert-danger mt-3 p-2">
                <p className="m-0">{responseStatus.error.message}</p>
              </div>
            )}
            {responseStatus.type === 'SUCCESS' && (
              <div className="alert alert-success mt-3 p-2">
                <p className="m-0">{type} created successfully!</p>
              </div>
            )}
            <div className="flex-grow-1 d-flex justify-content-center">
              <Button
                variant="primary"
                type="submit"
                className="mt-3 ps-4 pe-4"
                disabled={locationsStatus.type === 'LOADING' || assetsStatus.type === 'LOADING' || responseStatus.type === 'LOADING'}>
                Submit
                {responseStatus.type === 'LOADING' && (
                  <span className="spinner-border spinner-border-sm ms-2" />
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </CardContainer>
    </SectionContainer>
  );
}