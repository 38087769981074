import {useParams, useSearchParams} from 'react-router-dom';
import {Monitor, MonitorLink} from '../../components/Monitor';
import {useStableQueryArgs} from '@reduxjs/toolkit/dist/query/react/useSerializedStableValue';

interface LocationMonitorProps {
  type: 'alarm' | 'monitor';
}

export const LocationMonitor = ({ type }: LocationMonitorProps) => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  if (!id) return null;

  return (
    <MonitorLink link={searchParams.get("link")} />
  );
};
