import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const TableSkeleton = ({ n = 10 }: { n?: number }) => {
  return (
    <>
      <Skeleton style={{ width: '15%', minWidth: '150px', height: '35px' }} />
      <Skeleton className="mt-2" style={{ height: '50px' }} />
      <Skeleton className="mt-1" count={n} style={{ height: '40px' }} /> 
    </>
  );
}
