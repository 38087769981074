import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { injectStore } from './api/api';
import Router from './router/Router';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    injectStore(store);
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        theme="light"
        hideProgressBar
      />
      <div style={{ width: '100%', minHeight: '100vh', height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Router />
      </div>
    </Provider>
  );
}

export default App;
