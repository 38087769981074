import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Constants from '../../constants/fieldConstants';
import { changePasswordRequest } from '../../api/requests';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { AppError } from '../../interfaces/interfaces';

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

type Status =
  | { type: 'INIT' }
  | { type: 'LOADING' }
  | { type: 'ERROR', message: string };

export const ChangePassword = () => {
  const [status, setStatus] = useState<Status>({ type: 'INIT' });
  const passwordFormSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(Constants.passwordMinLength, 'Password must be at least 8 characters long')
      .max(Constants.passwordMaxLength, 'Password too long')
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'Password must contain at least one letter and one number')
      .notOneOf([Yup.ref('currentPassword')], 'New password must be different than current password'),
    currentPassword: Yup.string()
      .max(Constants.passwordMaxLength, 'Password too long'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
  });
  const { register, handleSubmit, formState, reset } = useForm<PasswordForm>({
    resolver: yupResolver(passwordFormSchema),
    mode: 'onTouched'
  });

  const onSubmit: SubmitHandler<PasswordForm> = (data: PasswordForm) => {
    setStatus({ type: 'LOADING' });
    changePasswordRequest(() => {
      reset();
      setStatus({ type: 'INIT' });
      toast.success('Password changed successfull');
    }, (err: AppError) => {
      setStatus({ type: 'ERROR', message: err.message });
    }, { currentPassword: data.currentPassword, newPassword: data.newPassword });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="pt-2">Change Password</h5>
      <Form.Group className="form-group mt-3">
        <Form.Label>Current Password</Form.Label>
        <Form.Control
          placeholder="Enter your current password"
          type="password"
          maxLength={Constants.passwordMaxLength}
          disabled={status.type === 'LOADING'}
          required
          {...register('currentPassword')}
        />
        {formState.errors.currentPassword && (
          <p className="text-danger mt-1 mb-1">{formState.errors.currentPassword.message}</p>
        )}
      </Form.Group>
      <Form.Group className="form-group mt-3">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          placeholder="Enter a new password"
          type="password"
          maxLength={Constants.passwordMaxLength}
          disabled={status.type === 'LOADING'}
          required
          {...register('newPassword')}
        />
        <p className={`mt-1 mb-1 ${formState.errors.newPassword ? "text-danger" : "text-muted"}`}>{`Password must be at least ${Constants.passwordMinLength} characters long and contain at least one letter and one number`}</p>
      </Form.Group>
      <Form.Group className="form-group mt-3">
        <Form.Label>Confirm New Password</Form.Label>
        <Form.Control
          placeholder="Enter your new password"
          type="password"
          maxLength={Constants.passwordMaxLength}
          disabled={status.type === 'LOADING'}
          required
          {...register('confirmNewPassword')}
        />
        {formState.errors.confirmNewPassword && <p className="text-danger mt-1 mb-1">{formState.errors.confirmNewPassword.message}</p> }
      </Form.Group>
      {status.type === 'ERROR' && (
        <div className="alert alert-danger mt-3 p-2">
          <p className="m-0">{status.message}</p>
        </div>
      )}
      <div className="flex-grow-1 d-flex justify-content-end">
        <Button
          variant="primary"
          type="submit"
          className="mt-3 ps-2 pe-2"
          disabled={status.type === 'LOADING' || !formState.isValid}
        >
          Change password
          {status.type === 'LOADING' && (
            <span className="spinner-border spinner-border-sm ms-2" />
          )}
        </Button>
      </div>
    </Form>
  );
}