import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { RootState } from '../store/store';
import Joi from 'joi';
import { RefreshTokenSchema } from '../interfaces/schemas';

export const baseURL = process.env.REACT_APP_API_URL;
interface AppApi extends AxiosInstance {
  store?: ToolkitStore<RootState>;
}

const isSessionEndpoint = (url: string) => url.startsWith('/session') || url.startsWith('/user/account') || url.startsWith('/user/password');

let api: AppApi = axios.create({ baseURL });

api.interceptors.request.use(async config => {
  const session = api.store?.getState().auth.session;
  config.headers = config.headers || {};
  if (session && config.headers && !('Authorization' in config.headers)) {
    (config.headers as AxiosHeaders).set("Authorization", `Bearer ${session.token}`);
  }

  return config;
});

api.interceptors.response.use(undefined, async error => {
  try {
    const session = api.store?.getState().auth.session;
    if (axios.isAxiosError(error) && error.response?.status === 401 && !isSessionEndpoint(error.config?.url || '')) {
      if (session?.refreshToken) {
        const response = await axios.get(`${baseURL}/session/refresh`, { headers: { Authorization: `Bearer ${session.refreshToken}` } });
        const data = Joi.attempt(response.data, RefreshTokenSchema);
        api.store?.dispatch({ type: 'auth/refreshToken', payload: data.data });
        const config = error.config || { headers: {} };
        (config.headers as AxiosHeaders).set("Authorization", `Bearer ${data.data.token}`);
        return axios.request(config);
      } else if (session) {
        api.store?.dispatch({ type: 'auth/logout' });
      }
    }
  } catch (e) {
    api.store?.dispatch({ type: 'auth/logout' });
  }
  return Promise.reject(error);
})

export const injectStore = (_store: ToolkitStore<RootState>) => {
  api.store = _store;
};

export default api;
