import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { LoginResponse, User } from '../../interfaces/interfaces';
import { login, logout } from './auth';

export interface ProfileState {
  user: User | null,
}

const initialState: ProfileState = {
  user: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(_, action: PayloadAction<User>) {
      return { user: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, _ => {
        return initialState;
      })
      .addCase(login, (_, action: PayloadAction<LoginResponse>) => {
        const { user } = action.payload;
        if (user) {
          return { user };
        }
        return initialState;
      })
      .addCase(logout, _ => {
        return initialState;
      })
  }
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
