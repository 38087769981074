import React, {PropsWithChildren} from 'react';
import {Tabs} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';

interface Props {
  defaultKey: string;
}

export const TabsContainer: React.FC<PropsWithChildren<Props>> = ({children, defaultKey}) => {
  return (
    <Tabs
      defaultActiveKey={defaultKey}
      className={"mb-0"}>
      {Array.isArray(children) ? children.map((child, index) => {
        return <Tab title={child.props.title} eventKey={child.props.eventKey} key={index}>{child}</Tab>
      }) : children}
    </Tabs>
  );
}

interface TabProps {
  eventKey: string;
  title: string;
}

export const TabContainer: React.FC<PropsWithChildren<TabProps>> = ({children}) => {
  return <>
    {children}
  </>
}
