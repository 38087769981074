import { CardContainer } from '../../components/container/CardContainer';
import { SectionContainer } from '../../components/container/SectionContainer';
import { DeficienciesTable } from '../../components/quote/DeficienciesTable';
import { QuotesTable } from '../../components/quote/QuotesTable';

export const Quotes = () => {
  return (
    <SectionContainer>
      <CardContainer>
        <h4 className="mb-3 mx-3 mx-md-0 mt-3 mt-md-0">Deficiencies</h4>
        <DeficienciesTable />
        <hr />
        <h4 className="mb-3 mx-3 mx-md-0 mt-3 mt-md-0">Quotes</h4>
        <QuotesTable />
      </CardContainer>
    </SectionContainer>
  );
}
