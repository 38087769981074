import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa';

export const BackButton = ({ className = '' }: { className?: string }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      style={{ border: 'none', background: 'transparent' }}
      className={className}
    >
      <FaArrowLeft size={22}/>
    </button>
  );
}
