import { createSlice } from "@reduxjs/toolkit";
import {RootState} from '../store';

type State = {
  terms: Record<string, string>
}

const initialState: State = {
  terms: {},
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm(state, action: {payload: {term?: string, value: string}}) {
      if (!action.payload.term) {
        return;
      }
      state.terms[action.payload.term] = action.payload.value;
    }
  }
});

export default searchSlice.reducer;
export const { setSearchTerm } = searchSlice.actions;

// selector
export const selectSearchTerm = (term?: string) => (state: RootState) =>  term ? state.searchTerms.terms?.[term] : undefined;
